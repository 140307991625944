import {Location, ViewportScroller} from "@angular/common";
import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NavigationEnd, NavigationSkipped, Router, RouterOutlet, Scroll} from "@angular/router";
import {initFlowbite} from "flowbite";
import {delay, filter, Subject, Subscription, takeUntil} from "rxjs";
import {DeviceService} from "./shared/service/device/device.service";
import {BackdropService} from "./shared/service/backdrop/backdrop.service";
import {TranslocoService} from "@ngneat/transloco";
import {NavigationService} from "./shared/service/nav/navigation.service";
import {ReferralService} from "./shared/service/referrer/referral.service";
import {HTML_LANG_MAP} from "./header/const/header-const";
import {LoadingBarComponent} from "./shared/loading-bar/loading-bar.component";
import {ToastComponent} from "./shared/widgets/toast/toast.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    LoadingBarComponent,
    ToastComponent,
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app-inbound-web';
  subscription: Subscription = new Subscription();
  initialized = false;

  destroyEvent$: Subject<void> = new Subject<void>();

  constructor(private deviceService: DeviceService,
              private router: Router,
              private referrerService: ReferralService,
              private scroller: ViewportScroller,
              private translocoService: TranslocoService,
              private backdropService: BackdropService,
              private renderer: Renderer2,
              private location: Location,
              private navService: NavigationService) {

    this.router.events
      .pipe(filter((event): event is Scroll => event instanceof Scroll), delay(100))
      .subscribe((event) => {
        if (event.routerEvent instanceof NavigationSkipped) {
          return;
        }
        if (event.position) {
          scroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          scroller.scrollToAnchor(event.anchor);
        } else {
          scroller.scrollToPosition([0, 0]);
        }
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navService.setListPageShouldLoadSaveState(window.location.pathname);
        // Scroll to the top of the page
        this.backdropService.visible = false;
        const currentParams = new URLSearchParams(window.location.search)

        if (!currentParams.get('referrer')) {
          const referrer = referrerService.getReferrerSlug()

          if (referrer && referrer.length && referrer !== referrerService.PUBLIC_REFERRAL_SLUG) {
            currentParams.set('referrer', referrer as string)

            const replacePath = this.router.url.split('?')[0]
            this.location.replaceState(`${replacePath}?${currentParams.toString()}`)
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.destroyEvent$.next();
    this.destroyEvent$.complete();
  }

  async ngOnInit(): Promise<void> {
    initFlowbite();

    this.translocoService.langChanges$.pipe(takeUntil(this.destroyEvent$)).subscribe(async (language) => {
      this.adjustFont(language);
    })

    this.initialized = true;
  }

  adjustFont(lang: string): void {
    const newFont = this.getCurrentStyleFont(lang);
    if (newFont) {
      document.body.style.setProperty('--main-font', newFont);

      const newHtmlLang = HTML_LANG_MAP?.[lang] ?? 'en';

      this.renderer.setAttribute(document.documentElement, 'lang', newHtmlLang);
    }
  }

  getCurrentStyleFont(lang: string) {
    switch (lang) {
      case 'zh-CN':
        return getComputedStyle(document.documentElement).getPropertyValue('--chinese-font').trim();
      case 'zh-TW':
        return getComputedStyle(document.documentElement).getPropertyValue('--chinese-font').trim();
      case 'ko':
        return getComputedStyle(document.documentElement).getPropertyValue('--korean-font').trim();
      case 'en':
        return getComputedStyle(document.documentElement).getPropertyValue('--english-font').trim();
      default:
        return getComputedStyle(document.documentElement).getPropertyValue('--japanese-font').trim();
    }
  }

}
