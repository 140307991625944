<ng-container *ngIf="dropdownName">
  <app-base-button
    [label]="label"
    [labelState]="labelState"
    [subLabel]="subLabel"
    [subLabelClass]="subLabelClass"
    [labelClass]="labelClass"
    [labelSecondary]="labelSecondary"
    [labelSecondaryClass]="labelSecondaryClass"
    [isDropDown]="isDropDown"
    [dropdownName]="dropdownName"
    [control]="control"
    [hideBorder]="hideBorder"
    [activeState]="activeState"
    [shopDropArrow]="shopDropArrow">
    <ng-content select=".left-image"></ng-content>
  </app-base-button>
  <div
    #dropdown
    [id]="dropdownName"
    id="dropdown"
    class="z-20 hidden bg-white divide-y divide-gray-100 sm:rounded-lg shadow dark:bg-gray-700 md:mt-3 lg:mt-4 w-full border-t-[0.5px] border-gray-300 sm:border-t-0 rounded-[0px]"
    [ngClass]="[widthClass, dropdownClass]">
    <div [ngClass]="contentPadClass">
      <span class="float-right hover:cursor-pointer" (click)="onExitClick()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
              fill="black" />
        </svg>
      </span>
      <label class="form-label font-bold">
        {{ contentHeaderLabel ? contentHeaderLabel: label }}
        <span [class]="subLabelClass">{{ subLabel ? subLabel : ""}}</span> 
      </label>

      <ng-content select=".dropdown-content"></ng-content>
    </div>

    <hr *ngIf="!hideSubmit" [ngClass]="topClass" />

    <ng-container *ngIf="!hideSubmit">
      <div [ngClass]="contentButtonPadClass">
        <div class="pt-1 pb-0 gap-5 flex justify-between align-items-center" [ngClass]="padClass">
          <span class="mt-0 sm:mt-5 text-start pb-2">
            <button class="text-[#4E4E4E] font-normal" (click)="searchService.resetFilter(control)" *ngIf="control.dirty">
              {{ "filter.resetFilter" | transloco }}
            </button>
          </span>

          <span class="mt-0 sm:mt-5 text-end pb-2">
            <button
              [disabled]="!filterCount"
              class="px-4 py-3 text-base font-medium text-center text-white focus:ring-4 focus:outline-none focus:bg-tm-pink rounded-[0px] sm:rounded-lg"
              (click)="onSubmitClick()"
              [ngClass]="filterCount ? 'bg-tm-red' : 'bg-tm-disabled text-[#8C9196]'">
              <span *ngIf="filterCount !== 1">{{ ("filter.seeFilteredRestaurants" | transloco).replace("\{\}", "" + filterCount) }}</span>
              <span *ngIf="filterCount === 1">{{ ("filter.seeFilteredRestaurant" | transloco).replace("\{\}", "" + filterCount) }}</span>
            </button>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
