import { Injectable } from '@angular/core';
import { Toast } from '../../widgets/toast/models/toast';
import { ToastType } from '../../widgets/toast/models/toast-type';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  delay = 5000;
  toasts: Toast[] = [];

  show(header: string, body: string, type: ToastType): void {
    let options = {};
    switch (type) {
      case ToastType.info:
        options = { classname: 'toast-info' };
        break;
      case ToastType.success:
        options = { classname: 'toast-success text-white' };
        break;
      case ToastType.warning:
        options = { classname: 'toast-warning' };
        break;
      case ToastType.error:
        options = { classname: 'toast-error text-white' };
        break;
    }
    this.toasts.push({ header, body, ...options });
  }

  remove(toast: Toast): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
