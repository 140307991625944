export enum SupportedLanguage {
  en = "English",
  ja = "日本語",
  'zh-CN' = "中文 (简体)",
  'zh-TW' = "中文 (繁體)",
  ko = "한국어",
}

export const SupportedLegacyAndBCP47Map: Record<string, string> = {
  en: 'en',
  ja: 'ja',
  jp: 'ja',
  'ja-JP': 'ja',
  zh: 'zh-CN',
  cn: 'zh-CN',
  tw: 'zh-TW',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
  'zh-Hant': 'zh-TW',
  'zh-Hant-TW': 'zh-TW',
  'zh-Hant-HK': 'zh-TW',
  'zh-HW': 'zh-TW',
  'zh-Hans': 'zh-CN',
  'zh-Hans-CN': 'zh-CN',
  ko: 'ko',
  kr: 'ko',
};

export const HTML_LANG_MAP: Record<string, string> = {
  en: "en",
  ja: "ja",
  'zh-CN': "zh",
  'zh-TW': "zh",
  ko: "ko",
}
