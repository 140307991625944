import { Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import { filter, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ShopListApiService } from "../../concierge-app/shop-list-view/services/shop-list-api.service";
import { Tag, TagCategory } from "../../concierge-app/home-page/models/tag";
import { isEmpty } from "lodash-es";
import { SearchService } from "../../shared/service/search/search.service";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagSelectorComponent),
      multi: true,
    },
  ],
  imports: [
    ReactiveFormsModule,
    NgForOf
  ],
  standalone: true
})
export class TagSelectorComponent implements ControlValueAccessor, OnDestroy {

  public onTouched = () => {};
  public onChange = (tagEnums: string[]) => {};

  tags: Tag[] = []

  form = new FormGroup({
    checkboxes: new FormArray<FormControl<boolean | null>>([])
  });

  destroyEvent$: Subject<void> = new Subject<void>();

  @Output() change = new EventEmitter<void>

  constructor(private searchService: SearchService) {}

  async fetchTags(): Promise<void> {
    await this.searchService.getLoadedTags().then((tags) => {
      this.tags = tags.filter(tag => [TagCategory.CUISINE].includes(tag.tagCategory));
    })
    this.tags.map(() => this.checkboxes.push(new FormControl(false)))
  }

  ngOnDestroy(): void {
    this.destroyEvent$.next();
    this.destroyEvent$.complete();
  }

  get checkboxes(): FormArray {
    return this.form.controls.checkboxes as FormArray;
  }

  onChangeClickEvent(index: number): void {
    const checkboxValues = this.checkboxes.value as boolean[];
    checkboxValues[index] = !checkboxValues[index];

    const selectedOptions = this.tags.map(tag => tag.tagEnum).filter((_, index) => checkboxValues[index]);
    this.onChange(selectedOptions);
  }

  async writeValue(newModel: string[]): Promise<void> {
    if (!this.form.controls.checkboxes.length || !this.tags?.length) {
      await this.fetchTags();
    }
    this.tags.forEach((option, index) => {
      this.checkboxes.at(index).setValue(newModel.includes(option.tagEnum));
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
