<app-drop-button
    [label]="(searchService.defaultState.label) | transloco"
    [contentHeaderLabel]="'filter.state' | transloco"
    [labelState]="(labelState | transloco)"
    [dropdownName]="'filterState'"
    [widthClass]="'sm:w-[280px]'"
    [control]="searchService.filterForm.controls.addressState"
    [activeState]="stateChanged"
    [contentPadClass]="'pt-4 px-4 pb-2'"
    [filterCount]="filterService.filteredCount"
    (submitEvent)="onSubmit()"
    [padClass]="'pr-0 pl-0'"
    [isDropDown]="true">
      <span class="left-image">
        <span>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path [attr.fill]="stateChanged ? '#DF0629': '#212529'"
                  d="M6.99992 6.99996C7.32075 6.99996 7.5954 6.88572 7.82388 6.65725C8.05235 6.42878 8.16658 6.15413 8.16658 5.83329C8.16658 5.51246 8.05235 5.23781 7.82388 5.00933C7.5954 4.78086 7.32075 4.66663 6.99992 4.66663C6.67909 4.66663 6.40443 4.78086 6.17596 5.00933C5.94749 5.23781 5.83325 5.51246 5.83325 5.83329C5.83325 6.15413 5.94749 6.42878 6.17596 6.65725C6.40443 6.88572 6.67909 6.99996 6.99992 6.99996ZM6.99992 11.2875C8.18603 10.1986 9.06589 9.20933 9.6395 8.31975C10.2131 7.43017 10.4999 6.64024 10.4999 5.94996C10.4999 4.89024 10.1621 4.02253 9.48638 3.34683C8.81068 2.67114 7.98186 2.33329 6.99992 2.33329C6.01797 2.33329 5.18915 2.67114 4.51346 3.34683C3.83777 4.02253 3.49992 4.89024 3.49992 5.94996C3.49992 6.64024 3.78672 7.43017 4.36034 8.31975C4.93395 9.20933 5.81381 10.1986 6.99992 11.2875ZM6.99992 12.8333C5.43464 11.5013 4.26554 10.2642 3.49263 9.12183C2.71971 7.97947 2.33325 6.92218 2.33325 5.94996C2.33325 4.49163 2.80235 3.32982 3.74054 2.46454C4.67874 1.59926 5.7652 1.16663 6.99992 1.16663C8.23464 1.16663 9.3211 1.59926 10.2593 2.46454C11.1975 3.32982 11.6666 4.49163 11.6666 5.94996C11.6666 6.92218 11.2801 7.97947 10.5072 9.12183C9.73429 10.2642 8.5652 11.5013 6.99992 12.8333Z"
            />
          </svg>
        </span>
      </span>

  <span class="dropdown-content">
        <ul class="ps-0.5">
          <ng-container *ngFor="let state of ADDRESS_STATE | keyvalue; let i = index">
            <li
                class="block px-[8px] py-2 hover:bg-tm-pink hover:text-tm-red hover:cursor-pointer rounded-[5px]"
                (click)="
                searchService.filterForm.controls.addressState.patchValue(state.value.default);
                searchService.filterForm.controls.addressState.markAsDirty()
              "
                [value]="state.value['default']"
                [ngClass]="{
                'bg-tm-pink text-tm-red': searchService.filterForm.controls.addressState.value === state.value.default
              }">
              {{ state.value.label | transloco }}
            </li>
          </ng-container>
        </ul>
      </span>
</app-drop-button>
