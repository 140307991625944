import { Component } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MeService } from 'src/app/shared/service/auth/me.service';
import { DeviceService } from 'src/app/shared/service/device/device.service';
import {ProfileViewerComponent} from "../../shared/widgets/profile-viewer/profile-viewer.component";
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {NavItemComponent} from "./nav-item/nav-item.component";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  imports: [
    ProfileViewerComponent,
    NgTemplateOutlet,
    NavItemComponent,
    NgClass,
    NgIf
  ],
  standalone: true
})
export class NavBarComponent {
  constructor(
    public offCanvas: NgbActiveOffcanvas,
    public device: DeviceService,
    public meService: MeService
  ) {
  }

}
