<span class="inline" *ngIf="variant === 'WALK_TIME'">
    <ng-container *transloco="let t">
        <ng-container *ngIf="formatType === 'DISTANCE'">
            {{t('shopCard.awayFromHotelTime', { value: formatWalkTimeInMin() })}}
        </ng-container>
        <ng-container *ngIf="formatType === 'FROM_HOTEL'">
            {{t('shopCard.awayFromHotelTimeNamed', { value: formatWalkTimeInMin(), hotel: referralInfo?.referrerName })}}
        </ng-container>
        <ng-container *ngIf="formatType === 'FROM_HOTEL_WITHOUT_HOTEL_NAME'">
            {{t('shopCard.awayFromHotelTime', { value: formatWalkTimeInMin()})}}
        </ng-container>
    </ng-container>
</span>
<span class="inline" *ngIf="variant === 'DISTANCE'">
    <ng-container *transloco="let t">
        <ng-container *ngIf="formatType === 'DISTANCE'">
            {{t('shopCard.awayFromHotelDistance', { value: formatDistance() }) }}
        </ng-container>
        <ng-container *ngIf="formatType === 'FROM_HOTEL'">
            {{t('shopCard.awayFromHotelDistanceNamed', { value: formatDistance(), hotel: referralInfo?.referrerName }) }}
        </ng-container>
        <ng-container *ngIf="formatType === 'FROM_HOTEL_WITHOUT_HOTEL_NAME'">
            {{t('shopCard.awayFromHotelDistance', { value: formatDistance()}) }}
        </ng-container>
    </ng-container>
</span>