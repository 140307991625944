import { Component } from '@angular/core';
import {FormArray, FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgbActiveModal, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import { DateTime } from 'luxon';
import { ADDRESS_STATE } from 'src/app/concierge-app/shop-list-view/models/shop-list-const';
import { ApiService } from 'src/app/shared/service/api/api.service';
import { MeService } from 'src/app/shared/service/auth/me.service';
import {KeyValuePipe, NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {SuccessPageComponent} from "../../success-page/success-page.component";

@Component({
  selector: 'app-booking-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    ReactiveFormsModule,
    NgForOf,
    NgIf,
    KeyValuePipe,
    NgTemplateOutlet,
    SuccessPageComponent,
    NgClass,
    NgbInputDatepicker
  ]
})
export class PersonalizedBookingFormComponent {
  selectedDateOption: 'today' | 'tomorrow' | null = null;

  bookingForm = this.fb.group({
    guestName: ['', Validators.required],
    email: [
      this.meService.user?.email || '',
      [
        Validators.required,
        Validators.pattern(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/),
      ],
    ],
    date: ['', Validators.required],
    time: ['' as string, Validators.required],
    guests: [2, [Validators.required, Validators.min(1)]],
    area: ['', Validators.required],
    adults: [1, [Validators.required, Validators.min(1)]], // Add adults control
    children: [0, []],
    childrenAges: this.fb.array([]), // Add array for children's ages
    request: ['', [Validators.required, Validators.minLength(1)]],
  });

  areas = ADDRESS_STATE;
  ageOptions = Array.from({ length: 18 }, (_, i) => i + 1); // Age options from 1 to 18

  todayTimeSlots: string[] = [];
  allTimeSlots: string[] = [
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
  ];

  minDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  //Date within 4months
  maxDate = {
    year: this.minDate.year,
    month: this.minDate.month + 4,
    day: this.minDate.day,
  };

  showSuccessComponent = false;

  constructor(
    private fb: FormBuilder,
    private meService: MeService,
    private apiService: ApiService,
    private transloco: TranslocoService,
    public activeModal: NgbActiveModal,
  ) {
    this.initAvailableTimeSlots();
  }

  initAvailableTimeSlots(): void {
    this.todayTimeSlots = [];
    let now = DateTime.now()
      .setZone('Asia/Tokyo')
      .set({ minute: 0, second: 0, millisecond: 0 });

    const earliestTime = now.set({ hour: 10 });
    const latestTime = now.set({ hour: 21 });

    if (now < earliestTime) {
      now = earliestTime;
    } else {
      now = now.plus({ hours: 2 });
    }

    while (now <= latestTime) {
      this.todayTimeSlots.push(now.toFormat('HH:mm'));
      now = now.plus({ minutes: 30 });
    }
  }

  todayIsSelected(): boolean {
    if (this.selectedDateOption === 'today') {
      return true;
    }

    const currentFormDateValue = this.bookingForm.controls.date.value as string;

    if (!currentFormDateValue) {
      return false;
    }

    const luxonDate = DateTime.fromFormat(currentFormDateValue, 'yyyy-MM-dd');

    if (!luxonDate.isValid) {
      return false;
    }

    return (
      luxonDate.toFormat('yyyy-MM-dd') ===
      DateTime.now().setZone('Asia/Tokyo').toFormat('yyyy-MM-dd')
    );
  }

  onDateSelect(date: NgbDateStruct): void {
    this.bookingForm.controls.date.patchValue(
      DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
      }).toFormat('yyyy-MM-dd'),
    );
  }

  setDate(): void {
    const today = new Date();
    const localDate = new Date(
      today.getTime() - today.getTimezoneOffset() * 60000,
    ); // Adjust to local timezone

    if (this.selectedDateOption === 'today') {
      this.bookingForm.patchValue({
        date: localDate.toISOString().split('T')[0],
      });
    } else if (this.selectedDateOption === 'tomorrow') {
      const tomorrow = new Date(localDate);
      tomorrow.setDate(localDate.getDate() + 1);
      this.bookingForm.patchValue({
        date: tomorrow.toISOString().split('T')[0],
      });
    }
  }

  setTime(value: string): void {
    if (value === 'lunch') {
      this.bookingForm.patchValue({ time: '12:00 - 14:00' });
    } else if (value === 'dinner') {
      this.bookingForm.patchValue({ time: '18:00 - 21:00' });
    }
  }

  changeGuests(change: number): void {
    const currentGuests =
      this.bookingForm.controls.guests.value ?? (0 as number);
    if (currentGuests + change > 0) {
      this.bookingForm.patchValue({ guests: currentGuests + change });
    }
  }

  getBookingDetails(): string {
    const { date, time, guests, request } = this.bookingForm.value;
    return `Booking Details:
    - Date: ${date}
    - Time: ${time}
    - Guests: ${guests}
    - Request: ${request}`;
  }

  genPayload() {
    const payload = {
      firstName: this.meService.user?.email,
      lastName: this.meService.user?.email,
      email: this.meService.user?.email,
      inquiryType: 'Personalized booking Concierge',
      companyName: 'TakeMe Concierge',
      message: this.getBookingDetails(),
    };
    return payload;
  }

  onSubmit(): void {
    const formValues = this.bookingForm.value as Record<string, string>;
    formValues['message'] = this.getBookingDetails();

    const updateFn = () => {
      setTimeout(() => {
        this.apiService
          .postJson('/api/v3/reservations/inquiry', null, formValues)
          .subscribe(() => {
            this.showSuccessComponent = true;
          });
      }, 300);
    };

    updateFn();
  }

  addChildAge() {
    const childrenAges = this.bookingForm.get('childrenAges') as FormArray;
    childrenAges.push(this.fb.control('', Validators.required));
  }

  removeChildAge(index: number) {
    const childrenAges = this.bookingForm.get('childrenAges') as FormArray;
    if (childrenAges.length > 0) {
      childrenAges.removeAt(index);
    }
  }

  changeChildren(value: number): void {
    const children = this.bookingForm.controls.children.value || 0;
    if (children + value >= 0) {
      this.bookingForm.controls.children.setValue(children + value);

      if (value > 0) {
        this.addChildAge();
      } else if (value < 0 && children > 0) {
        this.removeChildAge(children - 1);
      }
    }
  }
}
