import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../shared/service/api/api.service';
import { PredefinedPaymentResponse } from '../models/predefined-payment-response';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  constructor(private apiService: ApiService) {}

  public getPayment(resId: string): Observable<PredefinedPaymentResponse> {
    const url = '/api/v3/reservations/' + resId + '/predefined-payment';
    return this.apiService.get<PredefinedPaymentResponse>(url, null);
  }

  public getPaymentWithEmail(
    resId: string,
    email: string,
  ): Observable<PredefinedPaymentResponse> {
    const url =
      '/api/v3/reservations/' + resId + '/predefined-payment-with-email';
    return this.apiService.get<PredefinedPaymentResponse>(url, null, {
      email: email,
    });
  }
}
