import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, omitBy } from 'lodash-es/';
import { Observable } from 'rxjs';
import { BrowserStandardEncoder } from '../../../core/browser-standard-encoder';
import { ApiResponseError } from './api-response-error';

@Injectable({
  providedIn: 'root',
})

/**
 * Copied from app-admin-web with minor type modification
 */
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T>(
    url: string,
    headers?: Record<string, string> | null,
    params?: { [p: string]: any },
    responseType?: string | null,
  ): Observable<T> {
    const submitParams = omitBy(params, isNil);
    const options = {
      headers,
      params: this.encodeParams(submitParams),
      responseType: responseType,
    };
    return this.http.get<T>(url, omitBy(options, isNil));
  }

  postJson<T>(
    url: string,
    headers?: Record<string, string> | null,
    body?: any,
  ): Observable<T> {
    const options = {
      headers,
    };
    return this.http.post<T>(url, body, omitBy(options, isNil));
  }

  patchJson<T>(
    url: string,
    headers?: { [key: string]: string },
    body?: any,
  ): Observable<T> {
    return this.http.patch<T>(url, body, { headers });
  }

  putJson<T>(
    url: string,
    headers?: { [key: string]: string },
    body?: any,
  ): Observable<T> {
    const options = {
      headers,
    };
    return this.http.put<T>(url, body, options);
  }

  delete<T>(
    url: string,
    params?: Record<string, string>,
    headers?: { [key: string]: string },
  ): Observable<T> {
    return this.http.delete<T>(url, { params, headers });
  }

  private encodeParams(
    params: Record<string, string | number | boolean>,
  ): HttpParams {
    let encodedParams = new HttpParams({
      encoder: new BrowserStandardEncoder(),
    });
    Object.entries(params).forEach(([key, value]) => {
      encodedParams = encodedParams.append(key, value);
    });
    return encodedParams;
  }

  private convertToStringRecord<T>(obj: { [K in keyof T]: T[K] }): Record<
    string,
    string
  > {
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [k, this.stringifyValue(v)]),
    );
  }

  private stringifyValue(value: any): string {
    if (typeof value === 'string' || value instanceof String) {
      return value as string;
    } else {
      return JSON.stringify(value);
    }
  }

  public static parseErrorMsg(httpErrorResponse: HttpErrorResponse) {
    const apiResponseError: ApiResponseError = httpErrorResponse.error;
    return `${apiResponseError.code} - ${apiResponseError.message}`;
  }
}
