import { Component } from '@angular/core';
import { MeService } from 'src/app/shared/service/auth/me.service';
import { DeviceService } from 'src/app/shared/service/device/device.service';
import { LoginModalService } from '../../login/login-modal.service';
import { HeaderControlService } from '../../service/headerControl.service';
import {NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLinkActive,
    TranslocoModule,
    RouterLink
  ]
})
export class DashboardNavComponent {
  constructor(
    public device: DeviceService,
    public meService: MeService,
    private loginService: LoginModalService,
    public headerControl: HeaderControlService,
  ) {}

  async logout() {
    await this.meService.logout();
  }

  openLoginModal() {
    this.loginService.openLoginModal();
  }
}
