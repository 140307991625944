import { Component } from '@angular/core';
import { FilterService } from '../filter/service/filter.service';
import {HeaderComponent} from "../header/header.component";
import {SearchFilterComponent} from "../filter/search-filter.component";
import {NgIf} from "@angular/common";
import {RouterOutlet} from "@angular/router";
import {FooterComponent} from "../footer/footer.component";

@Component({
  selector: 'app-concierge-app',
  templateUrl: './concierge-app.component.html',
  styleUrls: ['./concierge-app.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    SearchFilterComponent,
    NgIf,
    RouterOutlet,
    FooterComponent
  ]
})
export class ConciergeAppComponent {
  constructor(public filterService: FilterService) {}

  isSearchPage(): boolean {
    return location.pathname.includes('/app/list');
  }
}
