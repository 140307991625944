import { NgcCookieConsentConfig } from "ngx-cookieconsent";
import { environment } from "../../environments/environment";

export const API_BASE = "/api/v3/"
export const API_CONCIERGE = API_BASE + "reservations/"

export const mobileViewThreshold = 480;
export const webViewThreshold = 1800;

const getReferrer = () => {
  let referralSlug = document.cookie.split(';')?.find(row => row.trim().startsWith('_tm_referrer='))
  if (referralSlug && referralSlug.length) {
    if (referralSlug.replace('_tm_referrer=', '')?.length) {
      return "&" + referralSlug.replace('_tm_referrer=', 'referrer=');
    }
  }
  return ''
}

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain //Note, according to docs, this is a requirement
  },
  palette: {
    popup: {
      background: 'white',
      link: '#212529',
      text: 'black',
    },
    button: {
      background: '#DC3545',
      text: 'white',
      border: "transparent"
    }
  },
  position: `bottom-left`,
  theme: 'classic',
  type: 'info',
  compliance: {
    info: `<div class="cc-compliance cc-highlight">
            <a aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny">Accept essential only</a>
            <a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow">Allow cookies</a>
            </div>`
  },
  content: {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Allow cookies",
    "deny": "Accept essential only",
    "link": "Learn more",
    "href": `${environment.baseUrl}/app/legal?type=cookiePolicy` + getReferrer(),
    "policy": "Cookie Policy",
  },
};
