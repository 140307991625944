<div #bookingModal>
    <div class="modal-header bg-red-500 text-white font-bold text-xl">
        <div class="flex flex-col">
            <div class="font-medium">
              <span>{{'personalizedBooking.title' | transloco }}</span>
              <span class="absolute right-3 md:right-6 text-3xl top-[14px]" (click)="activeModal.close()"><i class="bi bi-x"></i></span>
            </div>
            <div class="font-medium opacity-85">{{'personalizedBooking.subTitle' | transloco }}</div>
        </div>

    </div>
    <div class="modal-body p-0">
        <div *ngIf="!showSuccessComponent" class="booking-details-summary max-h-[70vh] overflow-y-auto p-4">
            <!-- Booking details content -->
            <ng-container *ngTemplateOutlet="bookingInfo"></ng-container>
        </div>
        <div *ngIf="showSuccessComponent" class="booking-details-summary h-full overflow-y-auto sm:overflow-y-visible p-4">
          <app-success-page></app-success-page>
      </div>
    </div>


  <div class="stikcy bottom-0 bg-white">
    <hr class="my-0">
    <div class="px-4 sm:px-6 py-6">
      <button *ngIf="!showSuccessComponent" class="btn bg-red-600 text-white disabled:text-black w-full disabled:bg-tm-grey"
              (click)="onSubmit()"
              [disabled]="!this.bookingForm.valid">
        <i class="bi bi-envelope me-2"></i> {{'personalizedBooking.submit' | transloco}}
      </button>
      <button *ngIf="showSuccessComponent" class="btn bg-red-600 text-white disabled:text-black w-full disabled:bg-tm-grey"
            (click)="activeModal.close()">
        {{'common.close' | transloco}}
      </button>
    </div>
  </div>
</div>

<!-- booking information date/slot -->
<ng-template #bookingInfo>
    <div class="max-w-md mx-auto">
      <form [formGroup]="bookingForm" (ngSubmit)="onSubmit()">
        <div class="mb-4">
          <label class="form-label required-field" for="guestName">
            {{ 'personalizedBooking.yourName' | transloco }}
          </label>
          <input class="form-control" type="text" id="guestName" [formControl]="bookingForm.controls.guestName">
        </div>

        <div class="mb-4">
          <label class="form-label required-field" for="email">
            {{ 'personalizedBooking.email' | transloco }}
          </label>
          <input class="form-control" type="text" id="email" [formControl]="bookingForm.controls.email">
        </div>

        <div class="mb-4">
          <label class="form-label required-field block mb-2">{{'booking.personalizedDate' | transloco}}</label>
          <div class="flex flex-wrap gap-2">

            <div class="btn-group-toggle flex gap-2">
              <input type="radio" class="btn-check" id="today-date" autocomplete="off" name="options" [checked]="selectedDateOption === 'today'" (click)="selectedDateOption = 'today';setDate();dateInput.value=''">
              <label class="btn rounded-3xl focus:ring-0 focus:bg-tm-pink focus:shadow-tm-btn focus:border-tm-red"
                      [ngClass]="{'bg-red-500 text-white': selectedDateOption === 'today', 'text-gray-700': selectedDateOption !== 'today'}"
                      for="today-date" [class.active]="selectedDateOption === 'today'">
                {{'time.today' | transloco}}</label><br>

              <input type="radio" class="btn-check" id="tomorrow-date" checked autocomplete="off" name="options" [checked]="selectedDateOption === 'tomorrow'" (click)="selectedDateOption = 'tomorrow';setDate();dateInput.value=''" >
              <label class="btn rounded-3xl focus:ring-0 focus:bg-tm-pink focus:shadow-tm-btn focus:border-tm-red"
                      [ngClass]="{'bg-red-500 text-white': selectedDateOption === 'tomorrow', 'text-gray-700': selectedDateOption !== 'tomorrow'}"
                      [class.active]="selectedDateOption === 'tomorrow'"
                      for="tomorrow-date">{{'time.tomorrow' | transloco }}</label><br>
            </div>

            <div class="input-group flex flex-nowrap sm:w-fit relative items-center">
              <i class="bi bi-calendar-date absolute left-3" (click)="dp.toggle()"></i>
              <input title="dateInput"
                      name="dp"
                      #dateInput
                      ngbDatepicker #dp="ngbDatepicker"
                      (click)="dp.toggle();"
                      [placeholder]="'booking.selectDate' | transloco"
                      class="form-input w-full sm:w-40 pl-10 rounded-3xl"
                      (dateSelect)="onDateSelect($event); selectedDateOption=null;"
                      [maxDate]="maxDate"
                      [minDate]="minDate"
                      [container]="'body'"
                      readonly
              />
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label class="form-label required-field block mb-2">{{'booking.personalizedTime' | transloco}}</label>
          <div class="flex flex-wrap gap-2 form-group">
            <div class="input-group flex flex-nowrap sm:w-fit relative items-center">
              <i class="bi bi-clock absolute left-3"></i>
              <select formControlName="time" class="form-input w-full sm:w-40 pl-10 rounded-3xl">
                <option value="" disabled selected>{{'booking.personalizedSelectTime' | transloco}}</option>

                <ng-container *ngIf="todayIsSelected(); else notTodayOption">
                  <option *ngFor="let slot of todayTimeSlots" [value]="slot">{{slot}}</option>
                </ng-container>

                <ng-template #notTodayOption>
                  <option *ngFor="let slot of allTimeSlots" [value]="slot">{{slot}}</option>
                </ng-template>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label class="form-label required-field block mb-2">{{'personalizedBooking.area' | transloco}}</label>
          <div class="flex flex-wrap gap-2 form-group">
            <div class="input-group flex flex-nowrap w-full relative items-center">
              <select formControlName="area" class="form-input input-group flex flex-nowrap relative items-center">
                <option value="" disabled selected>{{'personalizedBooking.selectArea' | transloco}}</option>
                <ng-container>
                  <option *ngFor="let area of areas | keyvalue" [value]="area.value.default">{{ area.value.label | transloco}}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-4 flex justify-between notranslate">
          <label class="form-label required-field block mb-2">{{'personalizedBooking.adults' | transloco}}</label>
          <div class="flex gap-6">
                <span title="subtractCourse" (click)="changeGuests(-1)">
                  <i class="bi bi-dash-circle" style="font-size: 20px;"></i>
                </span>
                  <div class="text-lg">{{ bookingForm.controls.guests.value }}</div>
                  <span title="addCourse" (click)="changeGuests(1)">
                  <i class="bi bi-plus-circle" style="font-size: 20px;"></i>
                </span>
          </div>
        </div>
        
        <!-- Children Section -->
        <div class="mb-4 flex justify-between notranslate">
          <label class="form-label block mb-2">{{ 'personalizedBooking.children' | transloco }}</label>
          <div class="flex gap-6">
            <span title="subtractChild" (click)="changeChildren(-1)">
              <i class="bi bi-dash-circle" style="font-size: 20px;"></i>
            </span>
            <div class="text-lg">{{ bookingForm.controls.children.value }}</div>
            <span title="addChild" (click)="changeChildren(1)">
              <i class="bi bi-plus-circle" style="font-size: 20px;"></i>
            </span>
          </div>
        </div>

        <!-- Select for Children Ages -->
        <div formArrayName="childrenAges" *ngIf="bookingForm.controls.children?.value || 0 > 0">
          <div *ngFor="let ageCtrl of bookingForm.controls.childrenAges.controls; let i = index" class="mb-4">
            <label class="form-label block mb-2">{{ 'personalizedBooking.childAge' | transloco }} {{ i + 1 }}</label>
            <select [formControlName]="i" class="form-input input-group flex flex-nowrap relative items-center">
              <option *ngFor="let age of ageOptions" [value]="age">{{ age }}</option>
            </select>
            <div *ngIf="ageCtrl.invalid && ageCtrl.touched">
              <small class="text-red-500">{{ 'personalizedBooking.errors.childAge' | transloco }}</small>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="form-label required-field block mb-2">{{'booking.personalizedRequest' | transloco}}</label>
          <textarea formControlName="request" class="form-textarea w-full" rows="3" [placeholder]="'personalizedBooking.remarks' | transloco"></textarea>
        </div>
      </form>
    </div>
</ng-template>
