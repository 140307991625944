import { Component } from '@angular/core';
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    TranslocoModule
  ]
})
export class SuccessPageComponent {
  // The component doesn't need any additional logic for displaying the title and subtitle
  // as Transloco will handle the translation in the template.
}
