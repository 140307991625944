import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../shared/service/api/api.service';
import { Course } from '../models/course';
import { ReservationRequest } from '../models/reservation-request';
import { SavedReservation } from '../models/saved-reservation';

@Injectable({
  providedIn: 'root',
})
export class ReservationApiService {
  constructor(private apiService: ApiService) {}

  public getCourseList(shopId: string): Observable<Course[]> {
    const url = '/api/v3/reservations/shops/' + shopId + '/courses';
    return this.apiService.get<Course[]>(url, null);
  }

  public getCountryCode(): Observable<string> {
    const url = '/api/v3/reservations/country-code';
    return this.apiService.get<string>(url, null, {}, 'text');
  }

  public getCourse(shopNumber: string, courseId: string): Observable<Course> {
    const url =
      '/api/v3/reservations/shops/' + shopNumber + '/courses/' + courseId;
    return this.apiService.get<Course>(url, null);
  }

  public getShopCourse(shopId: string, courseId: string): Observable<Course> {
    const url = '/api/v3/reservations/shops/' + shopId + '/courses/' + courseId;
    return this.apiService.get<Course>(url, null);
  }

  public createReservation(
    body: ReservationRequest,
  ): Observable<SavedReservation> {
    const url = '/api/v3/reservations/';
    return this.apiService.postJson<SavedReservation>(url, null, body);
  }

  public updateReservation(
    resId: string,
    body: { [key: string]: string },
  ): Observable<SavedReservation> {
    const url = '/api/v3/reservations/' + resId;
    return this.apiService.patchJson<SavedReservation>(url, {}, body);
  }

  getReservation(resId: string): Observable<SavedReservation> {
    const url = '/api/v3/reservations/view/' + resId;
    return this.apiService.get<SavedReservation>(url, null);
  }

  getReservationByUserId(params: any): Observable<SavedReservation[]> {
    const url = '/api/v3/reservations/';
    return this.apiService.get<SavedReservation[]>(url, null, params);
  }
}
