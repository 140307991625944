import { Component } from '@angular/core';
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { AnalyticsEventCategory, sendEvent } from "../../../shared/analytics/analytics";
import {BaseButtonComponent} from "../../../shared/buttons/base-button/base-button.component";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-sameday-filter',
  templateUrl: './sameday-filter.component.html',
  styleUrl: './sameday-filter.component.scss',
  imports: [
    BaseButtonComponent,
    TranslocoModule
  ],
  standalone: true
})
export class SamedayFilterComponent extends BaseFilterComponent {
  get labelState(): string {
    return "";
  }

  async sendAnalytics(): Promise<void> {
     sendEvent(AnalyticsEventCategory.TAGS, 'SAME_DAY')
  }

  get stateChanged(): boolean {
    return this.searchService.filterForm.controls.requestSameDay.value ?? false;
  }

  toggleSameDay(): void {
    const control = this.searchService.filterForm.controls.requestSameDay;
    control.patchValue(!control.value);
  }
}
