import { Component } from '@angular/core';
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { get, isEmpty } from "lodash-es";
import { Tag } from "../../../concierge-app/home-page/models/tag";
import { AnalyticsEventCategory, sendEvent } from "../../../shared/analytics/analytics";
import {DropdownButtonComponent} from "../../../shared/buttons/dropdown-button/dropdown-button.component";
import {TagSelectorComponent} from "../../tag-selector/tag-selector.component";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-cuisine-filter',
  templateUrl: './cuisine-filter.component.html',
  styleUrls: ['./cuisine-filter.component.scss'],
  imports: [
    DropdownButtonComponent,
    TagSelectorComponent,
    ReactiveFormsModule,
    TranslocoModule
  ],
  standalone: true
})
export class CuisineFilterComponent extends BaseFilterComponent {
  get labelState(): string {
    const selectedTags = this.searchService.filterForm.controls.tagEnums.value as string[];

    if (selectedTags.length === 0) {
      return 'Cuisine'
    }

    const numFilters = selectedTags.length;

    const tagReference = get(this.searchService.mappedTagsByEnum, selectedTags[0]) as Tag;

    let displayTag = tagReference?.tag ?? selectedTags[0] //Try to get the actual tag name, else use the tagEnum

    if (numFilters == 1) {
      return `${displayTag}`
    }

    return `${displayTag} + ${numFilters - 1}`
  }

  async sendAnalytics(): Promise<void> {
    const tagEnums = this.searchService.filterForm.controls.tagEnums.value as string[];

    if (tagEnums && tagEnums.length) {
      tagEnums.forEach(tagEnum => {
        sendEvent(AnalyticsEventCategory.TAGS, tagEnum);
      })
    }
  }

  get stateChanged(): boolean {
    return !isEmpty(this.searchService.filterForm.controls.tagEnums.value);
  }

}
