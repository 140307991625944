import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FeaturedShop } from 'src/app/concierge-app/home-page/models/featured-shop';
import { Shop } from 'src/app/concierge-app/shop-list-view/models/shop-list';

@Injectable({
  providedIn: 'root',
})
export class BookNowService {
  closingHr = 15;
  closingMin = 30;

  constructor() {}

  public getEnabled(shop: Shop | FeaturedShop): boolean {
    return shop?.instantReservationEligible || false;
  }

  public expiryTimer(): Date {
    let date = new Date();
    //this is for expiry time, it somehow do not takes the class values.
    const closingHr = 15;
    const closingMin = 30;
    date.setHours(closingHr, closingMin, 0, 0); // Setting expiry time to 3:30 PM
    return date;
  }

  public isInvalid(selectedDate: NgbDate): boolean {
    const now = new Date();

    const isToday = selectedDate?.equals({
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    });

    const isAfterClosing =
      now.getHours() > this.closingHr ||
      (now.getHours() >= this.closingHr && now.getMinutes() > this.closingMin);

    return isToday && isAfterClosing;
  }
}
