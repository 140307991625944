import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, shareReplay } from 'rxjs';
import { ApiService } from '../service/api/api.service';
import { ReferralService } from '../service/referrer/referral.service';
import { HotelShopDistance } from './model/HotelShopDistance';
import { PlaceDetails } from './model/PlaceDetails';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapApiService {
  private hotelShopDist: {
    [shopId: string]: Observable<HotelShopDistance | null>;
  } = {};
  private googlePlaceDetails: { [placeId: string]: Observable<PlaceDetails> } =
    {};

  constructor(
    private apiService: ApiService,
    public referrerService: ReferralService,
  ) {}

  getPlaceDetails(placeId: string): Observable<PlaceDetails> {
    const url = '/api/v3/reservations/google-place/' + placeId;
    if (!this.googlePlaceDetails[placeId]) {
      this.googlePlaceDetails[placeId] = this.apiService
        .get<PlaceDetails>(url)
        .pipe(shareReplay(1));
    }
    return this.googlePlaceDetails[placeId];
  }

  getHotelShopDistance(shopId: string): Observable<HotelShopDistance | null> {
    const url =
      '/api/v3/reservations/hotel-shop-distance/' +
      shopId +
      '?referrer=' +
      this.referrerService.getReferrerSlug();

    if (!this.hotelShopDist[shopId]) {
      this.hotelShopDist[shopId] = this.apiService
        .get<HotelShopDistance>(url)
        .pipe(
          shareReplay(1),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(null); // Return an Observable of null or an appropriate fallback value
            } else {
              return of(null); // Return an Observable of null for other errors
            }
          }),
        );
    }
    return this.hotelShopDist[shopId];
  }
}
