import { Component, Input } from '@angular/core';
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { SORT_BY_OPTIONS, SortByOption } from '../../constants/search-filter-const';
import { AnalyticsEventCategory, sendEvent } from "../../../shared/analytics/analytics";
import {KeyValuePipe, NgClass, NgForOf} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";
import {DropdownButtonComponent} from "../../../shared/buttons/dropdown-button/dropdown-button.component";

@Component({
  selector: 'app-sortby-filter',
  templateUrl: './sortby-filter.component.html',
  styleUrls: ['./sortby-filter.component.scss'],
  imports: [
    NgClass,
    TranslocoModule,
    DropdownButtonComponent,
    KeyValuePipe,
    NgForOf
  ],
  standalone: true
})
export class SortbyFilterComponent extends BaseFilterComponent {
  SORT_BY_OPTIONS = SORT_BY_OPTIONS as Record<string, { name: string, transLabel: string, stateLabel: string }>;

  @Input() filterSortMobile: string = "";
  @Input() hideBorder: boolean = false;
  @Input() showDropArrow: boolean = false;

  get labelState(): string {
    const sortByValue = this.searchService.filterForm.controls.sortBy.value
    if (!sortByValue) {
      return `${this.SORT_BY_OPTIONS[SortByOption.RECOMMENDED].stateLabel}`
    }

    return `${this.SORT_BY_OPTIONS[sortByValue].stateLabel}`
  }

  async sendAnalytics(): Promise<void> {
    sendEvent(AnalyticsEventCategory.SORT, this.labelState);
  }

  get stateChanged(): boolean {
    return this.searchService.filterForm.controls.sortBy.value !== SortByOption.RECOMMENDED + ''
  }

  override get clearAllEligible(): boolean {
    return false;
  }

}
