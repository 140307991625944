<app-drop-button
    [label]="'filter.priceRange' | transloco"
    [subLabel]="'booking.perGuest' | transloco"
    [subLabelClass]="'text-sm text-gray-500'"
    [labelState]="('filter.lessThan' | transloco) + labelState"
    [widthClass]="'sm:w-[376px]'"
    [topClass]="'mt-[40px]'"
    [dropdownName]="'filterPriceRange'"
    [control]="searchService.filterForm.controls.priceRange"
    [activeState]="stateChanged"
    [filterCount]="filterService.filteredCount"
    (submitEvent)="onSubmit()"
    [isDropDown]="true">
      <span class="left-image">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path [attr.fill]="stateChanged ? '#DF0629': 'black'"
                d="M7 8.75C7.46413 8.75 7.90925 8.56563 8.23744 8.23744C8.56563 7.90925 8.75 7.46413 8.75 7C8.75 6.53587 8.56563 6.09075 8.23744 5.76256C7.90925 5.43437 7.46413 5.25 7 5.25C6.53587 5.25 6.09075 5.43437 5.76256 5.76256C5.43437 6.09075 5.25 6.53587 5.25 7C5.25 7.46413 5.43437 7.90925 5.76256 8.23744C6.09075 8.56563 6.53587 8.75 7 8.75Z"
          />
          <path [attr.fill]="stateChanged ? '#DF0629': 'black'"
                d="M0 3.5C0 3.26794 0.0921872 3.04538 0.256282 2.88128C0.420376 2.71719 0.642936 2.625 0.875 2.625H13.125C13.3571 2.625 13.5796 2.71719 13.7437 2.88128C13.9078 3.04538 14 3.26794 14 3.5V10.5C14 10.7321 13.9078 10.9546 13.7437 11.1187C13.5796 11.2828 13.3571 11.375 13.125 11.375H0.875C0.642936 11.375 0.420376 11.2828 0.256282 11.1187C0.0921872 10.9546 0 10.7321 0 10.5V3.5ZM2.625 3.5C2.625 3.96413 2.44063 4.40925 2.11244 4.73744C1.78425 5.06563 1.33913 5.25 0.875 5.25V8.75C1.33913 8.75 1.78425 8.93437 2.11244 9.26256C2.44063 9.59075 2.625 10.0359 2.625 10.5H11.375C11.375 10.0359 11.5594 9.59075 11.8876 9.26256C12.2158 8.93437 12.6609 8.75 13.125 8.75V5.25C12.6609 5.25 12.2158 5.06563 11.8876 4.73744C11.5594 4.40925 11.375 3.96413 11.375 3.5H2.625Z"
          />
        </svg>
      </span>

  <span class="dropdown-content border-none">
        <ngx-slider [formControl]="searchService.filterForm.controls.priceRange" [options]="sliderOptions"></ngx-slider>
      </span>
</app-drop-button>
