<ng-container *ngIf="errorType === ErrorType.Blocked; else errorCode">
  <ng-container *ngTemplateOutlet="blockPage"></ng-container>
</ng-container>

<ng-template #errorCode>
  <div class="flex flex-col justify-between min-h-screen">
    <app-header></app-header>
    
    <div class="">
      <div *ngIf="errorType === ErrorType.NotFound">
        <ng-container *ngTemplateOutlet="notFound"></ng-container>
      </div>
    
      <div *ngIf="errorType === ErrorType.Forbidden">
        <ng-container *ngTemplateOutlet="forbidden"></ng-container>
      </div>
    
      <div *ngIf="errorType === ErrorType.InternalServerError">
        <ng-container *ngTemplateOutlet="serverError"></ng-container>
      </div>
    
      <div *ngIf="errorType === ErrorType.NotImplemented">
        <ng-container *ngTemplateOutlet="serverError"></ng-container>
      </div>
    
      <div *ngIf="errorType === ErrorType.Blocked">
        <ng-container *ngTemplateOutlet="blockPage"></ng-container>
      </div>
    
    </div>
    
    <app-footer></app-footer>
  </div>
</ng-template>

<ng-template #brokenPage>

  <h2>{{'errorPage.brokenPagetitle' | transloco}}</h2>
  <h6>
    {{'errorPage.brokenPage.subTitle' | transloco}}
  </h6>
  <div class="body">
    <span>
      {{'errorPage.brokenPage.body' | transloco}}
    </span>
  </div>
  <div class="btn tm-btn btn-danger"><a href="mailto:service@takeme.com" data-email="support" data-domain="takeme.com">{{ 'common.contactSupport' | transloco }}</a></div>
</ng-template>

<ng-template #blockPage>
  <div class="error-container">
    <img src="../../../assets/images/TakeMeLogo.png" alt="TakeMe" [style]="'width : 80px'">
    <h2>{{'errorPage.blockPage.title' | transloco}}</h2>
    <h6>
      {{'errorPage.blockPage.subTitle' | transloco}}
    </h6>
    <div class="body">
      <span>
        {{'errorPage.blockPage.body' | transloco}}
      </span>
    </div>
    <div class="btn tm-btn btn-danger"><a href="mailto:service@takeme.com" data-email="support" data-domain="takeme.com">{{ 'common.contactSupport' | transloco }}</a></div>
  </div>
</ng-template>

<ng-template #notFound>
  <div class="flex flex-col items-center justify-center bg-white p-4">
    <!-- Image Section -->
    <div class="max-w-xs md:max-w-md lg:max-w-lg mb-6 font-bold text-6xl"> 404 </div>
    <div class="max-w-xs md:max-w-md lg:max-w-lg mb-6 font-bold text-4xl"> {{'errorPage.404.title' | transloco}} </div>

    <!-- Text Section -->
    <p class="text-center text-gray-700 text-base md:text-lg mb-4">
      {{ 'errorPage.404.message' | transloco }}
    </p>

    <!-- Button Section -->
    <button
      class="bg-red-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-red-600 focus:outline-none"
      (click)="goToHomepage()"
    >
      {{ 'common.toHome' | transloco }}
    </button>
  </div>
</ng-template>

<ng-template #forbidden>
  <div class="flex flex-col items-center justify-center bg-white p-4">
    <!-- Image Section -->
    <div class="max-w-xs md:max-w-md lg:max-w-lg mb-6 font-bold text-6xl"> 401 </div>
    <div class="max-w-xs md:max-w-md lg:max-w-lg mb-6 font-bold text-4xl"> {{'errorPage.401.title' | transloco}} </div>

    <!-- Text Section -->
    <p class="text-center text-gray-700 text-base md:text-lg mb-4">
      {{ 'errorPage.401.message' | transloco }}
    </p>

    <!-- Button Section -->
    <button
      class="bg-red-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-red-600 focus:outline-none"
      (click)="goToHomepage()"
    >
      {{ 'common.toHome' | transloco }}
    </button>
  </div>
</ng-template>

<ng-template #serverError>
  <div class="flex flex-col items-center justify-center bg-white p-4">
    <!-- Image Section -->
    <div class="max-w-xs md:max-w-md lg:max-w-lg mb-6 font-bold text-6xl"> 500 </div>
    <div class="max-w-xs md:max-w-md lg:max-w-lg mb-6 font-bold text-4xl"> {{'errorPage.500.title' | transloco}} </div>

    <!-- Text Section -->
    <p class="text-center text-gray-700 text-base md:text-lg mb-4">
      {{ 'errorPage.401.message' | transloco }}
    </p>

    <!-- Button Section -->
    <button
      class="bg-red-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-red-600 focus:outline-none"
      (click)="goToHomepage()"
    >
      {{ 'common.toHome' | transloco }}
    </button>
  </div>
</ng-template>

