import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {Observable} from 'rxjs';

import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../shared/service/api/api.service';
import {SearchService} from '../../shared/service/search/search.service';
import {FeaturedShop} from './models/featured-shop';

@Injectable({
  providedIn: 'root',
})
export class HomePageService {
  constructor(
    private apiService: ApiService,
    private translocoService: TranslocoService,
    private searchService: SearchService,
    private route: ActivatedRoute,
  ) {}

  private getReferrerUrlParam() {
    const queryParams = this.route.snapshot.queryParamMap;
    return queryParams.get('referrer');
  }

  public getBudgetShop(): Observable<FeaturedShop[]> {
    const url =
      '/api/v3/reservations/featured/budget?referrer=' +
      this.getReferrerUrlParam();
    return this.apiService.get<FeaturedShop[]>(url, null);
  }

  public getRecommended(getRatings = false): Observable<FeaturedShop[]> {
    const url =
      '/api/v3/reservations/featured/recommended?referrer=' +
      this.getReferrerUrlParam();
    return this.apiService.get<FeaturedShop[]>(url, null, { getRatings });
  }

  public getMichelin(): Observable<FeaturedShop[]> {
    const url =
      '/api/v3/reservations/featured/michelin?referrer=' +
      this.getReferrerUrlParam();
    return this.apiService.get<FeaturedShop[]>(url, null);
  }

  public getShopTags(): Observable<string[]> {
    const url = '/api/v3/reservations/shops/tags';
    return this.apiService.get<string[]>(url, null, {
      lang: this.translocoService.getActiveLang(),
    });
  }

  public getTakeMeExpressShops(): Observable<FeaturedShop[]> {
    const url =
      '/api/v3/reservations/featured/same-day?referrer=' +
      this.getReferrerUrlParam();
    return this.apiService.get<FeaturedShop[]>(url, null);
  }
}
