<ngb-toast
  class="text-dark"
  [class]="toast.classname"
  [header]="toast.header || ''"
  [autohide]="true"
  [delay]="toastService.delay"
  (hidden)="toastService.remove(toast)"
  *ngFor="let toast of toastService.toasts"
>
  <div class="toast-body">
    {{ toast.body }}
    <button
      title="close-toast"
      class="btn"
      (click)="toastService.remove(toast)"
    >
      <i class="bi bi-x-square"></i>
    </button>
  </div>
</ngb-toast>
