import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from "rxjs";
import { LoginRequest } from "../../../header/models/login";
import { ApiService } from "../api/api.service";
import { User } from "./models/user";
import { USER_ORIGIN } from "../../../concierge-app/concierge-app-const";
import { ConciergeReferral } from "./models/concierge-referral";

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private apiService: ApiService) {
  }

  postCreateLoginRequest(params: LoginRequest): Observable<LoginRequest> {
    return this.apiService.postJson("v3/concierge-users/login-requests", undefined, {
      email: params.email,
      referralSlug: params.referralSlug
    })
  }

  postLoginRequest(params: LoginRequest, headers: Record<string, string> | null): Observable<User> {
    return this.apiService.postJson(`v3/users/login_requests/${params.token}/login`,
      headers,
      {code: params.oneTimeCode})
  }

  postCreateSignupRequest(params: LoginRequest): Observable<User> {
    return this.apiService.postJson(`v3/concierge-users/signup-invitations`,
      undefined,
      {email: params.email, referralSlug: params.referralSlug})
  }

  postLoginAfterSignupRequest(params: LoginRequest): Observable<User> {
    return this.apiService.postJson(`v3/users/signup_invitations/${params.token}/signup`,
      undefined,
      {code: params.oneTimeCode, userOrigin: USER_ORIGIN, language: params.language})
  }

  postLogout(): Observable<void> {
    return this.apiService.postJson('v3/concierge-users/logout')
  }

  getUserMe(): Observable<User> {
    return this.apiService.get("v3/users/me")
  }

  getUserEmailExists(email: string): Observable<boolean> {
    return this.apiService.get("v3/concierge-users/", null, {email})
  }

  getConciergeReferral(referralSlug: string): Observable<ConciergeReferral> {
    const url = `/api/v3/reservations/referral/${referralSlug}`
    return this.apiService.get(url)
  }
}
