<!-- Login -->
<nav *ngIf="!meService.user" >
    <a routerLinkActive="active" class="nav-link flex gap-2 items-center py-2 px-4" (click)="openLoginModal()">
        <i class="bi  text-gray-500 bi-person-circle text-lg"></i>
        <span class="control-label flex-nowrap">{{ "header.login" | transloco }}</span>
    </a>
</nav>

<!-- Logged In -->
<nav  *ngIf="meService.user">
    <a (click)="this.headerControl.triggerHeaderRestart()" [routerLink]="['/app/dashboard/orders']" routerLinkActive="active" class="nav-link flex items-center py-2 px-4">
        <!-- Booking icon SVG path -->
        <span class="w-5 h-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
            <i class="bi bi-card-checklist"></i>
        </span>
        <span>{{'header.myBookings' | transloco }}</span>
    </a>
    <a (click)="this.headerControl.triggerHeaderRestart()" [routerLink]="['/app/dashboard/favourites']" routerLinkActive="active" class="nav-link flex items-center py-2 px-4">
        <span class="w-5 h-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
            <i class="bi bi-heart"></i>
        </span>
        <span>{{'header.favourites' | transloco }}</span>
    </a>
    <hr class="border-t border-gray-400 my-2"/>
    <a (click)="logout()" routerLinkActive="active" class="nav-link flex items-center py-2 px-4">
        <!-- Log out icon SVG path -->
        <span class="w-5 h-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
            <i class="bi bi-box-arrow-right"></i>
        </span>
        <span class="control-label">{{ "header.logout" | transloco }}</span>
    </a>
</nav>