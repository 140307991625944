import { Component, Input } from '@angular/core';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import { NavigationService } from "../shared/service/nav/navigation.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
  ]
})
export class FooterComponent {

  @Input()
  showTakeMeInfo: boolean = true;

  langHover = false;

  constructor(
    public navService: NavigationService,
    private translocoService: TranslocoService,
  ) {
  }

  get activeLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  keyValueSortOrder(): number {
    return 0;
  }
}
