import { Component, Input, OnInit } from '@angular/core';
import { extractTextFromHtml } from 'src/app/shared/utils/extract-text-from-html';
import { Tag } from './models/tag';
import { TranslocoService } from "@ngneat/transloco";
import {CdnPipe} from "../../../shared/pipe/cdn.pipe";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-tag-card',
  templateUrl: './tag-card.component.html',
  imports: [
    CdnPipe,
    NgIf
  ],
  standalone: true
})
export class TagCardComponent implements OnInit {
  @Input() tag!: Tag;
  desc!: string;

  constructor(public translocoService: TranslocoService) {
  }

  ngOnInit(): void {
    this.desc = extractTextFromHtml(this.tag.desc);
  }
}
