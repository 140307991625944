import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  noop,
  throwError,
} from 'rxjs';
import { Shop } from 'src/app/concierge-app/shop-list-view/models/shop-list';
import { LoginModalService } from 'src/app/header/login/login-modal.service';
import { RowResponse } from '../../models/row-response';
import { ApiService } from '../api/api.service';
import { MeService } from '../auth/me.service';

@Injectable({
  providedIn: 'root',
})
export class FavService implements OnDestroy {
  private favShops = new BehaviorSubject<Shop[]>(this.loadInitialState()); // Attribute to store favorite shops
  public favShopIdUpdate = this.favShops.asObservable();
  private loginSubscription!: Subscription;

  constructor(
    private meService: MeService,
    private apiService: ApiService,
    private loginService: LoginModalService,
  ) {
    if (this.meService.isloggedIn()) {
      this.getFav();
    } else {
      this.loginSubscription = this.meService.isLoggedIn$.subscribe(
        (isLoggedIn) => {
          if (isLoggedIn) {
            this.getFav();
          } else {
            // for logout case reset the fav shops and behaviour subject
            this.favShops.next([]);
            localStorage.removeItem('favShops');
          }
        },
      );
    }
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }

  isFav(shopId: string): boolean {
    return this.loadInitialState().some((shop) => shop.id === shopId);
  }

  getFav(): void {
    this.fetchFavShops().subscribe({
      next: (res) => {
        if (res) {
          this.favShops.next(res.rows);
          localStorage.setItem('favShops', JSON.stringify(res.rows));
        }
      },
      error: () => noop(),
    });
  }

  private fetchFavShops(): Observable<RowResponse<Shop>> {
    if (this.checkLogin()) {
      return this.apiService.get('/api/v3/reservations/shops/fav');
    } else {
      return throwError(() => new Error('User is not logged in'));
    }
  }

  updateFav(payload: { action: string; shopIds: string[] }) {
    if (this.checkLogin()) {
      this.apiService
        .postJson('/api/v3/reservations/shops/fav/update', null, payload)
        .subscribe({
          next: () => {
            this.getFav();
          },
          error: () => noop(),
        });
    }
  }

  private loadInitialState(): Shop[] {
    // Attempt to load initial state from localStorage or return an empty array
    const savedFavs = localStorage.getItem('favShops');
    return savedFavs ? JSON.parse(savedFavs) : [];
  }

  private checkLogin(): boolean {
    if (!this.meService.isloggedIn()) {
      this.loginService.openLoginModal();
      return false;
    }
    return true;
  }
}
