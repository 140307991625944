import { EventEmitter, Injectable } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ShopListFilterForm } from "../../../concierge-app/shop-list-view/models/shop-list-filter-form";
import { FILTER_PRICE_CURRENCY_LIMIT } from "../../currency/currency-const";
import { CurrencyConverterPipe } from "../../currency/currency-converter.pipe";
import { firstValueFrom, noop } from "rxjs";
import { InboundTypeahead } from "../../widgets/search-bar/model/InboundTypeahead";
import { ADDRESS_STATE, AddressState, LIST_FILTER } from "../../../concierge-app/shop-list-view/models/shop-list-const";
import { NavigationService } from "../nav/navigation.service";
import { FormControlOf } from "../../utils/form-utils";
import { assign, isBoolean, isEmpty, isNil, isNumber, pickBy } from "lodash-es";
import { AnalyticsEventCategory, sendEvent } from "../../analytics/analytics";
import { ShopListApiService } from "../../../concierge-app/shop-list-view/services/shop-list-api.service";
import { Tag, TagCategory } from "../../../concierge-app/home-page/models/tag";
import { ShopListParams } from "../../../concierge-app/shop-list-view/models/shop-list-params";
import { DEFAULT_PAGE_SIZE } from "../../models/pagination-manager";
import { MeService } from "../auth/me.service";
import { AuthApiService } from "../auth/auth-api.service";
import { CONCIERGE_REFERRAL_TYPE, DEFAULT_ADDRESS_STATE } from "../auth/models/concierge-referral";
import { ReferralService } from "../referrer/referral.service";
import { ShopListTagRequest } from "../../../concierge-app/shop-list-view/models/shop-list-tag-request";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private currencyConverter: CurrencyConverterPipe,
              private shopListApiService: ShopListApiService,
              private authService: AuthApiService,
              private meService: MeService,
              private referrerService: ReferralService,
              private navService: NavigationService) {

    this.initConciergeReferralInitialFilter().then(noop);
    this.getLoadedTags().then(noop);
  }

  ADDRESS_STATE_VALUES = Object.values(ADDRESS_STATE);
  defaultState = ADDRESS_STATE[AddressState.tokyo];
  defaultStateInitializeEvent: EventEmitter<void> = new EventEmitter()
  defaultStateInitialized = false;
  defaultCurrencyLimit = this.currencyConverter.getActiveCurrencyLimit();

  mappedTagsByName: Record<string, Tag> = {};
  mappedTagsByEnum: Record<string, Tag> = {};
  tags: Tag[] = [];

  submitEvent: EventEmitter<LIST_FILTER> = new EventEmitter<LIST_FILTER>();

  private readonly DEFAULT_FORM: { [key in keyof Partial<ShopListFilterForm>]: unknown } = {
    addressState: "",
    priceRange: [0, this.defaultCurrencyLimit],
    globalSearchArgs: '' as string | InboundTypeahead | null,
    requestMichelin: false,
    tagEnums: [] as string[],
  }

  filterForm = new FormGroup<FormControlOf<ShopListFilterForm>>({
    addressState: new FormControl<string | null>(''),
    priceRange: new FormControl<[number, number]>([0, this.defaultCurrencyLimit]),
    globalSearchArgs: new FormControl<string | InboundTypeahead | null>(''),
    requestMichelin: new FormControl<boolean>(false),
    requestSameDay: new FormControl<boolean>(false),
    tagEnums: new FormControl<string[] | null>([]),
    sortBy: new FormControl<string>('0')
  })

  public async getLoadedTags(): Promise<Tag[]> {
    if (this.tags?.length) {
      return this.tags;
    } else {

      return await firstValueFrom(this.shopListApiService.getShopTags(this.getShopListTagParams())).then(tags => {
        this.tags = tags;

        tags?.forEach(tag => {
          this.mappedTagsByName[tag.tag?.toLowerCase()] = tag
          this.mappedTagsByEnum[tag.tagEnum] = tag
        })
        return this.tags;
      });
    }
  }

  private getShopListTagParams(): ShopListTagRequest {
    return {
      limit: 20,
      minTagCount: 3,
      tagCategories: [TagCategory.CUISINE.toString()]
    }
  }

  public async initConciergeReferralInitialFilter(): Promise<void> {
    if (this.filterForm.controls.addressState.dirty) {
      return
    }

    this.handleDefaultStateBasedOnReferrerType();

    this.defaultStateInitializeEvent.emit();
    this.defaultStateInitialized = true;
  }

  private handleDefaultStateBasedOnReferrerType(): void {
    const referrer = this.referrerService.getConciergeReferrer();
    let defaultAddressState = DEFAULT_ADDRESS_STATE; //For now assume all referrers will use Tokyo as default

    if (referrer?.referralType === CONCIERGE_REFERRAL_TYPE.HOTEL && referrer.addressState) {
      const savedAddressState = referrer.addressState;
      Object.values(ADDRESS_STATE).forEach((value, index) => {
        if (value.jap === savedAddressState) {
          defaultAddressState = value
        }
      })
    }

    this.filterForm.controls.addressState.setValue(defaultAddressState.default)
    this.defaultState = defaultAddressState
  }

  public resetFilters(): void {
    const currentSortBy = this.filterForm.controls.sortBy.value;
    this.filterForm.reset(this.DEFAULT_FORM as any)
    this.filterForm.controls.priceRange.reset([0, this.currencyConverter.getActiveCurrencyLimit()])
    this.filterForm.controls.addressState.reset(this.defaultState.default)
    this.filterForm.controls.sortBy.setValue(currentSortBy)
    this.submitEvent.emit()
  }

  public resetFilter(formControl: FormControl): void {
    if (formControl === this.filterForm.controls.priceRange) {
      formControl.reset([0, this.currencyConverter.getActiveCurrencyLimit()])
      return;
    }

    if (formControl === this.filterForm.controls.addressState) {
      formControl.reset(this.defaultState.default)
      return;
    }

    for (let formControlName of Object.keys(this.filterForm.controls)) {
      if (this.filterForm.get(formControlName) === formControl) {
        this.filterForm.get(formControlName)?.reset(this.DEFAULT_FORM?.[formControlName] ?? null)
        break;
      }
    }
  }

  async getParams(pagination?: ShopListParams): Promise<Record<string, unknown>> {
    let params: ShopListParams = {
      limit: pagination?.limit || DEFAULT_PAGE_SIZE,
      offset: pagination?.offset || 0,
    }

    const searchParams = await this.handleFilterSubmitForm() ?? {};

    params = assign(params, searchParams);

    return pickBy(params, value => !isNil(value)) as Record<string, string>;
  }

  async formatSubmitFormPriceRangeToYen(): Promise<number[]> {
    const priceRange = this.filterForm.controls.priceRange.value;
    return await this.currencyConverter.getYenEquivalentArray(this.currencyConverter.getActiveCurrency(), priceRange as number[]).then(yenValue => {
      yenValue[0] = Math.round(yenValue[0]) ?? 0;
      yenValue[1] = Math.round(yenValue[1]) ?? FILTER_PRICE_CURRENCY_LIMIT.JPY;
      return yenValue;
    })
  }

  public async handleFilterSubmitForm() {
    const priceRangeYen = await this.formatSubmitFormPriceRangeToYen();
    let searchParams = this.filterForm.value;

    const costParams = {
      priceRangeStart: priceRangeYen?.[0],
      priceRangeEnd: priceRangeYen?.[1],
    }

    searchParams = assign(searchParams, costParams)

    const globalSearchArgs = this.getFormattedSearchArgs();

    if (globalSearchArgs) {
      searchParams.globalSearchArgs = globalSearchArgs;
      sendEvent(AnalyticsEventCategory.TYPEAHEAD, globalSearchArgs as string)
    }

    return pickBy(searchParams, (value) => isBoolean(value) || !isEmpty(value) || isNumber(value));
  }

  public getFormattedSearchArgs(): string | null {
    const globalSearchArgs = this.filterForm.controls.globalSearchArgs.value;

    if (!globalSearchArgs) {
      return null;
    }

    if (typeof globalSearchArgs === 'string') {
      return `${globalSearchArgs}`
    } else {
      return `${this.searchFormatter(globalSearchArgs as InboundTypeahead)}`
    }
  }

  submitAndNavToList(): void {
    if (location.pathname != '/app/list') {
      this.navService.navigate(['/app/list']).then(noop)
      return;
    } else {
      this.submitEvent.emit()
    }
  }

  submitSearchOutsideListPage(): void {
    this.handleSubmitType(LIST_FILTER.SEARCH)
    this.submitAndNavToList();
  }

  filterTagNav(value: string) {
    if (value?.toLowerCase() === 'michelin') {
      this.filterNav(this.filterForm.controls.requestMichelin, true)
    } else {
      this.filterNav(this.filterForm.controls.tagEnums, [value])
    }
  }

  // REFACTOR LATER, VERY TEMPORARY
  tempNav(value: string): void {
    setTimeout(() => {
      this.filterTagNav(value)
    }, 500)

  }

  tempNavSearch(): void {
    this.handleSubmitType(LIST_FILTER.SEARCH)
    setTimeout(() => {
      this.submitAndNavToList()
    }, 500)

  }

  filterNav(formControl: FormControl, value: unknown) {
    this.resetFilters();

    if (formControl && value) {
      formControl?.setValue(value)
      formControl?.markAsDirty()
    }

    this.submitAndNavToList()
  }

  searchFormatter(ahead: InboundTypeahead): string {
    if (ahead.type === 'QueryInboundSearchTypeahead') {
      return ahead.query
    }
    if (ahead.type === 'TagInboundSearchTypeahead') {
      return ahead.tagEnum
    }
    if (ahead.type === 'PrefectureInboundSearchTypeahead') {
      return ahead.prefecture
    }
    return ''
  }

  public handleSearchEvent(): void {
    const searchTerm = this.getFormattedSearchArgs();

    if (!searchTerm) {
      return;
    }

    for (let index = 0; index < this.ADDRESS_STATE_VALUES.length; index++) {
      const value = this.ADDRESS_STATE_VALUES[index];

      if (value.default?.toLowerCase() === searchTerm?.toLowerCase()) {
        this.filterForm.controls.addressState.setValue(value.default)
        this.filterForm.controls.addressState.markAsDirty()
        return;
      }
    }

    if (searchTerm.toLowerCase() === "michelin") {
      this.filterForm.controls.requestMichelin.setValue(true)
      return;
    }

    const tag = this.mappedTagsByName?.[searchTerm]
    if (tag && tag?.tagEnum) {
      this.filterForm.controls.tagEnums.setValue([tag.tagEnum])
    }
  }

  public handleFilterEvent(): void {
    const searchTerm = this.getFormattedSearchArgs();
    const stateValue = this.filterForm.controls.addressState.value;

    if (stateValue !== searchTerm) {
      for (let index = 0; index < this.ADDRESS_STATE_VALUES.length; index++) {
        if (searchTerm?.toLowerCase() === this.ADDRESS_STATE_VALUES[index].default?.toLowerCase()) {
          this.filterForm.controls.globalSearchArgs.reset('')
          break;
        }
      }
    }
  }

  public handleSubmitType(filter: LIST_FILTER): void {
    if (filter === LIST_FILTER.SEARCH) {
      this.handleSearchEvent();
    }

    if (filter === LIST_FILTER.FILTER) {
      this.handleFilterEvent();
    }
  }

  onCurrencyChange(): void {
    const newCurrencyLimit = this.currencyConverter.getActiveCurrencyLimit();
    const priceRangeEndControl = this.filterForm.controls.priceRange;
    const priceRangeValue = priceRangeEndControl.value;

    if (priceRangeValue) {
      priceRangeValue[1] = newCurrencyLimit;
    }
    priceRangeEndControl.patchValue(priceRangeValue)
  }
}
