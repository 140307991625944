import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-base-button',
  templateUrl: './base-button.component.html',
  styleUrls: ['./base-button.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ]
})
export class BaseButtonComponent {
  @Input() label: string = '';
  @Input() subLabel: string = '';
  @Input() subLabelClass: string = '';
  @Input() labelState: string = '';
  @Input() labelClass: string = '';
  @Input() labelSecondary: string = '';
  @Input() labelSecondaryClass: string = '';
  @Input() dropdownName: string = '';

  @Input() isDropDown: boolean = false;
  @Input() hideBorder = false;
  @Input() activeState: boolean | null = false;
  @Input() shopDropArrow: boolean = true;

  @Input() control!: FormControl;

  @Output() submitEvent: EventEmitter<void> = new EventEmitter<void>();

  onClick(button: HTMLButtonElement) {
    if (!this.isDropDown) {
      button.blur();
      this.submitEvent.emit();
    }
  }
}
