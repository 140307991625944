import { Injectable } from '@angular/core';
import { TranslocoService } from "@ngneat/transloco";
import { Observable } from "rxjs";
import { RowResponse } from "../../../shared/models/row-response";

import { ApiService } from "../../../shared/service/api/api.service";
import { Shop } from "../models/shop-list";
import { ShopListParams } from "../models/shop-list-params";
import { Tag } from "../../home-page/models/tag";
import { ShopListTagRequest } from "../models/shop-list-tag-request";

@Injectable({
  providedIn: 'root'
})
export class ShopListApiService {

  constructor(private apiService: ApiService, private translocoService: TranslocoService) {
  }

  public getShopList(params: ShopListParams): Observable<RowResponse<Shop>> {
    const url = "/api/v3/reservations/shops";
    return this.apiService.get<RowResponse<Shop>>(url, null, params);
  }

  public getShopTags(request: ShopListTagRequest): Observable<Tag[]>{
    const url = "api/v3/reservations/tag-reference/tags";
    return this.apiService.get<Tag[]>(url, null, request);
  }

  public getShopListFilteredCount(params: ShopListParams): Observable<number> {
    const url = "/api/v3/reservations/shops/filtered-count";
    return this.apiService.get<number>(url, null, params);
  }

}
