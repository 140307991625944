<div class="hidden  flex-col md:flex-row  mt-10 justify-around items-start bg-tm-grey h-full sm:h-[276px]">
    <div class="flex flex-col p-8 justify-center items-start max-w-[567px]">
        <div class="text-xl font-medium mb-2">{{ 'personalizedBookingBanner.title' | transloco }}</div>
        <div class="text-xl font-medium mb-4">{{ 'personalizedBookingBanner.subtitle' | transloco }}</div>
        <p class="text-gray-600 mb-6">{{ 'personalizedBookingBanner.description' | transloco }}</p>
        <button class="px-6 py-2 bg-red-600 text-white font-medium rounded-lg flex gap-2 items-center"(click)="openBookingModal()" id="personalizedGetStartedButton">
            <i class="bi bi-envelope text-xs sm:text-sm"></i>
            {{ 'personalizedBookingBanner.buttonText' | transloco }}
        </button>
    </div>
    <img class="hidden md:hidden h-full w-[567px] object-cover" src="../../../../../assets/images/personalized-booking.jpeg" alt="" loading="lazy">
</div>