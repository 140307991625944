import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef, } from '@ng-bootstrap/ng-bootstrap';
import { NavDismissNgb } from "./nav-dismiss-ngb";

@Injectable({
  providedIn: 'root',
})
export class CustomModal extends NavDismissNgb<NgbModal, NgbModalRef, NgbModalOptions> {
  constructor(modalClass: NgbModal) {
    super(modalClass)
  }
}
