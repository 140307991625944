import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function membershipAgreeValidator(
  firstControl: string,
  secondControl: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const first = control.get(firstControl)?.value;
    const second = control.get(secondControl)?.value;
    if (first && first.length && !second) {
      return { fillTogether: 'Please fill both' };
    }
    return null;
  };
}
