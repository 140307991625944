import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  ResolveFn,
} from '@angular/router';
import { firstValueFrom, noop } from 'rxjs';
import { PredefinedPaymentResponse } from 'src/app/concierge-app/shop-view-booking/payment/models/predefined-payment-response';
import { PaymentApiService } from 'src/app/concierge-app/shop-view-booking/payment/service/payment-api-service';
import {
  isSavedReservationInterface,
  SavedReservation,
} from '../../../../concierge-app/shop-view-booking/course-reservation/models/saved-reservation';
import { ReservationApiService } from '../../../../concierge-app/shop-view-booking/course-reservation/service/reservationApiService';
import {
  PaymentStatus,
  ReservationStatus,
} from '../../../models/reservation-const';
import { NavigationService } from '../../nav/navigation.service';
import { MeService } from '../me.service';

@Injectable({
  providedIn: 'root',
})
export class ReservationPaymentGuardService {
  private reservationApiService = inject(ReservationApiService);
  private paymentApiService = inject(PaymentApiService);
  public navService = inject(NavigationService);

  async getReservation(
    reservationId: string,
  ): Promise<SavedReservation | null> {
    let reservation = null;
    let hasError = false;

    await firstValueFrom(
      this.reservationApiService.getReservation(reservationId),
    )
      .then(
        (successResponse) => (reservation = successResponse),
        (errorResponse) => {
          hasError = true;
        },
      )
      .catch(() => noop);

    if (hasError) {
      this.navService.navigate(['/app/error'], {
        queryParams: { type: '401' },
        skipLocationChange: true,
      });
      throw new Error('Forbidden to access resource');
    }

    return reservation;
  }

  async getPayment(
    resId: string,
    email: string,
  ): Promise<PredefinedPaymentResponse | null> {
    let payment = null;
    let hasError = false;

    await firstValueFrom(
      this.paymentApiService.getPaymentWithEmail(resId, email),
    )
      .then(
        (successResponse) => (payment = successResponse),
        (errorResponse) => {
          hasError = true;
        },
      )
      .catch(() => noop);

    if (hasError) {
      throw new Error('Forbidden to access resource');
    }
    return payment;
  }

  public showResultPage(
    reservation: SavedReservation,
    payment: PredefinedPaymentResponse,
  ): boolean {
    return (
      reservation.reservationStatus !== ReservationStatus.CREATED ||
      reservation.paymentStatus === PaymentStatus.PAID ||
      payment.isExpired
    );
  }

  public reservationIsPaid(reservation: SavedReservation): boolean {
    return reservation.paymentStatus === PaymentStatus.PAID;
  }
}

export const CanAccessReservationDetails: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
) => {
  const service = inject(ReservationPaymentGuardService);

  const parameters = next.params as { resId: string };
  const reservationId = parameters.resId;

  if (!reservationId) {
    return service.navService.navigate(['']);
  }

  let reservation = null;

  try {
    reservation = await service.getReservation(reservationId);
  } catch {
    return service.navService.navigate(['']);
  }

  if (!reservation) {
    return service.navService.navigate(['']);
  }

  return true;
};

export const PaymentPageResolver: ResolveFn<any> = async (
  next: ActivatedRouteSnapshot,
) => {
  const service = inject(ReservationPaymentGuardService);
  const meService = inject(MeService);

  const parameters = next.params as { number: string; resId: string };
  const reservationId = parameters.resId;

  const email = next.queryParams['email'] || (meService.user?.email as string);

  if (!reservationId) {
    return service.navService.navigate(['']);
  }

  let reservation = null;
  let payment = null;

  try {
    reservation = await service.getReservation(reservationId);
    payment = await service.getPayment(reservationId, email);
  } catch {
    return service.navService.navigate(['']);
  }

  if (!reservation || !payment || !isSavedReservationInterface(reservation)) {
    return service.navService.navigate(['']);
  }

  // If the confirmation page route changes, this will need to be updated.
  if (service.showResultPage(reservation, payment)) {
    return service.navService.navigate([
      `/app/confirmation/${reservationId}/result`,
    ]);
  }

  return { reservation, payment };
};
