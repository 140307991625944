<div
  class="nav-container items-center gap-2 flex-col-reverse sm:flex-row"
>
  <!-- Help Center -->
  <div class="nav-item w-full sm:w-min py-[7px] justify-between">
    <div class="flex w-max gap-2">
      <i class="bi bi-question-circle"></i>
      <a routerLink="/app/help-center" (click)="this.headerControl.triggerHeaderRestart()" class="space-x-0 flex-shrink-0">
        {{'common.help' | transloco }}
      </a>
    </div>
  </div>

  <!-- LANG switch -->
  <div class="nav-item w-full sm:w-min justify-between">
    <div class="flex gap-2 sm:hidden">
      <i class="bi bi-globe"></i>
      <span>{{'common.language' | transloco }}</span>
    </div>
    <div class="lang">
      <div ngbDropdown class="lang-switch" container="body">
        <button class="btn" id="dropdownLang" ngbDropdownToggle>
          <span>{{ supportedLanguagesMap[activeLanguage] }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownLang" class="mt-3 rounded-lg shadow-lg">
          <ng-container *ngFor="let lang of supportedLanguagesMap | keyvalue : keyValueSortOrder">
            <!--We are currently not supporting Japanese-->
            <ng-container *ngIf="showAllLanguages || !['ja'].includes(lang.key)">
              <button ngbDropdownItem (click)="activeLanguage = lang.key">
                {{ lang.value }}
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Currency Switch -->
  <div class="nav-item w-full sm:w-min justify-between">
    <div class="flex gap-2 sm:hidden">
      <i class="bi bi-cash-stack"></i>
      <span>{{'common.currency' | transloco}}</span>
    </div>
    <div class="currency">
      <div ngbDropdown container="body">
        <button class="btn" ngbDropdownToggle>
          <span>{{ currencyConverter.getActiveCurrency() }}</span>
        </button>

        <div ngbDropdownMenu class="mt-3 rounded-lg shadow-lg">
          <ng-container *ngFor="let newCurrency of supportedCurrencies">
            <button ngbDropdownItem (click)="setActiveCurrency(newCurrency)">
              {{ newCurrency }}
            </button>
          </ng-container>
        </div>
      </div>

      <div class="powered-by">
      </div>
    </div>
  </div>

  <!-- DROPDOWN -->
  <div ngbDropdown class="nav-menu hidden sm:block" container="body" #dashboardDropDown="ngbDropdown">
    <button type="button" title="person" class="btn remove-arrow" id="dropdownMenu" ngbDropdownToggle>
      <i class="bi text-tm-red bi-person-circle text-lg"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMenu" class="mt-3 shadow-lg rounded-lg">
      <ng-container>
        <div class="py-2 bg-white">
          <app-profile-viewer></app-profile-viewer>
          <app-dashboard-nav></app-dashboard-nav>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="w-full sm:hidden">
    <app-dashboard-nav></app-dashboard-nav>
  </div>

</div>
