import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * Transform a relative path to absolute URL regarding cdnUrl
 * @param path path without base url
 */
@Pipe({
  name: 'cdn',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class CdnPipe implements PipeTransform {
  private webp: boolean;

  constructor() {
    this.webp = CdnPipe.isWebpSupported();
  }

  transform(value: string, size?: string): string {
    if (
      !value ||
      (value.startsWith('http') &&
        !value.startsWith('https://app.takeme.com/imgs/'))
    ) {
      return value;
    }
    const extension = this.webp ? '.webp' : undefined;
    if (size == 'set') {
      return [
        { suffix: 's', size: 180 },
        { suffix: 'm', size: 720 },
        { suffix: 'l', size: 960 },
        { suffix: undefined, size: 980 },
      ]
        .map((def) => {
          return `${this.srcForSize(value, def.suffix, extension)} ${
            def.size
          }w`;
        })
        .join(',');
    }
    return this.srcForSize(value, size, extension);
  }

  private srcForSize(value: string, size?: string, extension?: string): string {
    if (size) {
      const dotIdx = value.lastIndexOf('.');
      value = `${value.substring(0, dotIdx)}_${size}${
        extension ?? value.substring(dotIdx)
      }`;
    }
    const cdnUrl = environment.cdnUrl;
    if (value.startsWith('https://')) {
      return value;
    } else if (value[0] === '/') {
      return `${cdnUrl}${value}`;
    } else {
      return `${cdnUrl}/${value}`;
    }
  }

  private static isWebpSupported(): boolean {
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    return false;
  }
}
