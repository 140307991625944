import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatestWith, firstValueFrom } from 'rxjs';
import { NavigationService } from '../../nav/navigation.service';
import { ToastService } from '../../toast/toast.service';
import { MeService } from '../me.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  meService = inject(MeService);
  router = inject(Router);
  toastService = inject(ToastService);
  translocoService = inject(TranslocoService);
  navService = inject(NavigationService);

  async canActivate(): Promise<boolean> {
    return await firstValueFrom(this.meService.getLoginUser()).then(() => true);
  }

  async canActivateLoginRequired(): Promise<boolean> {
    await this.canActivate();
    if (this.meService.user != null) {
      return true;
    } else {
      const errorHeaderObservable = this.translocoService.selectTranslate(
        'error.requireLoginHeader',
      );
      const errorMessageObservable =
        this.translocoService.selectTranslate('error.requireLogin');

      firstValueFrom(
        errorHeaderObservable.pipe(combineLatestWith(errorMessageObservable)),
      );

      return this.navService.navigate(['/app/']);
    }
  }

  async canActivateResultPage(): Promise<boolean> {
    await this.canActivate();
    if (this.meService.user != null) {
      return true;
    } else {
      // #TODO optimise this
      setTimeout(() => {
        this.navService.navigate(['/app/result/redirect']);
      }, 500);
      return false;
    }
  }
}

export const UserResolverActivate: CanActivateFn = () => {
  return inject(AuthGuardService).canActivate();
};

export const CanActivateLoginRequired: CanActivateFn = () => {
  return inject(AuthGuardService).canActivateLoginRequired();
};

export const CanActivateResultPage: CanActivateFn = () => {
  return inject(AuthGuardService).canActivateResultPage();
};
