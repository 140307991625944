import { Injectable } from "@angular/core";
import { ConciergeReferral } from "../auth/models/concierge-referral";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ReferralService {

  // Values will be set by referrer-guard.service.ts
  referrerSubject$ = new BehaviorSubject<ConciergeReferral | null>(null);

  PUBLIC_REFERRAL_SLUG = 'japanfoodie';
  URL_REFERRER_PARAM = 'referrer';


  /**
   * Prefer using this rather than internalCookieService.getReferrer() to get information about the referrer
   */
  getConciergeReferrer(): ConciergeReferral {
    return this.referrerSubject$.getValue() as ConciergeReferral;
  }

  /**
   * Technically the below getter can be undefined, but it's extremely unlikely.
   * If undefined occurs, there may be a condition in referrer-guard.service.ts that is not being met.
   */
  public getReferrerSlug(): string {
    return this.referrerSubject$.getValue()?.slug as string;
  }

  public updateReferralSubjectWithValue(referral: ConciergeReferral) {
    this.referrerSubject$.next(referral);
  }

  public clearReferralSubject() {
    this.referrerSubject$.next(null);
  }

}
