<div class="footer-container border-gray-300 border-t">
  <div class="mx-auto p-4 w-full md:w-[80vw] text-center">
    <div class="footer-items justify-around">
      <div class="footer-item">
        <div class="ft-ti"><span>{{ 'footer.featured' | transloco }}</span></div>
        <div class="text-xs"><span>({{ 'footer.featuredSorted' | transloco }})</span></div>
        <div class="payment-brand-img"><img class="w-80 h-auto" [src]="'./assets/images/featured.png'" alt="利用可能ブランド"></div>
      </div>
      <div class="footer-item">
        <div class="ft-ti"><span>{{ 'footer.metiJapan' | transloco }}</span></div>
        <div class="payment-brand-img"><img class="w-[36rem] h-auto" [src]="'./assets/images/meti.png'" alt="利用可能ブランド"></div>
      </div>
      <div class="footer-item">
        <div class="ft-ti"><span>{{ 'footer.paySecurely' | transloco }}</span></div>
        <div class="payment-brand-img"><img [src]="'./assets/images/accepted_payment_methods.png'" alt="利用可能ブランド"></div>
      </div>
    </div>
    <div class="footer-items mt-6 justify-around">
      <div class="footer-item">
        <div class="ft-ti">
          <span>{{ 'footer.termsOfUse' | transloco }}</span>
        </div>
        <div class="ft-row">
          <a [href]="navService.getUrlWithReferrer('app/legal?type=privacyPolicy')">{{ 'footer.privacyPolicy' | transloco }}</a>
        </div>
        <div class="ft-row">
          <a [href]="navService.getUrlWithReferrer('app/legal?type=cookiePolicy&lang=' + activeLanguage)"></a>
          <a [href]="navService.getUrlWithReferrer('app/legal?type=cookiePolicy')">{{ 'footer.cookiePolicy' | transloco }}</a>
        </div>
        <div class="ft-row">
          <a [href]="navService.getUrlWithReferrer('app/legal?type=cancellationPolicy')">{{ 'cancellation.cancellationPolicy' | transloco }}</a>
        </div>
        <div class="ft-row">
          <a [href]="navService.getUrlWithReferrer('app/legal?type=cathayPolicy')">{{ 'footer.cathayPolicy' | transloco }}</a>
        </div>
      </div>
      <div class="footer-item">
        <div class="ft-ti">
          <span>{{ 'footer.contact' | transloco }}</span>
        </div>
        <div class="ft-row">
          <div class="mail">
            <i class="bi bi-envelope-fill me-2"></i>
            <a
              href="mailto:service@takeme.com"
              data-email="support"
              data-domain="takeme.com"
              >service&#64;takeme.com</a
            >
          </div>
          <div class="ft-row">
            <a [href]="navService.getUrlWithReferrer('app/support/contact?')">{{ 'footer.contactUs' | transloco }}</a>
          </div>
          <div class="ft-row">
            <a href="https://www.takeme.com/">{{ 'footer.about' | transloco }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-container-secondary">
  <div class="mx-auto p-4 w-full md:w-[80vw] text-center">
    <div class="footer">
      <div class="lh">
        <a href="https://www.takeme.com/">
          <div class="flex flex-col justify-center align-items-center">
            <img class="logo" src='../../assets/images/takeme-jtb.svg' alt="TakeMe" />
            <span class="text-white text-xs mt-1">{{ 'footer.jtb' | transloco}}</span>
          </div>
        </a>
        <!--        <address>〒105-0003<br>東京都港区西新橋1-18-6<br class="sp">クロスオフィス内幸町605号室</address>-->
        <address>{{ 'footer.addressLine1' | transloco }}<br>{{ 'footer.addressLine2' | transloco }}<br class="sp">{{ 'footer.addressLine3' | transloco }}</address>
      </div>

      <div class="rh">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/japanfoodie/"
              target="_blank"
              rel="noopener noreferrer"
              ><img
                src="https://www.takeme.com/wp-content/themes/takeme/img/sn_fb.png"
                alt="TakeMe Facebook"
            /></a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCdDhaef5OB1xYWn_rsujXZw"
              target="_blank"
              rel="noopener noreferrer"
              ><img
                src="https://www.takeme.com/wp-content/themes/takeme/img/sn_yt.png"
                alt="TakeMe Youtube"
            /></a>
          </li>
          <li>
            <a
              href="https://twitter.com/TakeMe_Co_Ltd"
              target="_blank"
              rel="noopener noreferrer"
              ><img
                src="../../assets/images/x.png"
                alt="X account"
            /></a>
          </li>
        </ul>
        <div class="copyright-info">
          <span class="original">© TakeMe K.K. All rights reserved.</span>
        </div>
      </div>
    </div>
  </div>
</div>
