export enum AddressState {
  tokyo,
  kyoto,
  okinawa,
  osaka,
  aichi,
  hokkaido,
  fukuoka,
  hyogo,
  kanagawa,
  oita
}
export const getAddressStateFromString = (stateName: string): AddressState =>
  ({
    tokyo: AddressState.tokyo,
    kyoto: AddressState.kyoto,
    okinawa: AddressState.okinawa,
    osaka: AddressState.osaka,
    aichi: AddressState.aichi,
    hokkaido: AddressState.hokkaido,
    fukuoka: AddressState.fukuoka,
    oita: AddressState.oita,
    kanagawa: AddressState.kanagawa,
    hyogo: AddressState.hyogo,
  })[stateName.toLowerCase()] || AddressState.tokyo;

export const ADDRESS_STATE = {
  [AddressState.tokyo]: {
    label: "filter.states.tokyo",
    default: "Tokyo",
    jap: '東京都'
  },
  [AddressState.kyoto]: {
    label: "filter.states.kyoto",
    default: "Kyoto",
    jap: '京都府'
  },
  [AddressState.okinawa]: {
    label: "filter.states.okinawa",
    default: "Okinawa",
    jap: '沖縄県'
  },
  [AddressState.osaka]: {
    label: "filter.states.osaka",
    default: "Osaka",
    jap: '大阪府'
  },
  [AddressState.aichi]: {
    label: "filter.states.aichi",
    default: "Aichi",
    jap: '愛知県'
  },
  [AddressState.hokkaido]: {
    label: "filter.states.hokkaido",
    default: "Hokkaido",
    jap: '北海道'
  },
  [AddressState.fukuoka]: {
    label: "filter.states.fukuoka",
    default: "Fukuoka",
    jap: '福岡県'
  },
  [AddressState.hyogo]: {
    label: "filter.states.hyogo",
    default: "Hyogo",
    jap: "兵庫県"
  },
  [AddressState.kanagawa]: {
    label: "filter.states.kanagawa",
    default: "Kanagawa",
    jap: "神奈川県"
  },
  [AddressState.oita]: {
    label: "filter.states.oita",
    default: "Oita",
    jap: "大分県"
  }
}

export enum LIST_FILTER {
  FILTER,
  SEARCH
}
