import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingBarService } from '../service/loading/loading-bar.service'

export const LOADING_INTERCEPTOR_QUIET = 'x-takeme-loading-interceptor-quiet';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private requestCount = 0;

  constructor(private loadingBarService: LoadingBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const quiet = req.headers.has(LOADING_INTERCEPTOR_QUIET);
    if (quiet) {
      req.headers.delete(LOADING_INTERCEPTOR_QUIET);
    }
    if (quiet) {
      return next.handle(req);
    }
    this.startOne();
    return next.handle(req).pipe(finalize(() => {
        this.stopOne();
      })
    );
  }

  private startOne(): void {
    this.requestCount++;
    this.loadingBarService.start();
  }

  private stopOne(): void {
    this.requestCount--;
    if (this.requestCount === 0) {
      this.loadingBarService.stop();
    }
  }
}
