export interface Tag {
    tag: string;
    tagEnum: string;
    imageUrl: string;
    desc: string;
    tagReferenceId: string;
    tagCount: number;
    tagCategory: TagCategory;
}

export enum TagCategory {
    CUISINE = 1,
    FEATURE = 2,
    AWARD = 3,
}
