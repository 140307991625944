import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, firstValueFrom, noop } from 'rxjs';
import { ShopListApiService } from '../../concierge-app/shop-list-view/services/shop-list-api.service';
import { SearchService } from '../../shared/service/search/search.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  mapViewControl = new FormControl<boolean>(false);
  filteredCount: number = 0;

  constructor(
    private shopListApiService: ShopListApiService,
    private searchService: SearchService,
  ) {
    this.searchService.filterForm.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.getFilteredCount().then(noop);
      });
  }

  async getFilteredCount(): Promise<void> {
    const params = await this.searchService.getParams();
    firstValueFrom(
      this.shopListApiService.getShopListFilteredCount(params),
    ).then((response) => {
      this.filteredCount = response;
    });
  }

  refreshCount(): void {
    this.getFilteredCount().then(noop);
  }

  get mapView() {
    return this.mapViewControl.value;
  }

  get showMapView() {
    return this.mapView;
  }

  toggleMapView(): void {
    this.mapViewControl.setValue(!this.mapView);
  }
}
