export enum SupportedCurrency {
    JPY = "JPY",
    CNY = "CNY",
    USD = "USD",
    EUR = "EUR",
    KRW = "KRW"
}

export const FILTER_PRICE_CURRENCY_LIMIT: Record<SupportedCurrency, number> = {
    [SupportedCurrency.JPY]: 70000,
    [SupportedCurrency.CNY]: 5000,
    [SupportedCurrency.USD]: 500,
    [SupportedCurrency.EUR]: 500,
    [SupportedCurrency.KRW]: 1000000
}

export const FILTER_PRICE_CURRENCY_STEP: Record<SupportedCurrency, number> = {
    [SupportedCurrency.JPY]: 1000,
    [SupportedCurrency.CNY]: 500,
    [SupportedCurrency.USD]: 50,
    [SupportedCurrency.EUR]: 50,
    [SupportedCurrency.KRW]: 10000
}
