<article
  class="flex flex-col md:block w-full h-full relative overflow-hidden hover:opacity-75 hover:cursor-pointer bg-white bg-clip-border text-gray-700"
  (click)="onLocationClick(data.addressState)">
  <div class="md:absolute md:inset-0 md:m-0 md:h-full md:w-full flex-grow rounded-xl overflow-hidden bg-transparent">
    <img [src]="data.desktopImageUrl | cdn:'m'" class="hidden md:block object-cover h-full w-full" [alt]="data.cityName"
         loading="lazy">
    <img [src]="data.mobileImageUrl | cdn:'s'" [ngClass]="data.mobileImageScaleClass"
         class="md:hidden object-cover w-full h-full" [alt]="data.cityName" loading="lazy">
    <div class="absolute inset-0 w-full h-full to-bg-black-10 md:bg-gradient-to-t from-black/70 via-black/10"></div>
  </div>
  <h5 class="mb-0 text-base min-h-6 font-medium md:absolute md:bottom-0 md:mb-5 md:mx-auto md:text-white md:inset-x-0 md:text-center">
    {{ cityDisplayValue | transloco }}
  </h5>
</article>
