<div class="flex items-center px-4 mb-4">
    <div class="p-2 rounded-full">
      <!-- Replace with your user icon -->
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.4667 14.9333C27.4667 17.0667 26.7333 18.8667 25.2667 20.3333C23.8 21.8 22.0444 22.5333 20 22.5333C17.9556 22.5333 16.2 21.8 14.7333 20.3333C13.2667 18.8667 12.5333 17.0889 12.5333 15C12.5333 12.9111 13.2667 11.1333 14.7333 9.66667C16.2 8.2 17.9556 7.46666 20 7.46666C22.0444 7.46666 23.8 8.2 25.2667 9.66667C26.7333 11.1333 27.4667 12.8889 27.4667 14.9333ZM0 20C0 16.3556 0.888889 13 2.66667 9.93333C4.44444 6.86666 6.86667 4.44444 9.93333 2.66666C13 0.888885 16.3556 -3.8147e-06 20 -3.8147e-06C23.6444 -3.8147e-06 27 0.888885 30.0667 2.66666C33.1333 4.44444 35.5556 6.86666 37.3333 9.93333C39.1111 13 40 16.3556 40 20C40 23.6444 39.1111 27 37.3333 30.0667C35.5556 33.1333 33.1333 35.5556 30.0667 37.3333C27 39.1111 23.6444 40 20 40C16.3556 40 13 39.1111 9.93333 37.3333C6.86667 35.5556 4.44444 33.1333 2.66667 30.0667C0.888889 27 0 23.6444 0 20ZM20 2.53333C16.6222 2.53333 13.5111 3.42222 10.6667 5.2C7.82222 6.97778 5.66667 9.37778 4.2 12.4C2.73333 15.4222 2.2 18.6 2.6 21.9333C3 25.2667 4.22222 28.2667 6.26667 30.9333C7.33333 29.2444 8.84445 27.9111 10.8 26.9333C13.2889 25.6889 16.3556 25.0444 20 25C23.6444 24.9556 26.7111 25.6 29.2 26.9333C31.1556 27.9111 32.6667 29.2444 33.7333 30.9333C35.7778 28.2667 37 25.2667 37.4 21.9333C37.8 18.6 37.2667 15.4222 35.8 12.4C34.3333 9.37778 32.1778 6.97778 29.3333 5.2C26.4889 3.42222 23.3778 2.53333 20 2.53333Z" fill="#DF0629"/>
      </svg>
    </div>
    <div class="ml-4">
      <div class="font-medium">TakeMe User</div>
      <div title="{{ meService.userNickname }}" class="w-40 text-sm text-gray-500 truncate ">{{ meService.userNickname }}</div>
    </div>
</div>
<hr class="border-t border-gray-400 my-2"/>