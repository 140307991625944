<div *ngIf="bookNowService.getEnabled(shop)">
    <ng-container *ngIf="onlyTimer; then timer else bookNow"></ng-container>
</div>
<ng-template #bookNow>
    <div class="flex gap-1">
        <i class="book-now-icon"></i>
        <span *ngIf="!withTitle" [innerHTML]="'homePage.instantCnfMsg' | transloco"></span>
        <span *ngIf="withTitle">{{ 'homePage.instantCnfTitle' | transloco }}</span>
    </div>
</ng-template>

<ng-template #timer>
    <div *ngIf="!hide">
        <div *ngIf="shop.instantReservationEligible && !home">
            <span [innerHTML]="'homePage.instantCnfMsg' | transloco"></span>
        </div>
        <div *ngIf="!(shop.instantReservationEligible && !home)">
            <span>{{ 'homePage.bookNow1' | transloco }}</span>
            <!-- Timer component with class, input bindings, and event binding -->
            <span class="font-medium">
                <app-text-timer
                                [timedResource]="shop"
                                (expired)="hide = true"
                                [expireTimeSupplier]="bookNowService.expiryTimer">
                </app-text-timer>
            </span>
            <span class="flex-1">{{ 'homePage.bookNow2' | transloco }}</span>
        </div>
    </div>
    <div *ngIf="hide">
        <span *ngIf="hide">{{'homePage.bookNowExpired' | transloco}}</span>
    </div>
</ng-template>
