import {Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {NgClass, NgTemplateOutlet} from "@angular/common";
import {SwiperContainer} from "swiper/element";
import {NavigationOptions, SwiperOptions} from "swiper/types";

// import function to register Swiper custom elements
import {register} from 'swiper/element/bundle';

// register Swiper custom elements
register();


@Component({
  selector: 'app-swiper-container',
  template: `
    <!-- Left arrow -->
    <swiper-button-prev [class.lg:hidden]="disableNavigation" id="swiper-button-prev-{{instanceId}}"
                        class="absolute hidden lg:flex h-[32px] inset-y-0 lg:-left-[56px] items-center justify-center mx-2 my-auto">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" transform="rotate(-180 16 16)" fill="#F3F3F3"/>
        <path
            d="M17.0678 21.48L18.271 20.2768L14.3627 16.36L18.271 12.4432L17.0678 11.24L11.9478 16.36L17.0678 21.48Z"
            fill="#161616"/>
      </svg>
    </swiper-button-prev>

    <!-- Main swiper container -->
    <swiper-container id="swiper-container-{{instanceId}}" class="overflow-auto" init="false"
                      #swiperElement>
      @if (slideData) {
        @for (data of slideData; track dataTrackBy($index, data)) {
          <swiper-slide class="" [ngClass]="disableHover ? '' : 'hover:opacity-75 hover:cursor-pointer'">
            <ng-container *ngTemplateOutlet="slideTemplate; context: { $implicit: data, index: $index }"></ng-container>
          </swiper-slide>
        }
      } @else if (placeholderTemplate) {
        @for (data of placeholderItems; track $index) {
          <swiper-slide class="rounded-lg min-h-full">
            <ng-container *ngTemplateOutlet="placeholderTemplate; context: { index: $index }"></ng-container>
          </swiper-slide>
        }
      }
    </swiper-container>

    <!-- Right arrow -->
    <swiper-button-next [class.lg:hidden]="disableNavigation" id="swiper-button-next-{{instanceId}}"
                        class="absolute hidden lg:flex h-[32px] inset-y-0 lg:-right-[56px] items-center justify-center mx-2 my-auto">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#F3F3F3"/>
        <path
            d="M14.9322 10.52L13.729 11.7232L17.6373 15.64L13.729 19.5568L14.9322 20.76L20.0522 15.64L14.9322 10.52Z"
            fill="#161616"/>
      </svg>
    </swiper-button-next>`,
  styles: [':host { position: relative; }'],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SwiperContainerComponent<T> implements OnInit {
  @Input() slideData!: T[];
  @Input() placeholderItems!: null[];
  @Input() dataTrackBy: (index: number, cardData: T) => unknown = (index) => index;
  @Input() disableNavigation: boolean = false;
  @Input() disableHover: boolean = false;
  @Input() slidesPerView: number = 3;

  @Input() slideTemplate!: TemplateRef<any>;
  @Input() placeholderTemplate!: TemplateRef<any>;

  @ViewChild('swiperElement', {static: true}) swiperElement!: ElementRef<SwiperContainer>;

  private static idCounter = 0;
  public instanceId = 0;

  constructor() {
    SwiperContainerComponent.idCounter++;
    this.instanceId = SwiperContainerComponent.idCounter;
  }

  ngOnInit() {
    const swiperEl = this.swiperElement.nativeElement;
    const navigationPayload: NavigationOptions = {
      nextEl: `swiper-button-next`,
      prevEl: `swiper-button-prev`,
    };
    Object.assign(swiperEl, <SwiperOptions>{
      slidesPerView: 3,
      spaceBetween: 16,
      simulateTouch: true,
      navigation: navigationPayload,
      breakpoints: {
        250: {
          slidesPerView: 2.1,
          centeredSlides: false,
          spaceBetween: 16,
          freeMode: false,
          navigation: {enabled: false},
        },
        640: {
          slidesPerView: this.slidesPerView - 1,
          slidesPerGroup: this.slidesPerView - 1,
          freeMode: true,
        },
        1024: {
          slidesPerView: this.slidesPerView,
          slidesPerGroup: this.slidesPerView,
        },
      },
      on: {
        init() {
          // ...
        },
        slideChange() {

        },
      },
    });
    swiperEl.initialize();
  }

}
