import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoadingBarService} from '../service/loading/loading-bar.service';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  standalone: true,
  imports: [
    NgIf
  ]
})
export class LoadingBarComponent implements OnInit {

  started = false;

  constructor(private loadingBarService: LoadingBarService,
              private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.loadingBarService.startedBehavior.subscribe(v => {
      this.started = v;
      this.cd.detectChanges();
    });
  }

}
