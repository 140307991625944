<button #baseButton (click)="onClick(baseButton)"
    [id]="dropdownName + 'btn'"
    class="focus:ring-0 focus:bg-tm-pink md:font-medium text-sm rounded-full px-3 py-1.5 text-center inline-flex items-center gap-1 focus:shadow-tm-btn hover:border-tm-red focus:border-tm-red hover:bg-tm-pink"
    [ngClass]="{'border border-gray-300  focus:border-tm-red': !hideBorder, 'bg-tm-pink text-tm-red border-0': activeState, 'bg-white': !activeState}">

  <ng-content></ng-content>

  <span *ngIf="labelState && activeState; else defaultLabel">{{ labelState }}</span>
  <ng-template #defaultLabel>
    <span [ngClass]="labelClass"> {{label ? label: ""}} </span>
    <span [ngClass]="labelSecondaryClass" *ngIf="labelSecondary && labelSecondary.length"> {{labelSecondary}} </span>
  </ng-template>

  <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="isDropDown && shopDropArrow">
    <path d="M9 11.25L5.25 7.5188H12.75L9 11.25Z" fill="black" />
  </svg>
</button>
