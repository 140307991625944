import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ratingDecimal',
  standalone: true
})
export class RatingDecimalPipe implements PipeTransform {
  transform(value: number): string {
    // Check if value is numeric and convert it to a string with one decimal place
    if (!isNaN(value)) {
      return value.toFixed(1);
    }
    return ''; // If the value is not a number, return an empty string
  }
}
