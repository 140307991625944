import { Component } from '@angular/core';
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { LIST_FILTER } from "../../../concierge-app/shop-list-view/models/shop-list-const";
import { AnalyticsEventCategory, sendEvent } from "../../../shared/analytics/analytics";
import {BaseButtonComponent} from "../../../shared/buttons/base-button/base-button.component";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-michelin-filter',
  templateUrl: './michelin-filter.component.html',
  styleUrls: ['./michelin-filter.component.scss'],
  imports: [
    BaseButtonComponent,
    TranslocoModule
  ],
  standalone: true
})
export class MichelinFilterComponent extends BaseFilterComponent {
  get labelState(): string {
    return "";
  }

  async sendAnalytics(): Promise<void> {
    sendEvent(AnalyticsEventCategory.TAGS, 'MICHELIN')
  }

  get stateChanged(): boolean {
    return this.searchService.filterForm.controls.requestMichelin.value ?? false;
  }

  toggleMichelin(): void {
    const michelinControl = this.searchService.filterForm.controls.requestMichelin;
    michelinControl.patchValue(!michelinControl.value);
  }

}
