import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { initFlowbite } from 'flowbite';
import { Subscription } from 'rxjs';
import { CurrencyConverterPipe } from '../shared/currency/currency-converter.pipe';
import { MeService } from '../shared/service/auth/me.service';
import { DeviceService } from '../shared/service/device/device.service';
import { NavigationService } from '../shared/service/nav/navigation.service';
import { SearchService } from '../shared/service/search/search.service';
import { CustomOffCanvas } from '../shared/utils/custom-ngb-offcanvas';
import { LoginModalService } from './login/login-modal.service';
import { NavBarComponent } from './nav/nav-bar.component';
import { HeaderControlService } from './service/headerControl.service';
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {SearchBarInterceptorComponent} from "../shared/widgets/search-bar-interceptor/search-bar-interceptor.component";
import {ProfileViewerComponent} from "../shared/widgets/profile-viewer/profile-viewer.component";
import {NavItemComponent} from "./nav/nav-item/nav-item.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    SearchBarInterceptorComponent,
    NgIf,
    NgTemplateOutlet,
    ProfileViewerComponent,
    NavItemComponent
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dropDownLoginForm', { static: false }) dropDownForm?: NgbDropdown;

  hideHeader = false;
  showSubs!: Subscription;
  showHeader = true;

  subscription!: Subscription;
  @ViewChild('closeButton') closeButtonRef!: ElementRef<HTMLButtonElement>;

  constructor(
    private searchService: SearchService,
    public meService: MeService,
    private loginModalService: LoginModalService,
    private offCanvas: CustomOffCanvas,
    public currencyConverter: CurrencyConverterPipe,
    public device: DeviceService,
    public navService: NavigationService,
    private headerControl: HeaderControlService,
  ) {}

  ngAfterViewInit(): void {
    initFlowbite();
  }

  ngOnInit(): void {
    this.showSubs = this.headerControl.showHeader$.subscribe((show) => {
      this.showHeader = show;
    });
    this.subscription = this.headerControl.restartHeader$.subscribe(() => {
      if (this.closeButtonRef) {
        this.closeButtonRef.nativeElement.click();
      }
    });
  }

  ngOnDestroy(): void {
    this.showSubs?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  isHomePage(): boolean {
    return location.pathname.endsWith('/app');
  }

  openNavBar(): void {
    this.offCanvas.open(
      NavBarComponent,
      { position: 'end' },
      { closeOnNavigate: true },
    );
  }

  setActiveCurrency(newCurrency: string): void {
    this.currencyConverter.setActiveCurrency(newCurrency);
    this.searchService.onCurrencyChange();
    window.location.reload();
  }

  openLoginModal(): void {
    this.loginModalService.openLoginModal();
  }
}
