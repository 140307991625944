/// <reference types="@angular/localize" />

import {ApplicationConfig, enableProdMode, ErrorHandler, importProvidersFrom, Provider} from "@angular/core";
import {environment} from "./environments/environment";
import {initSentry} from "./app/core/sentry";
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {httpInterceptorProviders} from "./app/core/http-interceptors";
import {registerLocaleData} from "@angular/common";
import {provideHttpClient, withInterceptorsFromDi, withJsonpSupport} from "@angular/common/http";
import {LIGHTBOX_CONFIG, LightboxConfig} from "ng-gallery/lightbox";
import {GALLERY_CONFIG, GalleryConfig} from "ng-gallery";
import {createErrorHandler} from "@sentry/angular-ivy";
import localeJa from '@angular/common/locales/ja';
import localeZh from '@angular/common/locales/zh';
import localeKo from '@angular/common/locales/ko';
import {provideTransloco} from "@ngneat/transloco";
import {translocoOptions} from "./app/transloco-options";
import {provideRouter} from "@angular/router";
import {appRoutes} from "./app/app-routing";
import {RecaptchaModule} from "ng-recaptcha";
import {provideAnimations} from "@angular/platform-browser/animations";


if (environment.name != 'local') {
  enableProdMode();
}

initSentry();

registerLocaleData(localeJa);
registerLocaleData(localeZh);
registerLocaleData(localeKo);

function stagingAndProdProvider(): Provider[] {
  if (environment.name === 'local') {
    return [];
  }
  return [
    {provide: ErrorHandler, useValue: createErrorHandler()},
  ];
}

const config: ApplicationConfig = {
  providers: [
    ...stagingAndProdProvider(),
    {
      provide: LIGHTBOX_CONFIG,
      useValue: <LightboxConfig>{
        keyboardShortcuts: false,
        exitAnimationTime: 1000
      }
    },
    {
      provide: GALLERY_CONFIG,
      useValue: <GalleryConfig>{
        autoHeight: true,
        imageSize: 'cover'
      }
    },
    provideRouter(appRoutes),
    httpInterceptorProviders,
    provideHttpClient(withJsonpSupport(), withInterceptorsFromDi()),
    provideTransloco(translocoOptions),
    provideAnimations(),
    importProvidersFrom(RecaptchaModule),
  ],
};

bootstrapApplication(AppComponent, config).catch((err) => console.error(err));
