import { RowResponse } from './row-response';

export const DEFAULT_PAGE_SIZE = 16

export class PaginationManager {
  pageSize: number;
  currentPage: number;
  total: number;

  constructor(data: RowResponse<unknown> | null, pageSize = DEFAULT_PAGE_SIZE) {
    this.pageSize = pageSize;
    this.currentPage = 1;
    this.total = data?.count || 0;
  }

  updateTotal(data: RowResponse<unknown> | null): void {
    this.total = data?.count || 0;
  }

  getCurrentOffset(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  getCurrentLimit(): number {
    return this.pageSize;
  }

  resetPagination(): void {
    this.currentPage = 1;
  }
}
