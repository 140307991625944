import { Component } from '@angular/core';
import { CustomModal } from 'src/app/shared/utils/custom-ngb-modal';
import { PersonalizedBookingFormComponent } from '../form/form.component';
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-personalized-booking-banner',
  templateUrl: './banner.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    TranslocoModule
  ]
})
export class PersonalizedBookingBannerComponent {
  constructor(private customModal: CustomModal) {}
  openBookingModal(): void {
    this.customModal.open(
      PersonalizedBookingFormComponent,
      {
        size: 'l',
        backdrop: true,
        scrollable: false,
        centered: true,
        windowClass: 'overflow-visible',
      },
      { closeOnNavigate: true },
    );
  }
}
