import {
  ADDRESS_STATE,
  AddressState,
} from '../../../../concierge-app/shop-list-view/models/shop-list-const';

export interface ConciergeReferral {
  referrerName: string;
  addressState: string;
  addressCity: string;
  displayDiscount?: number;
  googlePlaceId: string;
  slug: string;
  referralType: number;
  referralLang: string;

  membershipTrackingEnabled: boolean;
  mandatoryInputEnabled: boolean;
  membershipDisplayEnum: string;
}

export enum CONCIERGE_REFERRAL_TYPE {
  HOTEL = 1,
  AFFILIATE = 2, // Also referred as 'Influencers' or KOLS
}

export const DEFAULT_ADDRESS_STATE = ADDRESS_STATE[AddressState.tokyo];
