export enum SortByOption {
  RECOMMENDED = 0,
  PRICE_ASC = 1,
  PRICE_DESC = 2
}

export const SORT_BY_OPTIONS = {
  [SortByOption.RECOMMENDED]:
    {
      name: 'Recommended',
      stateLabel: 'filter.recommended',
      transLabel: 'filter.recommended'
    },

  [SortByOption.PRICE_ASC]:
    {
      name: 'Price: Low to High',
      stateLabel: 'filter.lowToHigh',
      transLabel: 'filter.lowToHighPrefix'
    },
  [SortByOption.PRICE_DESC]:
    {
      name: 'Price: High to Low',
      stateLabel: 'filter.highToLow',
      transLabel: 'filter.highToLowPrefix'
    },
}
