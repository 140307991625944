import { Component } from '@angular/core';
import { TranslocoModule } from "@ngneat/transloco";
import {
  SearchBarInterceptorComponent
} from "../../../../../widgets/search-bar-interceptor/search-bar-interceptor.component";

@Component({
  selector: 'app-header-cathay',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [
    TranslocoModule,
    SearchBarInterceptorComponent
  ],
  standalone: true
})
export class HeaderComponent {


}
