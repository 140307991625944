<button class="block relative w-full text-start lg:hidden" (click)="openCanvas()" [class.p-0]="!isHomePage"
        [class.p-2]="isHomePage">
  <i title="search" class="flex items-center pl-3 pointer-events-none" *ngIf="!isHomePage">
    <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 20 20">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
    </svg>
  </i>
  <div *ngIf="isHomePage">
    <div
      class="min-h-9 block w-full py-2 lg:py-4 pl-4 pr-4 text-sm text-gray-900 border border-gray-300 rounded-full lg:rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:border-red-700 focus:ring-rose-300/50">
      {{ 'search.placeholderText' | transloco }}
    </div>
    <div class="absolute inset-1 text-right font-small sm:font-medium rounded-lg text-sm px-4 py-2">
                  <span class="inline-block">
                      <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="44" height="28" rx="14" fill="#DF0629"/>
                          <rect width="20" height="20" transform="translate(12 4)" fill="#DF0629"/>
                          <path
                            d="M24.9167 15.6667H24.2583L24.025 15.4417C24.8417 14.4917 25.3333 13.2583 25.3333 11.9167C25.3333 8.925 22.9083 6.5 19.9167 6.5C16.925 6.5 14.5 8.925 14.5 11.9167C14.5 14.9083 16.925 17.3333 19.9167 17.3333C21.2583 17.3333 22.4917 16.8417 23.4417 16.025L23.6667 16.2583V16.9167L27.8333 21.075L29.075 19.8333L24.9167 15.6667ZM19.9167 15.6667C17.8417 15.6667 16.1667 13.9917 16.1667 11.9167C16.1667 9.84167 17.8417 8.16667 19.9167 8.16667C21.9917 8.16667 23.6667 9.84167 23.6667 11.9167C23.6667 13.9917 21.9917 15.6667 19.9167 15.6667Z"
                            fill="white"/>
                      </svg>
                  </span>
    </div>
  </div>
</button>
<div class="hidden lg:block">
  <app-search-bar [isHomePage]="isHomePage"></app-search-bar>
</div>
