<div class="w-40 sm:w-full group">
    <div class="relative  bg-white rounded-lg group-hover:opacity-75 group-hover:cursor-pointer">
        <div
            class="hidden sm:block overflow-hidden rounded-lg aspect-w-16 aspect-h-9"
        >
            <img loading="lazy" [src]="tag.imageUrl | cdn:'m'" class="object-cover w-full h-full" >
        </div>
        <div
            class="sm:hidden overflow-hidden rounded-lg aspect-w-16 aspect-h-9"
        >
            <img loading="lazy" [src]="tag.imageUrl | cdn:'s'" class="object-cover w-full h-full" >
        </div>
        <div class="py-2">
            <span class="text-sm md:text-base line-clamp-2 antialiased font-medium leading-snug tracking-normal text-blue-gray-900 mb-0.5">{{tag.tag}}</span>

            <!--Description only for English until feature is available for other languages-->
            <div class="font-sans line-clamp-1 text-xs md:text-sm antialiased font-light leading-relaxed text-gray-500 mb-1 flex gap-2" *ngIf="translocoService.getActiveLang() === 'en'">{{desc}}</div>
        </div>
    </div>
</div>
