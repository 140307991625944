import { Component, Input, OnDestroy } from '@angular/core';
import { NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { DeviceService } from '../../service/device/device.service';
import { CustomOffCanvas } from '../../utils/custom-ngb-offcanvas';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import {TranslocoModule} from "@ngneat/transloco";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-search-bar-interceptor',
  templateUrl: './search-bar-interceptor.component.html',
  standalone: true,
  imports: [
    SearchBarComponent,
    TranslocoModule,
    NgIf
  ]
})
export class SearchBarInterceptorComponent implements OnDestroy {
  @Input() isHomePage = false;
  modal!: NgbOffcanvasRef;
  constructor(
    public device: DeviceService,
    private offcanvas: CustomOffCanvas,
  ) {}

  ngOnDestroy(): void {
    if (this.modal) {
      this.modal.dismiss('navigation');
    }
  }

  openCanvas(): void {
    console.log('openCanvas');

    this.modal = this.offcanvas.open(
      SearchBarComponent,
      {
        position: 'bottom',
        panelClass:
          'bottom h-[90vh] overflow-auto rounded lg:hidden before:block before:justify-center before:top-0 before:w-20 before:h-2 before:sticky search-canvas',
        backdropClass: 'bg-white bg-opacity-80',
      },
      {
        closeOnNavigate: true,
      },
    );
    this.modal.componentInstance.isHomePage = this.isHomePage;
    this.modal.componentInstance.canvasView = true;
  }
}
