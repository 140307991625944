<app-base-button
  (submitEvent)="toggleSameDay(); onSubmit()"
  [activeState]="stateChanged"
  [control]="searchService.filterForm.controls.requestSameDay"
  [dropdownName]="'filterSameDay'"
  [isDropDown]="false"
  [label]="'filter.sameDay' | transloco"
  class="flex-shrink-0">
  <span class="left-image">
    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        [attr.fill]="stateChanged ? '#DF0629' : '#212529'"
        d="M5.33366 14.6666L6.00033 9.99992H2.66699L8.66699 1.33325H10.0003L9.33366 6.66658H13.3337L6.66699 14.6666H5.33366Z"
        fill="#DF0629" />
    </svg>

  </span>
</app-base-button>
