<div [formGroup]="form">
  <div formArrayName="checkboxes" class="space-y-2 -ml-1 overflow-y-auto h-[200px]">
    <ng-container *ngFor="let control of checkboxes.controls; let i = index">
      <div class="form-check ms-2">
        <input [formControlName]="i" type="checkbox" class="form-check-input text-lg"  (click)="onChangeClickEvent(i)"/>
        <label class="form-check-label">
          {{ tags[i].tag }}
        </label>
      </div>
    </ng-container>
  </div>
</div>
