<ng-container>
  <div class="summary personal-info">
    <div class="card-body">
      <div class="font-medium text-xl pb-1" [ngClass]="referrerMandatoryInputEnabled ? 'required-field':''">
        {{ 'promotions.promotions'  | transloco}}
      </div>

      <span>
        <img title="cathay.png" class="h-7 mb-2" src="./../../../../assets/images/cathay.png" alt="Cathay Pacific Logo"/>
      </span>

      <div class="referrer-membership">
        <!-- Referrer Membership content -->
        <ng-container *ngIf="projectedContent">
          <ng-container *ngTemplateOutlet="projectedContent"></ng-container>

          <span class="agreement-box ps-0 bg-transparent" [ngClass]="{'error underline': referrerMandatoryInputEnabled || this.form.controls.userReferrerMembershipNumber.value!=''}">
            <div>
              <span>{{ 'booking.readMemberTnC' | transloco }} </span>
              <span><a [href]="navService.getUrlWithReferrer('app/legal?type=cathayPolicy')" class="underline" target="_blank">{{ 'booking.tnc' | transloco }}</a></span>
            </div>
            <input 
              title="agreementCheckbox"
              type="checkbox"
              [formControl]="form.controls.isMemberTncAgreed"
              (change)="onAgreeClick($event)"
            />          
          </span>

          <!-- Error message for the input -->
          <div class="error-message" *ngIf="form.controls.userReferrerMembershipNumber.invalid && (form.controls.userReferrerMembershipNumber.dirty || form.controls.userReferrerMembershipNumber.touched)">
            <ng-container>
              <div class="text-gray-600">{{ 'promotions.cathaymembershipNumberIsRequiredTitle' | transloco }}</div>
              <div>{{ 'promotions.cathaymembershipNumberIsRequired' | transloco }}</div>
            </ng-container>
          </div>

          <!-- error msg for the check box -->
          <div class="error-message" *ngIf="form.errors?.['fillTogther'] || form.controls.userReferrerMembershipNumber.invalid && this.form.controls.isMemberTncAgreed.invalid">
            <div class="text-gray-600">{{ 'promotions.cathayTncNotAgreedTitle' | transloco }}</div>
            <div>{{ 'promotions.cathayTncNotAgreed' | transloco }}</div>
          </div>

        </ng-container>
      </div>
    </div>
  </div>

</ng-container>
