import { Injectable } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasOptions, NgbOffcanvasRef, } from '@ng-bootstrap/ng-bootstrap';
import { NavDismissNgb } from "./nav-dismiss-ngb";

@Injectable({
  providedIn: 'root',
})
export class CustomOffCanvas extends NavDismissNgb<NgbOffcanvas, NgbOffcanvasRef, NgbOffcanvasOptions> {
  constructor(modalClass: NgbOffcanvas) {
    super(modalClass)
  }
}
