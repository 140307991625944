import { Component } from '@angular/core';
import { MeService } from '../../service/auth/me.service';


@Component({
  selector: 'app-profile-viewer',
  templateUrl: './profile-viewer.component.html',
  styleUrls: [],
  standalone: true
})
export class ProfileViewerComponent{
    constructor(public meService: MeService){}
}
