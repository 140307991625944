import { FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { map, Observable, pairwise, startWith } from 'rxjs';
import {
  DataEvent,
  FormAction,
  FormDataEvent,
  FormPage,
  FormStatus,
} from './form-data-event';

export enum AnalyticsEventCategory {
  TYPEAHEAD = 'search_typeahead',
  TYPEAHEAD_CLICK = 'click_typeahead_',
  PAGINATION = 'search_pagination',
  PRICE_RANGE = 'filter_price_range',
  AREA = 'filter_area',
  TAGS = 'filter_tags',
  SORT = 'filter_sort',
  FORM = 'form',
  FORM_SUCCESS = 'form_success',
  EDIT_FORM_SUCCESS = 'edit_form_sucess',
  FORM_ABANDON = 'form_abandon',
  LOGIN_FORM = 'login_form',
  LOGIN_ABANDON = 'login_abandon',
  LOGIN_SUCCESS = 'login_success',
  FORM_COMPLETE = 'form_complete',
  GENERATE_QR = 'generate_qr',
  FORM_DATE_ERROR_DATE_ONLY = 'form_date_error_date_only',
  FORM_DATE_ERROR_NUM_GUESTS = 'form_date_error_num_guests',
}

export function sendEvent(
  category: AnalyticsEventCategory,
  value: string | string[] | number,
): void;
export function sendEvent(
  category: AnalyticsEventCategory,
  value: Record<string, unknown>,
): void;

/**
 * Send event to Google Analytics
 * @overload
 * @param category
 * @param value
 */
export function sendEvent(
  category: AnalyticsEventCategory,
  value: unknown,
): void {
  if (!window.gtag) {
    return;
  }

  const params = {
    category: category,
  };

  Object.assign(params, formatEventValue(value));
  window.gtag('event', category, params);
}

function isRecordLike(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

function formatEventValue(value: unknown): Record<string, string> {
  if (isRecordLike(value)) {
    const obj: Record<string, string> = {};
    Object.entries(value).forEach(([key, value]) => {
      let formValue = value;

      if (typeof value !== 'string') {
        formValue = JSON.stringify(value);
      }

      obj[key] = formValue as string;
    });

    return obj;
  }

  if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
    return { value: value.join(',') };
  }

  if (typeof value === 'number') {
    return { value: JSON.stringify(value) };
  }

  if (typeof value === 'string') {
    return { value };
  }

  throw new Error('Invalid value type');
}

export function createNewFormEvent(
  formPage: FormPage,
  formName: string,
  formAction: FormAction,
  hasValue: boolean,
): FormDataEvent {
  return {
    page: formPage,
    formName,
    action: formAction,
    time: DateTime.now().toMillis().toString(),
    value: hasValue ? FormStatus.YES : FormStatus.NO,
  };
}

export function createNewDataEvent(
  page: FormPage,
  reservationId: string,
  shopNumber: string,
  duration: number,
): DataEvent {
  return {
    page,
    resId: reservationId,
    shopNumber,
    duration,
  };
}

export const ANALYTICS_SHOP_RESERVATION_KEY =
  'analytics.shopNumberReservationId';

export function storeReservationAndShop(
  reservationId: string,
  shopNumber: string,
) {
  sessionStorage.setItem(
    ANALYTICS_SHOP_RESERVATION_KEY,
    JSON.stringify([reservationId, shopNumber]),
  );
}

export function getSessionResIdAndShopNum(): [string, string] {
  const data = sessionStorage.getItem(ANALYTICS_SHOP_RESERVATION_KEY);
  if (!data) {
    return ['', ''];
  }
  return JSON.parse(data);
}

export function createFormValueChangesObs(
  form: FormGroup,
): Observable<unknown> {
  return form.valueChanges.pipe(
    startWith(form.value),
    pairwise(),
    map(([oldForm, newForm]) => {
      const formControlNames = Object.keys(newForm);
      for (let formControlName of formControlNames) {
        if (oldForm[formControlName] != newForm[formControlName]) {
          return formControlName;
        }
      }
      return null;
    }),
  );
}
