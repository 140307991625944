import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { join } from 'lodash-es';
import { DateTime } from 'luxon';
import { noop } from 'rxjs';
import { GoogleMapApiService } from 'src/app/shared/googlemap/GoogleMapApiService';
import { HotelShopDistance } from 'src/app/shared/googlemap/model/HotelShopDistance';
import { BookNowService } from 'src/app/shared/service/book-now/book-now.service';
import {BannerType, ImageBannerComponent} from 'src/app/shared/widgets/image-banner/image-banner.component';
import { ImageSize } from 'src/app/shared/widgets/image-viewer/image-viewer-options';
import { DeviceService } from '../../shared/service/device/device.service';
import { NavigationService } from '../../shared/service/nav/navigation.service';
import { Shop } from '../shop-list-view/models/shop-list';
import { formatTags } from 'src/app/shared/utils/form-utils';
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {CdnPipe} from "../../shared/pipe/cdn.pipe";
import {HeartComponent} from "../../shared/widgets/heart/heart.component";
import {TranslocoModule} from "@ngneat/transloco";
import {DistanceLabelComponent} from "../../shared/distance-label/distance-label.component";
import {CurrencyConverterPipe} from "../../shared/currency/currency-converter.pipe";
import {RatingDecimalPipe} from "../../shared/utils/rating-decimal-pipe";

export const NUM_NEAREST_BLOCK_TO_RETURN = 5;

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    CdnPipe,
    ImageBannerComponent,
    NgIf,
    HeartComponent,
    TranslocoModule,
    DistanceLabelComponent,
    CurrencyConverterPipe,
    AsyncPipe,
    RatingDecimalPipe
  ]
})
export class ShopCardComponent implements OnInit {
  ImageSize = ImageSize;

  constructor(
    public device: DeviceService,
    private router: Router,
    public navService: NavigationService,
    private googleMapApiService: GoogleMapApiService,
    public bookNowService: BookNowService,
  ) {
  }

  bannerType: BannerType = BannerType.None;

  @Input() public shop!: Shop;
  @Input() public desiredDate: DateTime = DateTime.now();
  @Input() rating: number | undefined;
  @Input() mapView: boolean = false;

  hotelShopDistance!: HotelShopDistance;

  currentShopId!: string;
  formattedTags!: string;

  ngOnInit(): void {
    this.formattedTags = formatTags(this.shop);
    this.currentShopId = this.shop?.id as string;
    this.refreshBannerType(this.formattedTags);
    this.refreshHotelShopDistance();
  }

  refreshBannerType(tag: string) {
    if (tag.toLowerCase().includes('michelin')) {
      this.bannerType = BannerType.Michelin;
    } else if (this.rating && this.rating >= 4.2) {
      this.bannerType = BannerType.TopRated;
    }
  }

  openShopView() {
    let url = 'app/shop/' + this.shop.number;
    this.router.navigate([url]).then(noop);
  }

  refreshHotelShopDistance() {
    this.googleMapApiService
      .getHotelShopDistance(this.shop?.id!)
      .subscribe((result) => {
        if (result) {
          this.hotelShopDistance = result;
        }
      });
  }

  getNearestAvailableBlocks(): string[] {
    let hours: DateTime[] = [];
    let middleDate: DateTime = this.desiredDate;
    let leftDate = middleDate;
    let rightDate = middleDate;
    let blockInterval = this.shop?.availabilityDto?.blockIntervalInMinutes || 0;

    let maxMinuteMultiplier = Math.floor(
      60 / (this.shop?.availabilityDto?.blockIntervalInMinutes || 0) - 1,
    );
    let maxMinute =
      maxMinuteMultiplier *
      (this.shop?.availabilityDto?.blockIntervalInMinutes || 0);

    if (this.isSlotAvailable(middleDate)) {
      hours.push(middleDate);
    }

    if (!leftDate || !rightDate || !blockInterval) {
      return [];
    }

    // while not limit reach or didn't browse all slots
    while (
      hours.length < NUM_NEAREST_BLOCK_TO_RETURN &&
      (leftDate.hour !== 0 ||
        leftDate.minute !== 0 ||
        rightDate.hour !== 23 ||
        rightDate.minute !== maxMinute)
      ) {
      if (leftDate.hour !== 0 || leftDate.minute !== 0) {
        leftDate = leftDate.minus({minutes: blockInterval});
        if (this.isSlotAvailable(leftDate)) {
          hours.push(leftDate);
        }
      }
      if (rightDate.hour !== 23 || rightDate.minute !== maxMinute) {
        rightDate = rightDate.plus({minutes: blockInterval});
        if (this.isSlotAvailable(rightDate)) {
          hours.push(rightDate);
        }
      }
    }
    return hours
      .sort((a, b) => a.toMillis() - b.toMillis())
      .map((dateTime) => dateTime.toFormat('HH:mm'));
  }

  isSlotAvailable(dateTime: DateTime) {
    return this.shop?.availabilityDto?.availableReservations.some(
      (HHmm) => HHmm === dateTime.toFormat('HH:mm'),
    );
  }

}
