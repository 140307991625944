<div class="result-container">
    <img src="../../../assets/images/TakeMeLogo.png" alt="TakeMe" [style]="'width : 80px'">

    <ng-container>
        <ng-container *ngTemplateOutlet="resultPage"></ng-container>
    </ng-container>

</div>


<ng-template #resultPage>
    <div class="result-title success">   
        <img src="../../../assets/images/checked.png" alt="confirmed"/>
        <span>{{ 'resultPage.successTitle' | transloco }}</span>
    </div>
    <h6>
        <div class="alert alert-warning mt-3" role="alert">
          {{ 'redirect.reservationDetailsMessage' | transloco }}
        </div>
    </h6>

    <div class="btn tm-btn btn-danger"><a href="">{{ 'common.toHome' | transloco }}</a></div>
    <div class=""><a href="app/dashboard/orders">{{ 'redirect.loginToViewBooking' | transloco }}</a></div>

</ng-template>
