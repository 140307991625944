import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../service/toast/toast.service';
import {NgbToast} from "@ng-bootstrap/ng-bootstrap";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [
    NgbToast,
    NgForOf
  ]
})
export class ToastComponent implements OnInit {
  constructor(public toastService: ToastService) {}

  ngOnInit(): void {}
}
