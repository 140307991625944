import { Component, Input, OnInit } from '@angular/core';
import {NgClass, NgSwitch, NgSwitchCase} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";



export enum BannerType {
    None = 0,
    Michelin = 1,
    TopRated = 2
}

@Component({
  selector: 'app-image-banner',
  templateUrl: './image-banner.component.html',
  imports: [
    NgClass,
    NgSwitch,
    NgSwitchCase,
    TranslocoModule
  ],
  standalone: true
})
export class ImageBannerComponent implements OnInit {
    @Input() bannerType!: any;
    none = BannerType.None;
    michelin = BannerType.Michelin;
    topRated = BannerType.TopRated;

    ngOnInit (): void
    {
    }
}
