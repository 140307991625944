import { Component } from '@angular/core';
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import {
  FILTER_PRICE_CURRENCY_LIMIT,
  FILTER_PRICE_CURRENCY_STEP,
  SupportedCurrency
} from "../../../shared/currency/currency-const";
import {NgxSliderModule, Options} from "ngx-slider-v2";
import { AnalyticsEventCategory, sendEvent } from "../../../shared/analytics/analytics";
import {DropdownButtonComponent} from "../../../shared/buttons/dropdown-button/dropdown-button.component";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.scss'],
  imports: [
    DropdownButtonComponent,
    NgxSliderModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
  standalone: true
})
export class PriceRangeComponent extends BaseFilterComponent {


  sliderOptions: Options = {
    floor: 0,
    ceil: FILTER_PRICE_CURRENCY_LIMIT[this.currencyConverter.getActiveCurrency() as SupportedCurrency] ?? FILTER_PRICE_CURRENCY_LIMIT.JPY,
    step: FILTER_PRICE_CURRENCY_STEP[this.currencyConverter.getActiveCurrency() as SupportedCurrency] ?? FILTER_PRICE_CURRENCY_LIMIT.JPY,
    getSelectionBarColor: (): string => {
      return '#DF0629'
    },
    translate: (value: number): string => {
      return this.rangeFilterTranslate(value, this.translocoService.getActiveLang(), this.currencyConverter.getActiveCurrency() as SupportedCurrency);
    },
  }

  rangeFilterTranslate(value: number, locale: string = 'ja-JP', currentCode = 'JPY'): string {
    return value.toLocaleString(locale, {
      style: 'currency',
      currency: currentCode,
      minimumFractionDigits: 0
    })
  }

  get labelState(): string {
    let upperPrice = this.searchService.filterForm.controls.priceRange?.value?.[1];
    const currentCurrency = this.currencyConverter.getActiveCurrency()

    if (!upperPrice) {
      upperPrice = this.currencyConverter.getCurrencyLimit(currentCurrency as SupportedCurrency);
    }

    const formatter = new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 0,
      currency: currentCurrency,
    })

    let formattedPrice = formatter.format(Math.round(upperPrice));

    return ` ${currentCurrency}${formattedPrice}`
  }

  async formatSubmitFormPriceRangeToYen(): Promise<number[]> {
    const priceRange = this.searchService.filterForm.controls.priceRange.value;
    return await this.currencyConverter.getYenEquivalentArray(this.currencyConverter.getActiveCurrency(), priceRange as number[]).then(yenValue => {
      yenValue[0] = Math.round(yenValue[0]) ?? 0;
      yenValue[1] = Math.round(yenValue[1]) ?? FILTER_PRICE_CURRENCY_LIMIT.JPY;
      return yenValue;
    })
  }

  async sendAnalytics(): Promise<void> {
    const priceRange = this.searchService.filterForm.controls.priceRange?.value;
    const priceRangeYen = await this.formatSubmitFormPriceRangeToYen();


    const priceRangeChangedEvent = {
      startPrice: priceRange?.[0],
      endPrice: priceRange?.[1],
      currency: this.currencyConverter.getActiveCurrency(),
      startPriceYen: priceRangeYen?.[0],
      endPriceYen: priceRangeYen?.[1]
    }

    if (priceRange) {
      sendEvent(AnalyticsEventCategory.PRICE_RANGE, priceRangeChangedEvent)
    }
  }

  get stateChanged(): boolean {
    const currencyDefaultLimit = FILTER_PRICE_CURRENCY_LIMIT[this.currencyConverter.getActiveCurrency() as SupportedCurrency] ?? FILTER_PRICE_CURRENCY_LIMIT.JPY;
    return (this.searchService.filterForm.controls.priceRange?.value?.[0] !== 0
      || this.searchService.filterForm.controls.priceRange?.value?.[1] !== currencyDefaultLimit)

  }

}
