<div class="modal-header">
  <h3 class="modal-title" id="modal-title">{{options.title}}</h3>
  <span class="text-end btn btn-xl close-btn" (click)="activeModal.close(); sendFormAbandonEvent()">&times;</span>
</div>
<div class="modal-body">
  <div class="d-flex">
    <p><app-note [note]="options.body"></app-note> </p>
  </div>
  <form [formGroup]="createRequestForm" (ngSubmit)="createLoginRequestSubmit()" class="mb-2">
    <div>
      <label class="control-label mb-1" for="email">{{"common.email" | transloco}}
      </label>

      <input type="text" class="form-control" formControlName="email" id="email" (focusin)="onFormSelect('email')"/>
    </div>

    <span class="text-danger" *ngIf="createLoginRequestEmailInvalid() && createRequestForm.controls?.['email']?.value">
      {{'header.loginModal.emailInvalid' | transloco}}
    </span>

    <span  class="text-danger" *ngIf="!createRequestForm.controls?.['email']?.value && createRequestForm.touched">
      {{'header.loginModal.noEmail' | transloco}}
    </span>

    <div class="action-btns" *ngIf="!createRequestForm.disabled">
        <button class="btn btn-danger" type="submit" [style]="'width:100%'">
          {{ "common.submit" | transloco }}
        </button>
    </div>

  </form>

  <form [formGroup]="loginRequestForm" *ngIf="createRequestForm.disabled" (ngSubmit)="loginSubmit()">

    <span>
      <div><label class="control-label mb-1" for="email">{{"common.code" | transloco}}
      </label></div>

      <div class="text-danger mb-2" *ngIf="hasTokenError">
        {{'header.loginModal.tokenInvalid' | transloco}}
      </div>

      <div>
        <ngx-otp-input
          [config]="otpInputConfig"
          (fill)="handleFillEvent($event)"
        >
        </ngx-otp-input>
      </div>
      <div class="action-btns">
        <button class="btn btn-danger" type="submit" [style]="'width:100%'">
          {{ "common.submit" | transloco }}
        </button>
      </div>
      <div class="mt-3 text-center">
        <div *ngIf="canRequestResend">
          <span (click)="handleResendPinCode()" class="clickable-text">
            {{ 'header.loginModal.resendPINCode' | transloco }}
          </span>
        </div>
        <div *ngIf="!canRequestResend">
          <span class="text-gray">
            {{ 'header.loginModal.resendPinCodeAfter' | transloco }}
          </span>
          <span class="text-tm-red">
            <app-text-timer 
              [timedResource]="latestLoginRequestResponse" 
              [expireTimeSupplier]="supplyResendExpireTime" 
              (expired)="handleExpiration($event)"
            />
          </span>
        </div>
      </div>
    </span>
  </form>
</div>
