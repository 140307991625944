import { inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { isNil } from 'lodash-es/';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { firstValueFrom, noop } from 'rxjs';
import { InternalCookieService } from '../../cookie/internal-cookie.service';
import { NavigationService } from '../../nav/navigation.service';
import { ReferralService } from '../../referrer/referral.service';
import { AuthApiService } from '../auth-api.service';
import { SupportedLanguage } from "../../../../header/const/header-const";

@Injectable({
  providedIn: 'root',
})
export class ReferrerGuard {
  constructor(
    private authService: AuthApiService,
    private referralService: ReferralService,
    private cookieService: InternalCookieService,
    private ngxCookieService: NgxCookieService,
    private navService: NavigationService,
  ) {}

  async getValidityAndSetServiceValues(referralSlug: string): Promise<boolean> {
    const conciergeReferral = await firstValueFrom(
      this.authService.getConciergeReferral(referralSlug),
    );

    if (conciergeReferral && conciergeReferral?.slug === referralSlug) {
      this.referralService.updateReferralSubjectWithValue(conciergeReferral);
      this.cookieService.setReferrer(referralSlug);

      if (!this.cookieService.getActiveLang()) {
        this.cookieService.setActiveLang(conciergeReferral.referralLang ?? "en");
      }

      return true;
    }

    this.onInvalidReferrer().then(noop);
    return false;
  }

  private async onInvalidReferrer(): Promise<void> {
    this.cookieService.deleteReferrer();
    this.referralService.clearReferralSubject();
    await this.navService.navigate(['app/error'], {
      skipLocationChange: true,
    });
  }

  /**
   * Due to historic changes, this referrer guard is now acting as a resolver rather than a guard.
   * Use only the URL and cookie service to determine if a referral slug is present and valid but in all other cases,
   * we should consider using the ReferrerService instead. This is because even though the URL and cookie are higher
   * sources of truth, info on the referrer including address can only come from the service and so done this way to prevent inconsistencies.
   * URL > Cookie > Service is the priority order.
   * Todo: If necessary, we can check if changing it to an actual resolver is beneficial.
   */
  async canActivate(): Promise<boolean> {
    const fromCookie = this.ngxCookieService.get(
      this.cookieService.getReferrerCookieName(),
    );
    const fromUrl = new URLSearchParams(window.location.search).get(
      this.referralService.URL_REFERRER_PARAM,
    );
    const fromService = this.referralService.getConciergeReferrer()?.slug;

    let existInCookie = !isNil(fromCookie) && !isEmpty(fromCookie);
    let existInUrl = !isNil(fromUrl) && !isEmpty(fromUrl);

    const cookieAndUrlExist = existInCookie && existInUrl;
    const cookieAndUrlMatch = fromCookie === fromUrl;
    const allThreeSourcesMatch =
      fromCookie === fromUrl && fromCookie === fromService;

    // If the referral slug is public and no url exist but the value of the cookie and service are the same, return true.
    // Public Referral Slug is a special case where the referrer parameter is not required in the URL.
    if (
      !existInUrl &&
      fromCookie === this.referralService.PUBLIC_REFERRAL_SLUG &&
      fromCookie === fromService
    ) {
      return true;
    }

    // If all sources match, return true.
    if (allThreeSourcesMatch) {
      return true;
    }

    // If both, the cookie and url exist and matches but service value is not set, revalidate and set the service value.
    if (cookieAndUrlExist && cookieAndUrlMatch) {
      return await this.getValidityAndSetServiceValues(fromUrl as string);
    }

    // If both, the cookie and url exist but do not match or the url exists, revalidate and set the service value.
    // This takes URL as the highest priority source of truth.
    if ((cookieAndUrlExist && !cookieAndUrlMatch) || existInUrl) {
      return await this.getValidityAndSetServiceValues(fromUrl as string);
    }

    // If the cookie exists but the url does not, revalidate and set the service value.
    // This takes the cookie as the secondary source of truth.
    if (existInCookie && !existInUrl) {
      return await this.getValidityAndSetServiceValues(fromCookie as string);
    }

    // If none of the above conditions are met, revalidate and set the service value to the public referral slug.
    return await this.getValidityAndSetServiceValues(
      this.referralService.PUBLIC_REFERRAL_SLUG,
    );
  }
}

export const ReferrerGuardActivate = async () => {
  return inject(ReferrerGuard).canActivate();
};
