import { inject, Injectable } from '@angular/core';
import { InternalCookieService } from "../cookie/internal-cookie.service";
import { SupportedLegacyAndBCP47Map } from "../../../header/const/header-const";
import { TranslocoService } from "@ngneat/transloco";


@Injectable({
  providedIn: 'root'
})
export class LangResolver {

  constructor(private translocoService: TranslocoService,
              private cookieService: InternalCookieService) {
  }

  SupportedLanguageMap = SupportedLegacyAndBCP47Map

  async resolveLang(): Promise<boolean> {
    if (this.cookieService.getActiveLang()) {
      this.translocoService.setActiveLang(this.cookieService.getActiveLang());
      return true;
    }

    const savedLang = this.cookieService.getActiveLang();

    if (savedLang?.trim()) {
      return true;
    }

    const browserLang = navigator.languages;

    for (let i = 0; i < browserLang.length; i++) {
      const lang = browserLang[i];

      if (this.SupportedLanguageMap[lang]) {
        this.setLanguageFromMap(lang)
        return true;
      }
    }

    if (!this.cookieService.getActiveLang()) {
      this.cookieService.setActiveLang(this.translocoService.getActiveLang())
    }

    return true
  }

  private setLanguageFromMap(lang: string): void {
    const setLang = this.SupportedLanguageMap?.[lang] ?? null;
    if (setLang) {
      this.cookieService.setActiveLang(setLang);
      this.translocoService.setActiveLang(setLang);
    } else {
      this.cookieService.setActiveLang(this.translocoService.getActiveLang());
    }
  }

}

export const LanguageResolver = async () => {
  return inject(LangResolver).resolveLang();
};
