import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of, tap } from "rxjs";
import { LoginRequest } from "../../../header/models/login";
import { User } from "./models/user";
import { AuthApiService } from "./auth-api.service";
import { InternalCookieService } from '../cookie/internal-cookie.service';
import { SignupInvitation } from 'src/app/header/models/signupInvitation'
import { ConciergeReferral } from "./models/concierge-referral"
import { NavigationService } from '../nav/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class MeService {

  userSubject = new BehaviorSubject<User | null>(null);
  public isLoggedIn$ = this.userSubject.asObservable();

  constructor(private authApiService: AuthApiService,
              private cookieService: InternalCookieService,
              private navService: NavigationService) {}

  get user(): User | null {
    return this.userSubject.getValue();
  }

  get userNickname(): string {
    return this.user?.nickname ?? "User";
  }

  getTokenAndCreateLoginRequest(email: string, referralSlug: string): Observable<LoginRequest> {
    return this.authApiService.postCreateLoginRequest({email, referralSlug})
  }

  getTokenAndCreateSignupRequest(email: string, referralSlug: string): Promise<SignupInvitation> {
    return firstValueFrom(this.authApiService.postCreateSignupRequest({email, referralSlug}))
      .then(response => {
        return response;
      });
  }

  async login(params: LoginRequest): Promise<boolean> {
    let isSuccess = false;
    await firstValueFrom(this.authApiService.postLoginRequest(params, null))
      .then(response => {
        if (response) {
          this.userSubject.next(response)
          isSuccess = true;
        }
      })
    return isSuccess;
  }

  async completeSignup(params: LoginRequest): Promise<boolean> {
    let isSuccess = false;
    await firstValueFrom(this.authApiService.postLoginAfterSignupRequest(params))
      .then(response => {
        if (response) {
          this.userSubject.next(response)
          isSuccess = true;
        }
      })
    return isSuccess;
  }

  async logout(): Promise<void> {
    await firstValueFrom(this.authApiService.postLogout()).then(
      () => {
        sessionStorage.clear()
        this.cookieService.deleteCookieExceptReferrer()
        window.location.href = this.navService.getUrlWithReferrer('/');
      });
  }

  isloggedIn(): boolean {
    if (this.user) {
      return true;
    }
    return false;
  }

  getLoginUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    return this.authApiService.getUserMe()
      .pipe(tap(user => this.userSubject.next(user)));
  }

}
