<div class="search-bar">
  <!-- Mobile search view -->
  <div *ngIf="canvasView" class="px-3 pt-1">
    <div class="offcanvas-header pb-0">
      <h5 class="offcanvas-title text-lg font-semibold" id="offcanvasLabel">{{'common.search' | transloco}}</h5>
      <button type="button" class="btn-close text-reset" aria-label="Close" (click)="closeOffcanvas()"></button>
    </div>
    <div class="relative p-4 mt-1">
      <span class="lg:flex" ngbDropdown #searchDropDown="ngbDropdown">
        <div class="relative">
          <input
            #parent
            [formControl]="searchService.filterForm.controls.globalSearchArgs"
            type="search"
            id="default-search"
            [placeholder]="'search.placeholderText' | transloco"
            [ngbTypeahead]="search"
            [resultTemplate]="rt"
            [inputFormatter]="searchFormatter"
            (selectItem)="onSelect($event.item)"
            (focus)="showBackDrop = true"
            (blur)="showBackDrop = false"
            ngbDropdownToggle
            [ngClass]="isHomePage ? 'lg:py-4' : 'lg:py-2'"
            [class]="
              'block w-full py-2 pl-4 pr-4 text-sm text-gray-900 border border-gray-300 rounded-full lg:rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:border-red-700 focus:ring-rose-300/50'
            " />
          <div class="absolute top-1 right-2">
            <div

              [class]="
                isHomePage
                  ? 'text-white bg-red-700 hover:bg-red-800 font-medium rounded-full text-sm'
                  : 'text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm ms-2'
              ">
              <span (click)="closeOffcanvas();this.searchService.tempNavSearch()">
                <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="44" height="28" rx="14" fill="#DF0629" />
                  <rect width="20" height="20" transform="translate(12 4)" fill="#DF0629" />
                  <path
                    d="M24.9167 15.6667H24.2583L24.025 15.4417C24.8417 14.4917 25.3333 13.2583 25.3333 11.9167C25.3333 8.925 22.9083 6.5 19.9167 6.5C16.925 6.5 14.5 8.925 14.5 11.9167C14.5 14.9083 16.925 17.3333 19.9167 17.3333C21.2583 17.3333 22.4917 16.8417 23.4417 16.025L23.6667 16.2583V16.9167L27.8333 21.075L29.075 19.8333L24.9167 15.6667ZM19.9167 15.6667C17.8417 15.6667 16.1667 13.9917 16.1667 11.9167C16.1667 9.84167 17.8417 8.16667 19.9167 8.16667C21.9917 8.16667 23.6667 9.84167 23.6667 11.9167C23.6667 13.9917 21.9917 15.6667 19.9167 15.6667Z"
                    fill="white" />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="showSearchSuggest">
          <div [style.width.px]="device.isMobile ? '100vw' : parent.offsetWidth">
            <app-search-suggest [showTypeahead]="false" [recommendedRestaurants]="recommendedRestaurants"></app-search-suggest>
          </div>
        </div>
      </span>
    </div>

    <ng-template #rt let-r="result" let-t="term">
      <div
        class="hover:bg-gray-200"
        [ngClass]="showSearchSuggest ? 'hidden' : ''">
        <app-search-suggest [result]="r" [term]="t" [showTypeahead]="r ? true : false" [recommendedRestaurants]="recommendedRestaurants"></app-search-suggest>
      </div>
    </ng-template>
  </div>

  <!-- Web Search View -->
  <div *ngIf="!canvasView">
    <div class="relative p-2">
      <span class="hidden lg:flex" ngbDropdown #dropdown="ngbDropdown">
        <span [ngClass]="isHomePage ?  'py-2': '' " class="absolute left-3 top-2.5"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="black"/>
          </svg>
        </span>
        <input
          #parent
          [formControl]="searchService.filterForm.controls.globalSearchArgs"
          type="search"
          [placeholder]="'search.placeholderText' | transloco"
          [ngbTypeahead]="search"
          [resultTemplate]="rt"
          [inputFormatter]="searchFormatter"
          (selectItem)="onSelect($event.item)"
          (focus)="showBackDrop = true"
          (blur)="showBackDrop = false"
          ngbDropdownToggle
          [ngClass]="isHomePage ? 'lg:py-4' : 'lg:py-2'"
          [class]="
            'block w-full py-2 pl-10 pr-4 text-sm text-gray-900 border border-gray-300 rounded-full lg:rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:border-red-700 focus:ring-rose-300/50'
          " />

        <button
          type="submit"
          (click)="this.searchService.submitSearchOutsideListPage()"
          [class]="
            isHomePage
              ? 'hidden lg:block text-white absolute right-2.5 bottom-2.5 bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm px-4 py-2 flex-shrink-0'
              : 'hidden lg:block text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-md text-sm px-3 py-1 ms-2 flex-shrink-0'
          ">
          <span class="hidden lg:inline-block">{{ "common.search" | transloco }}</span>
          <span class="inline-block lg:hidden">
            <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="44" height="28" rx="14" fill="#DF0629" />
              <rect width="20" height="20" transform="translate(12 4)" fill="#DF0629" />
              <path
                d="M24.9167 15.6667H24.2583L24.025 15.4417C24.8417 14.4917 25.3333 13.2583 25.3333 11.9167C25.3333 8.925 22.9083 6.5 19.9167 6.5C16.925 6.5 14.5 8.925 14.5 11.9167C14.5 14.9083 16.925 17.3333 19.9167 17.3333C21.2583 17.3333 22.4917 16.8417 23.4417 16.025L23.6667 16.2583V16.9167L27.8333 21.075L29.075 19.8333L24.9167 15.6667ZM19.9167 15.6667C17.8417 15.6667 16.1667 13.9917 16.1667 11.9167C16.1667 9.84167 17.8417 8.16667 19.9167 8.16667C21.9917 8.16667 23.6667 9.84167 23.6667 11.9167C23.6667 13.9917 21.9917 15.6667 19.9167 15.6667Z"
                fill="white" />
            </svg>
          </span>
        </button>

        <div ngbDropdownMenu *ngIf="showSearchSuggest">
          <div [style.width]="device.isMobile ? '100vw' : '100%'">
            <app-search-suggest [showTypeahead]="false" (closeParentDropdown)="dropdown.close()" [recommendedRestaurants]="recommendedRestaurants" ></app-search-suggest>
          </div>
        </div>
      </span>
    </div>

    <ng-template #rt let-r="result" let-t="term">
      <div class="flex place-items-center hover:opacity-75 min-h-[50px]" *ngIf="!showSearchSuggest" [style.width.px]="parent.offsetWidth - 35">
        <app-search-suggest class="w-full" [result]="r" [term]="t" [showTypeahead]="r ? true : false" [recommendedRestaurants]="recommendedRestaurants"></app-search-suggest>
      </div>
    </ng-template>
  </div>
</div>
