import { Component } from '@angular/core';
import { ADDRESS_STATE } from "../../../concierge-app/shop-list-view/models/shop-list-const";
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { isEmpty, isNil } from "lodash-es";
import {DropdownButtonComponent} from "../../../shared/buttons/dropdown-button/dropdown-button.component";
import {TranslocoModule} from "@ngneat/transloco";
import {KeyValuePipe, NgClass, NgForOf} from "@angular/common";

@Component({
  selector: 'app-area-filter',
  templateUrl: './area-filter.component.html',
  styleUrls: ['./area-filter.component.scss'],
  imports: [
    DropdownButtonComponent,
    TranslocoModule,
    KeyValuePipe,
    NgClass,
    NgForOf
  ],
  standalone: true
})
export class AreaFilterComponent extends BaseFilterComponent {

  protected readonly ADDRESS_STATE = ADDRESS_STATE;

  get labelState(): string {
    const currentStateValue = this.searchService.filterForm.controls.addressState.value

    if (currentStateValue && currentStateValue.length) {
      return "filter.states." + currentStateValue.toLowerCase()
    }
    return "filter.area";
  }

  sendAnalytics(): Promise<void> {
    return Promise.resolve(undefined);
  }

  get stateChanged(): boolean {
    const currentStateValue = this.searchService.filterForm.controls.addressState.value

    if (this.searchService.defaultState) {
      return currentStateValue !== this.searchService.defaultState.default
    }

    return !isNil(currentStateValue) && !isEmpty(currentStateValue)
  }
}
