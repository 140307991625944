import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Provider} from '@angular/core';

import {ErrorInterceptor} from './error-interceptor';
import {LoadingInterceptor} from "../../shared/interceptor/loading-interceptor";


export const httpInterceptorProviders: Provider[] = [
  {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
];
