import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlCreationOptions } from "@angular/router";
import { InternalCookieService } from "../cookie/internal-cookie.service";
import { ReferralService } from "../referrer/referral.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private router: Router, private referrerService: ReferralService) {}

  private readonly listPageShouldLoadSavedState = 'navigation.shouldLoadSaveState';
  private readonly listPageSavedState = 'navigation.savedPageState';

  public async navigate(command: string[], options: NavigationExtras = {}): Promise<boolean> {
    let referralSlug = this.referrerService.getReferrerSlug()

    if (referralSlug && referralSlug.length && referralSlug !== this.referrerService.PUBLIC_REFERRAL_SLUG) {
      options.queryParams = {...options.queryParams, referrer: referralSlug}
    }

    return this.router.navigate(command, options);
  }

  public getUrl(url: string): string {
    let referralSlug = this.referrerService.getReferrerSlug()

    const navOption: UrlCreationOptions = {}

    const command = [url]
    if (referralSlug && referralSlug.length && referralSlug !== this.referrerService.PUBLIC_REFERRAL_SLUG) {
      navOption.queryParams = {'referrer': referralSlug}
    }

    return this.router.createUrlTree(command, navOption).toString();
  }

  public getUrlWithReferrer(url: string) {
    const referrer = this.referrerService.getReferrerSlug();
    if (referrer && referrer !== this.referrerService.PUBLIC_REFERRAL_SLUG) {
      return `${url}&referrer=${referrer}`
    }

    return url;
  }

  public setListPageShouldLoadSaveState(navEndPathUrl: string): void {
    const shopPageRegex = /^\/app\/shop\/\d+$/;

    if (this.navEndUrlIsShopPage(navEndPathUrl)) {
      sessionStorage.setItem(this.listPageShouldLoadSavedState, "true");
      return;
    }

    if (navEndPathUrl !== '/app/list') {
      sessionStorage.setItem(this.listPageShouldLoadSavedState, "false");
    }
  }

  public getListPageShouldLoadSaveState(): boolean | null {
    const shouldLoad = sessionStorage.getItem(this.listPageShouldLoadSavedState);
    sessionStorage.setItem(this.listPageShouldLoadSavedState, "false")

    if (shouldLoad && shouldLoad.length) {
      return shouldLoad === "true";
    }

    return false;
  }

  public saveListPageState(pageNumber: number): void {
    sessionStorage.setItem(this.listPageSavedState, pageNumber.toString());
  }

  public navEndUrlIsShopPage(navEndUrl: string): boolean {
    const shopPageRegex = /^\/app\/shop\/\w+$/;
    return shopPageRegex.test(navEndUrl);
  }

  public getListPageState(): number {
    const savedPageNumber = parseInt(sessionStorage.getItem(this.listPageSavedState) || '1');
    sessionStorage.removeItem(this.listPageSavedState);
    return savedPageNumber;
  }

}
