<!-- Note: The map card must fit the exact size of the container ,
           Thus, we need to use h-full, but we should consider using less "main" div to do that -->
<div [ngClass]="mapView ? 'h-full' : ''" *ngIf="shop" (click)="openShopView()">
  <div [ngClass]="mapView ? 'h-full' : ''" class="card p-0 hover:cursor-pointer hover:opacity-75">
    <div [ngClass]="mapView ? 'h-full' : ''" class="card-body p-0">
      <div [ngClass]="mapView ? 'h-full gap-2': 'flex-wrap' " class="flex ">
        <div [ngClass]="mapView ? 'w-[38%]': 'p-0 col-12 col-md-4 aspect-video' ">
          <div class="h-full shop-img overflow-hidden relative rounded-lg">
            @if (shop.imageArray) {
              <img [srcset]="shop.imageArray[0] | cdn:'set'" alt="" class="object-cover h-full w-full">
            }
            <!-- Banner -->
            <app-image-banner [bannerType]="bannerType"></app-image-banner>
            <!-- Save Shop as Favourite -->
            <div *ngIf="!mapView" class="md:hidden">
              <app-widget-heart [shopId]="shop.id"></app-widget-heart>
            </div>
          </div>
        </div>
        <div [ngClass]="mapView ? 'w-[62%]': 'p-0 col-12 col-md-8 '">
          <!-- Card bottom -->
          <div
            class="flex flex-col justify-around pt-2.5 h-full pl-0 md:pl-2"
          >
            <div class="flex flex-col">
              <!-- Shop Name -->
              <div
                class="text-md md:text-base line-clamp-2 antialiased font-medium leading-snug tracking-normal text-blue-gray-900 mb-0.5"
              >
                {{ shop.i18?.name ?? shop.nameEn}}
              </div>
              <!-- Save Shop as Favourite -->
              <div *ngIf="!mapView" class="hidden md:block">
                <app-widget-heart [shopId]="shop.id"></app-widget-heart>
              </div>
              <!-- Shop tags -->
              <div>
                <span
                  class="font-sans line-clamp-1 text-xs md:text-sm antialiased font-light leading-relaxed text-gray-500 mb-1"
                >
                  {{ formattedTags }}
                </span>
              </div>
            </div>
            <div class="w-min rounded badge text-tm-red bg-tm-booknow-bg" *ngIf="bookNowService.getEnabled(shop)">
              {{ 'homePage.instantCnfBadge' | transloco }}
            </div>
            <!-- Price and rating -->

            <div>
              <!-- Rating and distance -->
              <div *ngIf="hotelShopDistance && rating" class="flex items-center gap-1 md:gap-1.5 mb-1">
                <span
                  *ngIf="rating"
                  class="flex items-center gap-0.5 text-sm md:text-base font-medium text-red-600 antialiased"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    class="-mt-0.5 h-3 w-3 md:h-5 md:w-5 text-tm-red"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  {{ rating }}
                </span>
                <span *ngIf="hotelShopDistance && rating" class="text-gray-300 text-sm md:text-base">
                  •
                </span>
                <div *ngIf="hotelShopDistance" class="line-clamp-1 text-sm md:text-base antialiased font-light text-gray-500">
                  <distance-label [hotelShopDistance]="hotelShopDistance" />
                </div>
              </div>

              <!-- Price and rating -->
              <div>
                <div class="flex gap-2">
                  <div>
                    <div class="flex md:flex-row items-center gap-1 md:gap-1 " *ngIf="shop.minCoursePrice">
                      <span class="antialiased text-xs md:text-sm"> {{'shopCard.from' | transloco}} </span>
                      <span class="antialiased text-sm md:text-base"> {{ ('shopCard.fromValue' | transloco: {value: shop.minCoursePrice | currencyConverter | async}) }} </span>
                      <span *ngIf="shop.displayMinCoursePrice" class="text-gray-500 decoration-gray-400 antialiased text-sm md:text-base line-through"> {{ ('shopCard.fromValue' | transloco: {value: shop.displayMinCoursePrice | currencyConverter | async}) }} </span>
                    </div>
                  </div>
                  <span
                    *ngIf="rating && !hotelShopDistance"
                    class="flex items-center gap-0.5 leading-relaxed text-xs md:text-base font-medium antialiased text-red-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      class="-mt-0.5 h-4 w-4 md:h-5 md:w-5 text-tm-red"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {{ rating | ratingDecimal }}
                  </span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
</div>
</div>
<ng-template #t let-fill="fill">
  <i class="bi bi-star-fill">
    <i *ngIf="fill > 0" class="bi-star-fill filled" [style.width.%]="fill"></i>
  </i>
</ng-template>
