import { Component } from '@angular/core';
import { CustomModal } from '../../utils/custom-ngb-modal';
import { PersonalizedBookingFormComponent } from './form/form.component';
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-personalized-booking',
  templateUrl: './personalized-booking.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    TranslocoModule
  ]
})
export class PersonalizedBookingComponent {
  constructor(private customModal: CustomModal) {}
  openBookingModal(): void {
    this.customModal.open(
      PersonalizedBookingFormComponent,
      { size: 'l', backdrop: true, scrollable: false, centered: true, windowClass: 'overflow-visible' },
      { closeOnNavigate: true },
    );
  }
}
