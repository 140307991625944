
<video autoplay controls class="flex rounded-t-lg w-full justify-center align-center max-h-[60vh] sm:max-h-full">
    <source [src]="videoUrl" type="video/mp4">
    Your browser does not support the video tag.
</video>
<div class="modal-footer flex justify-center">
  <div *ngIf="showPlaybutton" class="w-full">
    <button (click)="playVideo()" class="btn w-full btn-primary"><i class="bi bi-play-circle-fill"></i> {{ "homePage.watchVideo" | transloco }}</button>
  </div>
  <button (click)="close()" class="btn tm-red w-full btn-outline-primary"> {{ "common.close" | transloco }}</button>
</div>
