import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle} from '@ng-bootstrap/ng-bootstrap';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { CurrencyConverterPipe } from 'src/app/shared/currency/currency-converter.pipe';
import { ConciergeUserService } from 'src/app/shared/service/auth/concierge-user.service';
import { MeService } from 'src/app/shared/service/auth/me.service';
import { InternalCookieService } from 'src/app/shared/service/cookie/internal-cookie.service';
import { DeviceService } from 'src/app/shared/service/device/device.service';
import { SearchService } from 'src/app/shared/service/search/search.service';
import { NavigationService } from '../../../shared/service/nav/navigation.service';
import { ReferralService } from '../../../shared/service/referrer/referral.service';
import { SupportedLanguage } from '../../const/header-const';
import { LoginModalService } from '../../login/login-modal.service';
import { HeaderControlService } from '../../service/headerControl.service';
import {DashboardNavComponent} from "../dashboard-nav/dashboard-nav.component";
import {ProfileViewerComponent} from "../../../shared/widgets/profile-viewer/profile-viewer.component";
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  standalone: true,
  imports: [
    DashboardNavComponent,
    ProfileViewerComponent,
    NgbDropdownMenu,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownItem,
    NgForOf,
    KeyValuePipe,
    TranslocoModule,
    NgIf,
    RouterLink
  ]
})
export class NavItemComponent implements OnInit, OnDestroy {
  supportedCurrencies: string[];
  supportedLanguagesMap: Record<string, string>;
  subscription!: Subscription;
  @ViewChild('dashboardDropDown') dashboardDropDown!: NgbDropdown;

  showAllLanguages: boolean = false;

  constructor(
    public navService: NavigationService,
    public meService: MeService,
    public translocoService: TranslocoService,
    public device: DeviceService,
    public loginModalService: LoginModalService,
    public currencyConverter: CurrencyConverterPipe,
    private searchService: SearchService,
    private cookieService: InternalCookieService,
    private conciergeUserService: ConciergeUserService,
    public headerControl: HeaderControlService,
    public referralService: ReferralService,
  ) {
    this.supportedCurrencies = this.currencyConverter.getSupportedCurrencies();
    this.supportedLanguagesMap = Object.assign(
      {},
      ...Object.entries(SupportedLanguage).map(([key, value]) => ({
        [key]: value,
      })),
    );
    this.subscription = this.headerControl.restartHeader$.subscribe(() => {
      this.dashboardDropDown?.close();
    });
  }

  ngOnInit() {
    this.showAllLanguages = true;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  openLoginModal(): void {
    this.loginModalService.openLoginModal();
  }

  setActiveCurrency(newCurrency: string): void {
    this.currencyConverter.setActiveCurrency(newCurrency);
    this.searchService.onCurrencyChange();
    window.location.reload();
  }

  keyValueSortOrder(): number {
    return 0;
  }

  get activeLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  set activeLanguage(switchLanguage: string) {
    if (this.activeLanguage === switchLanguage) {
      return;
    }

    this.cookieService.setActiveLang(switchLanguage); //This must come first, otherwise there will be a de-sync problem
    this.translocoService.setActiveLang(switchLanguage);

    if (this.meService.isloggedIn()) {
      this.conciergeUserService
        .postUpdateUserLanguagePreference(switchLanguage)
        .subscribe(() => {
          location.reload();
        });
    } else {
      location.reload();
    }
  }
}
