import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Shop } from 'src/app/concierge-app/shop-list-view/models/shop-list';
import { Course } from 'src/app/concierge-app/shop-view-booking/course-reservation/models/course';
import { PlaceDetails } from '../../googlemap/model/PlaceDetails';
import { CdnPipe } from '../../pipe/cdn.pipe';
import { CourseLangService } from '../lang/course-lang.service';

@Injectable({
  providedIn: 'root',
})
// TODO i18n SEO (but require changing the structure of the website ".../en/...")
export class SeoService {
  private structuredDataScriptId = 'structured-data-script';

  constructor(
    private title: Title,
    private meta: Meta,
    private transloco: TranslocoService,
    private courseLangService: CourseLangService,
    private cdnService: CdnPipe,
  ) {}

  updateTitle(title: string | undefined): void {
    if (!title) return;
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateDescription(description: string | undefined): void {
    if (!description) return;
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
  }

  updateUrl(canonicalUrl: string | undefined): void {
    if (!canonicalUrl) return;
    this.meta.updateTag({ property: 'og:url', content: canonicalUrl });
    if (canonicalUrl) {
      this.meta.updateTag({ rel: 'canonical', href: canonicalUrl });
    }
  }

  updateImages(images: string | string[] | undefined): void {
    if (!images) return;

    // Remove existing
    let ogImageTags = this.meta.getTags("property='og:image'");
    ogImageTags.forEach((tag) => this.meta.removeTagElement(tag));

    if (Array.isArray(images)) {
      images.forEach((image) => {
        if (image !== '') {
          let imageUrl = this.cdnService.transform(image);
          this.meta.addTag({ property: 'og:image', content: imageUrl });
        }
      });
    } else {
      let imageUrl = this.cdnService.transform(images);
      this.meta.updateTag({ property: 'og:image', content: imageUrl });
    }
  }

  updateStructuredDataForRestaurant(
    shop: Shop,
    courses: Course[],
    minPrice: number,
    maxPrice: number,
    placeDetails?: PlaceDetails,
  ): void {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'Restaurant',
      name: shop.i18?.name,
      description: shop.i18?.desc,
      image: shop.imageArray?.map((relativeUrl) =>
        this.cdnService.transform(relativeUrl),
      ), // can be array
      priceRange: `¥${minPrice} - ¥${maxPrice}`,
      address: this.getCompleteAddress(shop),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: placeDetails?.rating,
        ratingCount: placeDetails?.userRatingsTotal,
        bestRating: 5, // max range
        worstRating: 0, // min range
      },
      hasMenu: {
        '@type': 'Menu',
        name: 'Menu',
        hasMenuSection: [
          {
            '@type': 'MenuSection',
            name: 'Courses',
            hasMenuItem: courses.map((course) => ({
              '@type': 'MenuItem',
              name: this.courseLangService.getCourseName(course),
              description: this.getPlainTextFromHtml(
                this.courseLangService.getCourseDescription(course),
              ),
              image: course.images.map((courseImg) =>
                this.cdnService.transform(courseImg.url),
              ),
              offers: {
                '@type': 'Offer',
                price: course.marketSalesPrice,
                priceCurrency: 'JPY',
              },
            })),
          },
        ],
      },
    };

    // Check if the script tag already exists
    let scriptTag = document.getElementById(
      this.structuredDataScriptId,
    ) as HTMLScriptElement;

    if (!scriptTag) {
      // Create a new script tag if it doesn't exist
      scriptTag = document.createElement('script');
      scriptTag.type = 'application/ld+json';
      scriptTag.id = this.structuredDataScriptId;
      document.head.appendChild(scriptTag);
    }

    // Update the content of the existing script tag
    scriptTag.text = JSON.stringify(structuredData);
  }

  getCompleteAddress(shop: Shop): string {
    const addressZip = shop.i18?.addressZip || shop.addressZip;
    const addressState = shop.i18?.addressState || shop.addressState;
    const addressCity = shop.i18?.addressCity || shop.addressCity;
    const addressTown = shop.i18?.addressTown || shop.addressTown;
    const addressLine1 = shop.i18?.addressLine1 || shop.addressLine1;
    const addressLine2 = shop.i18?.addressLine2 || shop.addressLine2;

    return `${addressZip} ${addressState} ${addressCity} ${addressTown} ${addressLine1} ${addressLine2}`.trim();
  }

  getPlainTextFromHtml(htmlString: string | undefined): string | undefined {
    if (!htmlString) return undefined;
    return htmlString
      .replace(/<\/?[^>]+(>|$)/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
  }
}
