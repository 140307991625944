// search-by-cuisine.component.ts
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {NgbHighlight, NgbOffcanvas} from '@ng-bootstrap/ng-bootstrap';
import { join } from 'lodash-es';
import { OperatorFunction, Subject } from 'rxjs';
import { HomePageService } from 'src/app/concierge-app/home-page/home-page-api.service';
import { featuredLocations } from 'src/app/concierge-app/home-page/models/featured-location';
import { FeaturedShop } from 'src/app/concierge-app/home-page/models/featured-shop';
import { Tag, TagCategory } from 'src/app/concierge-app/home-page/models/tag';
import {
  ADDRESS_STATE,
  getAddressStateFromString,
} from 'src/app/concierge-app/shop-list-view/models/shop-list-const';
import { SearchService } from 'src/app/shared/service/search/search.service';
import { environment } from 'src/environments/environment';
import { ImageSize } from '../../image-viewer/image-viewer-options';
import { InboundTypeahead } from '../model/InboundTypeahead';
import {NgForOf, NgIf} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";
import {CdnPipe} from "../../../pipe/cdn.pipe";

@Component({
  selector: 'app-search-suggest',
  templateUrl: './search-suggest.component.html',
  standalone: true,
  imports: [
    NgIf,
    TranslocoModule,
    NgForOf,
    CdnPipe,
    NgbHighlight
  ]
})
export class SearchSuggestComponent implements OnDestroy {
  tags!: Tag[];
  MIN_TAG_COUNT = environment.minTagCount;
  @Input() recommendedRestaurants!: FeaturedShop[];
  @Input() search!: OperatorFunction<string, InboundTypeahead[]>;
  @Input() result: any;
  @Input() term: any;
  @Input() showTypeahead: boolean = false;
  ImageSize = ImageSize;

  @Output() closeParentDropdown = new EventEmitter<boolean>(true);
  destroyEvent$ = new Subject<void>();
  featuredLocations = featuredLocations;

  constructor(
    private homePageService: HomePageService,
    public searchService: SearchService,
    private router: Router,
    public offcanvas: NgbOffcanvas,
  ) {
    this.searchService.getLoadedTags().then((tags) => {
      this.tags = tags.filter(
        (tag) =>
          [TagCategory.CUISINE].includes(tag.tagCategory) &&
          tag.tagCount >= this.MIN_TAG_COUNT,
      );
    });
  }

  ngOnDestroy() {
    this.destroyEvent$.next();
    this.destroyEvent$.complete();
  }

  openShop(shopNumber: string) {
    setTimeout(() => {
      window.location.href = this.router
        .createUrlTree(['/app/shop', shopNumber])
        .toString();
    }, 500);
  }

  formatTags(tags: string[]): string {
    if (!tags) {
      return '';
    }

    tags = tags.slice();

    return join(tags.splice(0, 3), ', ');
  }

  onLocationClick(addressState: string) {
    // Implement your logic here
    setTimeout(() => {
      this.searchService.filterNav(
        this.searchService.filterForm.controls.addressState,
        ADDRESS_STATE[getAddressStateFromString(addressState)].default,
      );
    }, 500);
  }
}
