import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  fromEvent,
  takeUntil,
} from 'rxjs';
import { DeviceService } from 'src/app/shared/service/device/device.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderControlService implements OnDestroy {
  lastScrollTop = 0;
  unsubscribe$ = new Subject();
  showHeader = true;
  private showHeaderSource = new BehaviorSubject<boolean>(true);
  showHeader$ = this.showHeaderSource.asObservable();
  private restartHeaderSource = new BehaviorSubject<number>(0);
  restartHeader$ = this.restartHeaderSource.asObservable();

  triggerHeaderRestart(): void {
    // Incrementing a value will change the observable's emitted value,
    // which can be used to trigger a "restart" in the header component
    this.restartHeaderSource.next(this.restartHeaderSource.value + 1);
  }
  constructor(private device: DeviceService) {
    if (device.isMobile) {
      fromEvent(window, 'scroll')
        .pipe(
          debounceTime(25), // Debounce time to limit the number of events
          takeUntil(this.unsubscribe$), // Take until unsubscribe$ emits to clean up on destroy
        )
        .subscribe((event) => {
          const currentScrollPos =
            window.scrollY || document.documentElement.scrollTop;

          if (this.lastScrollTop > currentScrollPos) {
            this.showHeaderSource.next(true);
          } else {
            this.showHeaderSource.next(false);
          }
          this.lastScrollTop = currentScrollPos <= 0 ? 0 : currentScrollPos; // For Mobile or negative scrolling
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$?.next('');
    this.unsubscribe$?.complete();
  }
}
