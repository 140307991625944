import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from 'src/app/shared/service/search/search.service';
import {FeatureLocation} from '../home-page/models/featured-location';
import {ADDRESS_STATE, getAddressStateFromString} from '../shop-list-view/models/shop-list-const';
import {CdnPipe} from "../../shared/pipe/cdn.pipe";
import {NgClass} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-featured-location',
  templateUrl: './featured-location.component.html',
  imports: [
    CdnPipe,
    NgClass,
    TranslocoModule
  ],
  standalone: true
})
export class FeaturedLocationComponent implements OnInit {

    @Input() data!: FeatureLocation;
    constructor(private searchService: SearchService){}

    cityDisplayValue: string = '';


    ngOnInit(): void {
      this.setCityNameTransloco(this.data.cityName)
    }

    onLocationClick(addressState: string) {
        // Implement your logic here
        this.searchService.filterNav(this.searchService.filterForm.controls.addressState, ADDRESS_STATE[getAddressStateFromString(addressState)].default)
    }

    //This is a temporary function to get the city name from the transloco service.
    setCityNameTransloco(cityName: string): void {
        if (!cityName || !cityName.length) {
          return;
        }

        this.cityDisplayValue = "filter.states." + cityName.toLowerCase()
    }

}
