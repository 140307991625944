import { Component, Input, OnInit } from "@angular/core";
import { HotelShopDistance } from "../googlemap/model/HotelShopDistance";
import { ConciergeReferral } from "../service/auth/models/concierge-referral";
import {NgIf} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'distance-label',
  templateUrl: './distance-label.component.html',
  imports: [
    NgIf,
    TranslocoModule
  ],
  standalone: true
})
export class DistanceLabelComponent implements OnInit {

    @Input() hotelShopDistance!: HotelShopDistance;

    // Distance label text format type:
    // FROM_HOTEL_WITHOUT_HOTEL_NAME: "x min/km from hotel"
    // FROM_HOTEL: "x min/km from hotel {hotelName}"
    // DISTANCE: "x min/km away"
    @Input() formatType: 'FROM_HOTEL_WITHOUT_HOTEL_NAME' | 'FROM_HOTEL' | 'DISTANCE' = 'DISTANCE';

    @Input() referralInfo: ConciergeReferral | null = null;
    variant!: string;

    ngOnInit(): void {
        if (this.hotelShopDistance && this.hotelShopDistance.walkTime) {
            if (this.hotelShopDistance.walkTime < (25 * 60)) { // under 25 min walk
                this.variant = "WALK_TIME"
                return;
            }
            this.variant = "DISTANCE";
        }
    }

    formatDistance() {
        let distanceInMeters = this.hotelShopDistance?.walkDistance!;
        if (distanceInMeters >= 1000) {
            // Convert meters to kilometers and round to one decimal place
            const distanceInKilometers = (distanceInMeters / 1000).toFixed(1);
            return `${distanceInKilometers} km`;
        } else {
            // Display distance in meters without decimals
            return `${Math.round(distanceInMeters)} m`;
        }
    }

    formatWalkTimeInMin() {
        let walkTimeInSec = this.hotelShopDistance?.walkDistance!;
        if (walkTimeInSec >= 60) {
            return (walkTimeInSec/60).toFixed(0);
        }
        return 1
    }

}
