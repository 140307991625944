import { Component } from '@angular/core';
import {TranslocoModule} from "@ngneat/transloco";
import {NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'app-result-redirect',
  templateUrl: './result-redirect.component.html',
  styleUrls: ['./result-redirect.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    NgTemplateOutlet
  ]
})
export class ResultRedirectComponent{
}
