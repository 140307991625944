import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../shared/service/nav/navigation.service';
import { ErrorType } from './models/error-type';
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";
import {HeaderComponent} from "../../header/header.component";
import {FooterComponent} from "../../footer/footer.component";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    HeaderComponent,
    NgIf,
    FooterComponent,
    TranslocoModule,
    HeaderComponent,
    FooterComponent
  ]
})
export class ErrorPageComponent implements OnInit {
  errorType = ErrorType.Blocked;
  ErrorType = ErrorType;
  constructor(
    private route: ActivatedRoute,
    private navService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const errorTypeParam = params['type'];

      // Check if the provided type matches any value in the ErrorType enum
      if (
        errorTypeParam &&
        Object.values(ErrorType).includes(Number(errorTypeParam))
      ) {
        this.errorType = Number(errorTypeParam) as ErrorType;
      }
    });
  }

  goToHomepage(): void {
    this.navService.navigate(['app/']);
  }
}
