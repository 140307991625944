import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {TranslocoModule, TranslocoService} from "@ngneat/transloco";
import { filter, firstValueFrom, interval, noop, Subject, takeUntil } from "rxjs";
import { AuthApiService } from "../../shared/service/auth/auth-api.service";
import { MeService } from "../../shared/service/auth/me.service";

import { ToastService } from "../../shared/service/toast/toast.service";
import { ToastType } from "../../shared/widgets/toast/models/toast-type";
import { LoginRequest } from "../models/login";
import { LoginModalOptions } from "./login-modal-options";

import { isNil } from "lodash-es/";
import {NgxOtpInputConfig, NgxOtpInputModule} from "ngx-otp-input";
import { SignupInvitation } from '../models/signupInvitation';
import {
  AnalyticsEventCategory,
  createFormValueChangesObs,
  getSessionResIdAndShopNum,
  sendEvent
} from "../../shared/analytics/analytics";
import { FormAction, FormDataEvent, FormPage } from "../../shared/analytics/form-data-event";
import { InternalCookieService } from "../../shared/service/cookie/internal-cookie.service";
import { SupportedLegacyAndBCP47Map } from "../const/header-const";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { ReferralService } from "../../shared/service/referrer/referral.service";
import {NoteComponent} from "../../shared/widgets/note/note.component";
import {NgIf} from "@angular/common";
import {TextTimerComponent} from "../../shared/timer/text-timer.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    NoteComponent,
    ReactiveFormsModule,
    TranslocoModule,
    NgIf,
    NgxOtpInputModule,
    TextTimerComponent
  ],
  standalone: true
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() email = '';
  hasValidMail! : boolean;

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    numericInputMode: true,
    classList: {
      inputBox: "pin-box-class",
      input: "pin-input-class",
      inputFilled: "pin-filled-class",
      inputDisabled: "pin-disable-class",
      inputSuccess: "pin-success-class",
      inputError: "pin-error-class"
    }
  };

  createRequestForm!: FormGroup;
  loginRequestForm!: FormGroup;
  formEvents: FormDataEvent[] = []

  canRequestResend: boolean = true;
  hasTokenError = false;
  isNewUser = false;

  latestLoginRequestResponse!: LoginRequest | SignupInvitation

  formDuration: number = 0;
  destroyEvent$: Subject<void> = new Subject()

  SupportedLanguageMap = SupportedLegacyAndBCP47Map

  constructor(private fb: FormBuilder,
              public meService: MeService,
              private toastService: ToastService,
              private authService: AuthApiService,
              public activeModal: NgbActiveModal,
              private translocoService: TranslocoService,
              private referrerService: ReferralService,
              private cookieService: InternalCookieService,
              @Inject('options') public options: LoginModalOptions) {
  }

  ngOnInit(): void {
    this.createRequestForm = this.fb.nonNullable.group({
      email: [this.email, [Validators.required, Validators.email]],
      referralSlug: [this.referrerService.getReferrerSlug() ?? '', Validators.required]
    }, {updateOn: "blur"});

    this.loginRequestForm = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      oneTimeCode: ['', Validators.required],
      token: ['', Validators.required]
    })

    this.initFormEvents();
  }

  ngOnDestroy() {
    this.destroyEvent$.next()
    this.destroyEvent$.complete()
  }

  async loginSubmit(): Promise<void> {
    let reservationAndShop = getSessionResIdAndShopNum();
    if (!this.isNewUser) {
      await this.meService.login(this.loginRequestForm.value as LoginRequest)
        .then(() => {
          sendEvent(AnalyticsEventCategory.LOGIN_SUCCESS, reservationAndShop?.[0]);
          this.activeModal?.close(true)
        }).catch((error) => {
          this.hasTokenError = true;
        })
    } else {
      const formValues = this.loginRequestForm.value as LoginRequest
      formValues.language = this.translocoService.getActiveLang();

      await this.meService.completeSignup(formValues)
        .then(() => {
          sendEvent(AnalyticsEventCategory.LOGIN_SUCCESS, reservationAndShop?.[0]);
          this.activeModal?.close(true)
        }).catch((error) => {
          this.hasTokenError = true;
        });
    }

    if (this.meService.user && this.meService.user.languageCd) {
      const setLang = this.SupportedLanguageMap?.[this.meService.user.languageCd] ?? null;

      if (setLang === this.cookieService.getActiveLang()) {
        return;
      }

      this.translocoService.setActiveLang(setLang)
      this.cookieService.setActiveLang(setLang)

      if (this.options.refreshOnLogin) {
        window.location.reload();
      }
    }

  }

  handleFillEvent(value: string): void {
    if (value.length === 6) {
      this.loginRequestForm.patchValue({oneTimeCode: value});
    }
  }

  initFormEvents(): void {
    interval(1000).pipe(takeUntil(this.destroyEvent$)).subscribe(() => {
      this.formDuration++;
    });

    createFormValueChangesObs(this.createRequestForm).pipe(takeUntil(this.destroyEvent$), filter(value => !isNil(value))).subscribe((value) => {
      sendEvent(AnalyticsEventCategory.LOGIN_FORM, FormAction.INPUT);
    });

    createFormValueChangesObs(this.loginRequestForm).pipe(takeUntil(this.destroyEvent$), filter(value => !isNil(value))).subscribe((value) => {
      sendEvent(AnalyticsEventCategory.LOGIN_FORM, value as string);
    });

  }

  onFormSelect(formName: string): void {
    sendEvent(AnalyticsEventCategory.LOGIN_FORM, formName);
  }

  createLoginRequestEmailInvalid(): boolean {
    return this.createRequestForm.controls['email'].invalid && (this.createRequestForm.controls['email'].dirty || this.createRequestForm.controls['email'].touched);
  }

  async createLoginRequestSubmit(): Promise<void> {
    const email = this.createRequestForm.controls['email'].value ?? null;
    const emailIsValid = this.createRequestForm.controls['email'].valid || this.hasValidMail;

    if (!emailIsValid) {
      return;
    }
    this.hasValidMail = true;

    const referralSlug = this.createRequestForm.controls['referralSlug'].value ?? null;
    if (!referralSlug) {
      this.toastService.show('', "To sign up or log in, please use the exact link provided by your hotel and try again.", ToastType.error)
      return;
    }
    let isExistingEmail = null

    await firstValueFrom(this.authService.getUserEmailExists(email))
      .then(response => isExistingEmail = response)
      .catch(noop)

    if (isExistingEmail) {
      firstValueFrom(this.meService.getTokenAndCreateLoginRequest(email, referralSlug)).then(loginRequest => {
        this.loginRequestForm.patchValue({token: loginRequest.token});
        this.createRequestForm.disable();
        this.updateCanResendEmailRequest(loginRequest);
      }, (error: HttpErrorResponse) => {
        if (error && error.status && error.status === HttpStatusCode.Conflict) {
          this.toastService.show('', this.translocoService.translate("header.loginModal.differentOriginMessage"), ToastType.warning);
        }
      })
    } else {
      this.meService.getTokenAndCreateSignupRequest(email, referralSlug).then(signupInvitation => { //Todo need to refactor
        this.loginRequestForm.patchValue({token: signupInvitation.token})
        this.createRequestForm.disable();
        this.isNewUser = true
        this.updateCanResendEmailRequest(signupInvitation);
      })
    }
  }

  sendFormAbandonEvent(): void {
    let reservationAndShop = getSessionResIdAndShopNum();

    sendEvent(AnalyticsEventCategory.LOGIN_ABANDON, reservationAndShop?.[0]);
  }

  // - - - - Resend email delay

  handleExpiration(expired: boolean) {
    if (expired) {
      this.canRequestResend = true;
    }
  }

  async handleResendPinCode() {
    await this.createLoginRequestSubmit()
  }

  supplyResendExpireTime(request : LoginRequest | SignupInvitation) {
    return new Date(request.oneTimeCodeResendExpireTime!)
  }

  updateCanResendEmailRequest(request : LoginRequest | SignupInvitation) {
    const expireTime = new Date(request.oneTimeCodeResendExpireTime!);
    this.latestLoginRequestResponse = request;
    this.canRequestResend = this.canResendEmailRequest(expireTime)
  }

  canResendEmailRequest(dateWhenCanResendAgain: Date) {
    const now = new Date();
    return dateWhenCanResendAgain < now;
  }
}
