import { Component, EventEmitter, Output } from '@angular/core';
import { SearchService } from "../../../shared/service/search/search.service";
import { CurrencyConverterPipe } from "../../../shared/currency/currency-converter.pipe";
import { TranslocoService } from "@ngneat/transloco";
import { FilterService } from "../../service/filter.service";

@Component({
  selector: 'app-base-filter',
  templateUrl: './base-filter.component.html',
  styleUrls: ['./base-filter.component.scss']
})
export abstract class BaseFilterComponent {

  public constructor(protected searchService: SearchService,
                     protected translocoService: TranslocoService,
                     protected filterService: FilterService,
                     protected currencyConverter: CurrencyConverterPipe) {
  }

  @Output() submitEvent: EventEmitter<BaseFilterComponent> = new EventEmitter<BaseFilterComponent>();

  abstract get stateChanged(): boolean;

  abstract sendAnalytics(): Promise<void>;

  abstract get labelState(): string;

  async onSubmit(): Promise<void> {
    this.submitEvent.emit(this);
  }

  get clearAllEligible(): boolean {
    return true;
  }

}
