<div class="sticky top-0 header w-full bg-white z-40 transition-top"
  [ngClass]="showHeader ? 
  'top-0'
  : 'top-[-100%]'">
  <div class="flex justify-center pt-2 bg-white" [ngClass]="{'hidden': hideHeader && this.device.isMobile}">
    <div class="flex w-[90%] md:w-[80%] gap-2 items-center justify-normal sm:justify-between py-1">
      <div class="header-item flex-shrink-0 mr-auto sm:mr-0">
        <span (click)="navService.navigate(['app/'])">
          <img
            src="{{ './assets/images/TakeMeLogo.png' }}"
            class="align-self-center pointer-event h-[40px] w-[40px]"
            alt="TakeMeLogo"
          />
        </span>
      </div>
  
      <div class="header-item w-[40px] h-[40px] sm:w-2/3 xl:w-1/2" *ngIf="!isHomePage()">
        <app-search-bar-interceptor></app-search-bar-interceptor>
      </div>
  
      <div class="hidden sm:block">
        <ng-container *ngTemplateOutlet="navWebView"></ng-container>
      </div>
  
      <div class="sm:hidden">
        <ng-container *ngTemplateOutlet="navNewMobileView"></ng-container>
      </div>
    </div>
  
  
    <ng-template #navMobileView>
      <div class="header-item">
        <button
          title="menu"
          class="btn btn-outline-secondary"
          (click)="openNavBar()"
        >
          <span><i class="bi bi-list"></i></span>
        </button>
      </div>
    </ng-template>
  
    <ng-template #navWebView>
      <div class="header-item">
        <app-nav-item></app-nav-item>
      </div>
    </ng-template>
  </div>
</div>


<ng-template #navNewMobileView>
  <!-- drawer init and toggle -->
  <div class="text-center">
    <button
        title="menu"
        class="btn btn-outline-secondary "
        type="button" data-drawer-target="drawer-right-example" data-drawer-show="drawer-right-example" data-drawer-placement="right" aria-controls="drawer-right-example"
      >
        <span><i class="bi bi-list"></i></span>
    </button>
  </div>

  <!-- drawer component -->
  <div id="drawer-right-example" class="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-80 dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-right-label">
    <app-profile-viewer></app-profile-viewer>
    <button type="button" #closeButton data-drawer-hide="drawer-right-example" aria-controls="drawer-right-example" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm mt-2 w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white" >
      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
      </svg>
      <span class="sr-only">Close menu</span>
    </button>
    <div class="overflow-y-auto">
      <app-nav-item></app-nav-item>
    </div>
  </div>

</ng-template>