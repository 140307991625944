import {Component, Input, OnInit} from '@angular/core';
import {TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {FeaturedShop} from 'src/app/concierge-app/home-page/models/featured-shop';
import {Shop} from 'src/app/concierge-app/shop-list-view/models/shop-list';
import {BookNowService} from '../../service/book-now/book-now.service';
import {NgIf} from "@angular/common";
import {TextTimerComponent} from "../../timer/text-timer.component";

@Component({
  selector: 'app-book-now',
  templateUrl: './book-now.component.html',
  styleUrls: ['book-now.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslocoModule,
    TextTimerComponent
  ]
})
export class BookNowComponent implements OnInit {
  hide = false;
  expiredMessage = '';
  @Input() onlyTimer = false;
  @Input() withTitle = false;
  @Input() shop!: Shop | FeaturedShop;
  @Input() home = false;

  constructor(
    private transloco: TranslocoService,
    public bookNowService: BookNowService
  ) {
  }

  ngOnInit(): void {
    this.expiredMessage = this.transloco.translate('homePage.bookNowExpired');
  }
}
