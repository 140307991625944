import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FavService} from '../../service/fav/fav.service';
import {MeService} from '../../service/auth/me.service';
import {Subscription} from 'rxjs';
import {LoginModalService} from 'src/app/header/login/login-modal.service';
import {NgClass} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-widget-heart',
  templateUrl: './heart.component.html',
  styleUrls: ['heart.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    TranslocoModule
  ]
})
export class HeartComponent implements OnInit, OnDestroy {
  @Input() desc = false;
  @Input() shopId: string | undefined = '';

  isHeartFilled = false;
  subscription!: Subscription;

  constructor(
    private favService: FavService,
    public meService: MeService,
    private loginModal: LoginModalService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.favService.favShopIdUpdate.subscribe(
      (favShops) => {
        this.isHeartFilled = false;
        favShops.forEach((shop) => {
          if (this.shopId === shop.id) {
            this.isHeartFilled = true;
          }
        });
      },
    );
    if (this.meService.isloggedIn()) {
      this.isHeartFilled = this.favService.isFav(this.shopId || '');
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  toggleHeart(event: MouseEvent): void {
    const updateFn = () => {
      this.favService.updateFav({
        action: this.isHeartFilled ? 'delete' : 'insert',
        shopIds: [this.shopId || ''],
      });
    };

    event.stopPropagation(); // Stop the event from propagating to parent elements
    if (this.meService.isloggedIn()) {
      updateFn();
    } else {
      this.loginModal.setPostLoginAction(updateFn);
      this.loginModal.openLoginModal();
    }
  }
}
