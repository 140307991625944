import { HeaderComponent } from "../member-home/cathaypacific/header/header.component";
import { FooterComponent } from "../member-home/cathaypacific/footer/footer.component";
import { PromotionComponent } from "../member-home/cathaypacific/promotion/promotion.component";

export enum HOME_PAGE_COMPONENT {
  HEADER = "header",
  RECOMMENED = "recommended",
  LOCATION = "location",
  EXPERIENCES = "experiences",
  INSTANT = "instant",
  TAG_CATEGORIES = "tagCategories",
  MICHELIN = "michelin",
  FOOTER = "footer",
}

export enum BOOKING_DETAILS_COMPONENT {
  PROMOTION = "promotion",
}

export const CUSTOM_MEMBERSHIP_COMPONENTS: Record<string, Record<string, any>> = {
  "cathaypacific": {
    [HOME_PAGE_COMPONENT.HEADER]: HeaderComponent,
    [HOME_PAGE_COMPONENT.FOOTER]: FooterComponent,
    [BOOKING_DETAILS_COMPONENT.PROMOTION]: PromotionComponent,
  }
}
