import { PredefinedPaymentResponse } from '../../payment/models/predefined-payment-response';

export interface SavedReservation {
  [key: string]: unknown;
  createTime: number;
  updateTime: number;
  id: string;
  shopId: string;
  shopNumber: string;
  nameEn: string;
  destPlatformShopId?: string;
  reservationStatus: number;
  paymentStatus: number;
  reservationStartTime: number;
  reservationConfirmTime: number;
  numberOfGuests: number;
  guestFirstName: string;
  guestLastName: string;
  guestEmail: string;
  guestPhoneNumber: string;
  remark: string;
  remarkStatus: number;
  originPlatformReservationNumber: string;
  originPlatform: string;
  destPlatform: string;
  destPlatformReservationNumber?: string;
  totalMarketSalesPrice: number;
  courses: SavedCourse[];
  images: string[];
  courseDesc: string;
  cancellationReason?: string;
  paymentDto?: PredefinedPaymentResponse;
}

export interface SavedCourse {
  [key: string]: string | number | boolean | undefined;
  courseId: string;
  courseName: string;
  courseAmountRequested?: number;
  isParentCourse: boolean;
}

export function isSavedReservationInterface(
  object: unknown,
): object is SavedReservation {
  const attemptConvert = object as SavedReservation;
  return (
    attemptConvert.reservationStatus !== undefined &&
    attemptConvert.reservationStartTime !== undefined &&
    attemptConvert.totalMarketSalesPrice !== undefined &&
    attemptConvert.courses !== undefined
  );
}
