export interface ImageViewOptions {
  size?: ImageSize;
  singleImage?: boolean;
}

export enum ImageSize {
  m = 'm',
  s = 'sm',
  std = 'std',
  l = 'l',
  xl = 'xl',
}
