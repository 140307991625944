import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/browser';


export function initSentry(): void {
  Sentry.init({
    dsn: 'https://4cfdbed475eb4d629917b0279b079db0@o34807.ingest.sentry.io/4505469730553856',
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler, and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [
      new Sentry.Integrations.TryCatch({
        eventTarget: false,
        requestAnimationFrame: false,
        setInterval: false,
        setTimeout: false,
        XMLHttpRequest: false
      }),
    ],
    environment: environment.name,
    release: environment.release && `app-concierge-web@${environment.release}`
  });
}
