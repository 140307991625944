import { Injectable } from '@angular/core';
import { ReferralService } from '../referrer/referral.service';
import { CookieService } from "ngx-cookie-service";
import { CUSTOM_MEMBERSHIP_COMPONENTS } from "./common/member-constants";
import { InternalCookieService } from "../cookie/internal-cookie.service";

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  private membershipDisplayEnum: string | null = '';

  constructor(private referrerService: ReferralService,
              private cookieService: CookieService,
              private internalCookieService: InternalCookieService) {
    this.membershipDisplayEnum = this.getReferrerEnum();
  }

  private getReferrerEnum(): string | null {
    const referrer = this.referrerService.getConciergeReferrer();

    if (
      !referrer ||
      !referrer.membershipTrackingEnabled ||
      !referrer.membershipDisplayEnum
    ) {
      return null;
    }

    return referrer.membershipDisplayEnum;
  }

  public isValid(): boolean {
    return (
      !!this.membershipDisplayEnum && this.membershipDisplayEnum.length > 0
    );
  }

  public getReferrerMembershipBookingNumber(): string | null {
    return this.cookieService.get(this.internalCookieService.MEMBERSHIP_BOOKING_COOKIE_PARAM);
  }

  public referrerHasCustomComponent(enumValue: string): boolean {
    if (!this.getReferrerEnum()) {
      return false;
    }

    const referrer = this.referrerService.getReferrerSlug();

    const referrerHasCustom = CUSTOM_MEMBERSHIP_COMPONENTS[referrer];

    if (!referrerHasCustom) {
      return false;
    }

    return enumValue in referrerHasCustom;
  }

  public getReferrerCustomComponent(enumValue: string): any | null {
    const referrer = this.referrerService.getReferrerSlug();
    const referrerHasCustom = CUSTOM_MEMBERSHIP_COMPONENTS[referrer];

    if (!referrerHasCustom) {
      return null;
    }

    return referrerHasCustom[enumValue] || null;
  }
}
