import {
  AfterViewInit,
  Component,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import { membershipAgreeValidator } from 'src/app/shared/directives/fill-together.directive';
import { GuestForm } from '../../../../../../concierge-app/shop-view-booking/booking-details/models/guest-form';
import { FormControlOf } from '../../../../../utils/form-utils';
import { NavigationService } from '../../../../nav/navigation.service';
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";


@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrl: './promotion.component.scss',
  standalone: true,
  imports: [
    NgClass,
    TranslocoModule,
    NgTemplateOutlet,
    NgIf,
    ReactiveFormsModule
  ]
})
export class PromotionComponent implements AfterViewInit {
  @Input() referrerMandatoryInputEnabled: boolean = false;
  @Input() public projectedContent: TemplateRef<any> | undefined;
  @Input() @Output() public form!: FormGroup<FormControlOf<GuestForm>>;

  constructor(public navService: NavigationService) {}

  onAgreeClick($event: unknown): void {
    // sendEvent(AnalyticsEventCategory.FORM, FormAction.INPUT);
  }

  ngAfterViewInit(): void {
    this.form.addValidators(
      membershipAgreeValidator(
        'userReferrerMembershipNumber',
        'isMemberTncAgreed',
      ),
    );
  }
}
