<!-- We need to use a href because  router link doesnt work if we open from the same shop page-->
<article (click)="openShopView()" class="relative flex w-full h-full flex-col">

  <!-- Card top -->
  <div class="overflow-hidden text-white rounded-xl h-[100px] md:h-[130px] lg:h-[170px] xl:h-[200px]">
    <img [src]="shop.mainImageUrl | cdn:'m'" class="object-cover w-full h-full" loading="lazy" [alt]="shop.shopI18n?.name">

    <!-- Banner -->
    <app-image-banner [bannerType]="bannerType"></app-image-banner>

    <!-- Save in Favourites -->
    <app-widget-heart [shopId]="shop.id"></app-widget-heart>
  </div>


  <!-- Card bottom -->
  <div class="flex flex-1 flex-col justify-between py-1.5 md:py-3 px-1.5">

    <div>
      <!-- Shop Name -->
      <p class="text-sm md:text-base line-clamp-2 antialiased font-medium leading-snug tracking-normal text-blue-gray-900 mb-0.5">
        {{ shop.shopI18n?.name }}
      </p>

      <!-- Shop tags -->
      <p class="font-sans line-clamp-1 text-xs md:text-sm antialiased font-light leading-relaxed text-gray-500 mb-1 flex gap-2">
        <span *ngFor="let tag of shop.tags; let last = last" class="whitespace-nowrap" [ngClass]="last ? '' : 'after:content-[\',\']'">{{ tag }}</span>
      </p>
    </div>

    <div class="align-end mt-2">
      <div class="w-min rounded badge text-tm-red bg-tm-booknow-bg mb-2" *ngIf="bookNowService.getEnabled(shop)">
        {{ 'homePage.instantCnfBadge' | transloco }}
      </div>
      <!-- Rating and distance -->
      <div *ngIf="hotelShopDistance && rating" class="flex items-center gap-1 md:gap-1.5 mb-1">
        <span
          *ngIf="rating"
          class="flex items-center gap-0.5 text-xs md:text-base font-medium text-red-600 antialiased"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
            class="-mt-0.5 h-3 w-3 md:h-5 md:w-5 text-tm-red"
          >
            <path
              fill-rule="evenodd"
              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {{ rating | ratingDecimal }}
        </span>
        <span *ngIf="hotelShopDistance && rating" class="text-gray-300 text-xs md:sm">
          •
        </span>
        <div *ngIf="hotelShopDistance" class="line-clamp-1 text-xs md:text-base antialiased font-light text-gray-500">
          <distance-label [hotelShopDistance]="hotelShopDistance" />
        </div>
      </div>

      <!-- Price and rating -->
      <div class="flex gap-2">
        <div>
          <div class="flex md:flex-row items-center leading-none align-bottom gap-1 md:gap-1 ">
            <span class="antialiased text-xs md:text-sm"> {{'shopCard.from' | transloco}} </span>
            <span class="antialiased text-sm md:text-base"> {{ ('shopCard.fromValue' | transloco: {value: shop.minCoursePrice | currencyConverter | async}) }} </span>
            <span *ngIf="shop.displayMinCoursePrice" class="hidden md:block text-gray-500 decoration-gray-400 antialiased text-sm line-through"> {{ ('shopCard.fromValue' | transloco: {value: shop.displayMinCoursePrice | currencyConverter | async}) }} </span>
          </div>
          <span *ngIf="shop.displayMinCoursePrice" class="md:hidden text-gray-500 decoration-gray-400 antialiased text-sm md:text-base line-through"> {{ ('shopCard.fromValue' | transloco: {value: shop.displayMinCoursePrice | currencyConverter | async}) }} </span>
        </div>

        <span
          *ngIf="rating && !hotelShopDistance"
          class="flex items-center gap-0.5 leading-relaxed text-xs md:text-base font-medium antialiased text-red-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
            class="-mt-0.5 h-4 w-4 md:h-5 md:w-5 text-tm-red"
          >
            <path
              fill-rule="evenodd"
              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {{ rating | ratingDecimal }}
        </span>
      </div>
    </div>


  </div>
</article>
