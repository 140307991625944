import {Routes} from '@angular/router';
import {UserResolverActivate} from "./shared/service/auth/guards/auth-guard.service";
import {LanguageResolver} from "./shared/service/lang/lang-resolver.service";
import {conciergeRoutes} from "./concierge-app/concierge-routing";

export const appRoutes: Routes = [
  {
    path: 'app',
    canActivate: [UserResolverActivate],
    resolve: {langResolved: LanguageResolver},
    children: conciergeRoutes,
  },
  {
    path: '**', redirectTo: 'app', pathMatch: 'full'
  }
];
