import {Routes} from '@angular/router';
import {ResultRedirectComponent} from '../shared/result-redirect/result-redirect.component';
import {CanActivateLoginRequired} from '../shared/service/auth/guards/auth-guard.service';
import {ReferrerGuardActivate} from '../shared/service/auth/guards/referrer-guard.service';
import {
  CanAccessReservationDetails,
  PaymentPageResolver,
} from '../shared/service/auth/guards/reservation-payment-guard.service';
import {ConciergeAppComponent} from './concierge-app.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {HomePageComponent} from './home-page/home-page.component';

export const conciergeRoutes: Routes = [
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: 'legal',
    component: ConciergeAppComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./terms-of-use/terms-of-use.component').then((m) => m.TermsOfUseComponent),
      },
      {
        path: '**',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    component: ConciergeAppComponent,
    canActivate: [ReferrerGuardActivate],
    children: [
      {
        path: 'support/contact',
        loadComponent: () => import('./contact-page/contact-page.component').then((m) => m.ContactPage),
      },
      {
        path: 'result/redirect',
        component: ResultRedirectComponent,
      },
      {
        path: '',
        component: HomePageComponent,
      },
      {
        path: 'list',
        loadComponent: () => import('./shop-list-view/shop-list-view.component').then((m) => m.ShopListViewComponent),
      },
      {
        path: 'list:/search',
        loadComponent: () => import('./shop-list-view/shop-list-view.component').then((m) => m.ShopListViewComponent),
      },
      {
        path: 'shop/:number',
        loadComponent: () => import('./shop-view-booking/shop-view-booking.component').then((m) => m.ShopViewBookingComponent),
      },
      {
        path: 'help-center',
        loadComponent: () => import('./help-center/help-center.component').then((m) => m.HelpCenterComponent),
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent),
        canActivate: [CanActivateLoginRequired],
        children: [
          {
            path: 'orders',
            loadComponent: () => import('./dashboard/orders/orders.component').then((m) => m.OrdersComponent)
          },
          {
            path: 'favourites',
            loadComponent: () => import('./dashboard/favourites/favourite.component').then((m) => m.FavComponent)
          },
        ],
      },
      {
        path: 'shop/:number/booking-details',
        loadComponent: () => import('./shop-view-booking/booking-details/booking-details.component').then((m) => m.BookingDetailsComponent),
      },
      {
        path: 'shop/:number/course-booking/:courseId',
        loadComponent: () => import('./shop-view-booking/booking-details/booking-details.component').then((m) => m.BookingDetailsComponent),
      },
      {
        path: 'shop/:number/reservation/:resId/pay',
        loadComponent: () => import('./shop-view-booking/payment/payment.component').then((m) => m.PaymentComponent),
        canActivate: [],
        resolve: {resolverData: PaymentPageResolver},
      },
      {
        path: 'confirmation/:resId/result', // This route deviates from standard routing for analytical purposes. Please update route in reservation-payment-guard.service.ts if this route changes.
        loadComponent: () => import('./result-page/result-page.component').then((m) => m.ResultPageComponent),
        canActivate: [CanAccessReservationDetails],
      },
      {
        path: 'reservation/:resId/result-view', //This route has the exact same logic as the one above, but is used for the "View Booking" button on the order card and for Analytics purposes.
        loadComponent: () => import('./result-page/result-page.component').then((m) => m.ResultPageComponent),
        canActivate: [CanActivateLoginRequired, CanAccessReservationDetails],
      },
      {
        path: '**',
        loadComponent: () => import('./home-page/home-page.component').then((m) => m.HomePageComponent),
        pathMatch: 'full',
      },
      {
        path: '',
        loadComponent: () => import('./home-page/home-page.component').then((m) => m.HomePageComponent),
        pathMatch: 'full',
      },
    ],
  },
];
