<!-- search-by-cuisine.component.html -->
<div class="rounded-lg mt-6 p-0 lg:px-6 lg:pb-6" *ngIf="!showTypeahead">
    <div class="mx-auto rounded-lg lg:overflow-auto sm:max-h-[50vh]">
        <!-- Search by Cuisine Section -->
        <div class="mb-8">
          <p class="text-[16px] font-semibold text-gray-800">{{'search.searchByCuisine' | transloco}}</p>
          <div class="flex flex-wrap gap-2 mt-3">
            <div *ngFor="let tag of tags">
              <button
                class="bg-gray-100 text-gray-800 font-normal px-3 py-[6px] rounded-full hover:cursor-pointer hover:bg-opacity-10 hover:bg-gray-300"
                (click)="offcanvas.dismiss(); closeParentDropdown.emit(true); searchService.tempNav(tag.tagEnum)"
                >
                  {{ tag.tag }}
              </button>
            </div>
          </div>
        </div>
        <!--Area Locations Section -->
        <div class="mb-8">
          <p class="text-[16px] font-semibold text-gray-800 mb-4">{{'search.location' | transloco}}</p>
          <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
            <div *ngFor="let location of featuredLocations" 
              class="rounded overflow-hidden flex flex-row gap-4 items-center hover:bg-gray-200 hover:bg-opacity-50 hover:cursor-pointer"
              (click)="offcanvas.dismiss(); closeParentDropdown.emit(true); onLocationClick(location.addressState)">
              <!-- Use your custom image viewer component -->
              <div class="h-[60px] w-[60px] flex-shrink-0">
                <img [src]="location.desktopImageUrl | cdn:'s'"  class="object-cover w-full h-full rounded-lg">
            </div>
            <div class="font-normal text-m">
              {{ "filter.states." +  location.cityName.toLowerCase() | transloco }}
            </div>
          </div>
          </div>
        </div>
        <!-- Recommended Restaurants Section -->
        <div class="mb-8">
          <p class="text-[16px] font-semibold text-gray-800 mb-4">{{'search.recommendedRestaurants' | transloco}}</p>
          <ul style="padding-left:0rem!important">
            <li class="flex items-center mb-4 hover:bg-gray-200 hover:bg-opacity-50 hover:cursor-pointer" *ngFor="let restaurant of recommendedRestaurants" (click)="offcanvas.dismiss(); openShop(restaurant.number)">
              <div class="h-[50px] w-[50px] mr-4 flex-shrink-0">
                <img [src]="restaurant.mainImageUrl | cdn:'s'" class="object-cover w-full h-full rounded-lg">
              </div>
              <div>
                <p class="font-semibold mb-0">{{ restaurant?.shopI18n?.name }}</p>
                <p class="text-gray-600 mb-0">{{ restaurant?.tags }}</p>
              </div>
            </li>
          </ul>
        </div>
    </div>
</div>

<div *ngIf="showTypeahead">
  <div *ngIf="result.type === 'QueryInboundSearchTypeahead'" class="inline-flex gap-4 items-center">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.1" width="40" height="40" rx="5" fill="#DF0629"/>
      <path d="M23.4351 22.0629H22.7124L22.4563 21.8159C23.3528 20.773 23.8925 19.4191 23.8925 17.9463C23.8925 14.6621 21.2304 12 17.9463 12C14.6621 12 12 14.6621 12 17.9463C12 21.2304 14.6621 23.8925 17.9463 23.8925C19.4191 23.8925 20.773 23.3528 21.8159 22.4563L22.0629 22.7124V23.4351L26.6369 28L28 26.6369L23.4351 22.0629ZM17.9463 22.0629C15.6684 22.0629 13.8296 20.2241 13.8296 17.9463C13.8296 15.6684 15.6684 13.8296 17.9463 13.8296C20.2241 13.8296 22.0629 15.6684 22.0629 17.9463C22.0629 20.2241 20.2241 22.0629 17.9463 22.0629Z" fill="#DF0629"/>
    </svg>
    <ngb-highlight [result]="result.query" [term]="term"></ngb-highlight>
  </div>
  <div *ngIf="result.type === 'PrefectureInboundSearchTypeahead'" class="inline-flex">
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         class="bi bi-shop ms-0 me-3" width="28" zoomAndPan="magnify" viewBox="3 3 19 22" height="28"
         preserveAspectRatio="xMidYMid meet" version="1.0">
      <defs>
        <clipPath id="id1">
          <path d="M 10 7 L 21.046875 7 L 21.046875 25.875 L 10 25.875 Z M 10 7 " clip-rule="nonzero"/>
        </clipPath>
        <clipPath id="id2">
          <path d="M 7 3.375 L 16 3.375 L 16 10 L 7 10 Z M 7 3.375 " clip-rule="nonzero"/>
        </clipPath>
        <clipPath id="id3">
          <path d="M 5.804688 6 L 12 6 L 12 15 L 5.804688 15 Z M 5.804688 6 " clip-rule="nonzero"/>
        </clipPath>
        <clipPath id="id4">
          <path d="M 11 3.375 L 21 3.375 L 21 14 L 11 14 Z M 11 3.375 " clip-rule="nonzero"/>
        </clipPath>
      </defs>
      <g clip-path="url(#id1)">
        <path fill="rgb(28.239441%, 70.979309%, 39.219666%)"
              d="M 19.820312 15.644531 L 19.828125 15.644531 C 19.828125 15.644531 17.777344 18.679688 16.050781 20.933594 C 14.5625 22.882812 14.125 24.550781 14 25.359375 C 13.953125 25.652344 13.714844 25.875 13.425781 25.875 C 13.136719 25.875 12.898438 25.652344 12.847656 25.359375 C 12.722656 24.550781 12.289062 22.882812 10.796875 20.933594 C 10.570312 20.632812 10.335938 20.320312 10.101562 20 L 15.730469 13.25 L 20.261719 7.828125 C 20.761719 8.886719 21.039062 10.074219 21.039062 11.332031 C 21.039062 12.921875 20.589844 14.398438 19.820312 15.644531 Z M 19.820312 15.644531 "
              fill-opacity="1" fill-rule="nonzero"/>
      </g>
      <path fill="rgb(98.81897%, 77.648926%, 5.488586%)"
            d="M 15.730469 13.25 L 10.101562 20 C 8.566406 17.925781 7.023438 15.644531 7.023438 15.644531 L 7.027344 15.644531 C 6.863281 15.378906 6.71875 15.113281 6.59375 14.832031 L 11.117188 9.410156 C 10.6875 9.929688 10.433594 10.601562 10.433594 11.332031 C 10.433594 13 11.769531 14.347656 13.425781 14.347656 C 14.355469 14.347656 15.1875 13.921875 15.730469 13.25 Z M 15.730469 13.25 "
            fill-opacity="1" fill-rule="nonzero"/>
      <g clip-path="url(#id2)">
        <path fill="rgb(17.248535%, 52.159119%, 92.158508%)"
              d="M 15.816406 3.78125 L 11.15625 9.367188 L 7.589844 6.226562 C 8.980469 4.480469 11.082031 3.375 13.425781 3.375 C 14.261719 3.375 15.066406 3.515625 15.816406 3.78125 Z M 15.816406 3.78125 "
              fill-opacity="1" fill-rule="nonzero"/>
      </g>
      <g clip-path="url(#id3)">
        <path fill="rgb(92.939758%, 34.118652%, 28.239441%)"
              d="M 11.15625 9.367188 L 11.117188 9.410156 L 6.59375 14.832031 C 6.09375 13.773438 5.808594 12.585938 5.808594 11.332031 C 5.808594 9.386719 6.480469 7.605469 7.589844 6.226562 Z M 11.15625 9.367188 "
              fill-opacity="1" fill-rule="nonzero"/>
      </g>
      <g clip-path="url(#id4)">
        <path fill="rgb(33.729553%, 58.428955%, 96.469116%)"
              d="M 15.730469 13.25 C 16.160156 12.730469 16.417969 12.058594 16.417969 11.332031 C 16.417969 9.664062 15.078125 8.3125 13.425781 8.3125 C 12.496094 8.3125 11.664062 8.742188 11.117188 9.410156 L 15.816406 3.78125 C 17.769531 4.449219 19.371094 5.925781 20.261719 7.828125 Z M 15.730469 13.25 "
              fill-opacity="1" fill-rule="nonzero"/>
      </g>
    </svg>
    <ngb-highlight [result]="result.prefecture" [term]="term"></ngb-highlight>
  </div>

  <div *ngIf="result.type === 'ShopInboundSearchTypeahead'">

    <div class="flex items-center mb-4 " (click)="offcanvas.dismiss(); openShop(result.number)">
      <div class="h-[50px] w-[50px] mr-4 flex-shrink-0">
        <img [src]="result.mainImgUrl | cdn:'s'"  class="object-cover w-full h-full rounded-lg">
      </div>
      <div>
        <p class="mb-0 line-clamp-1">
          <ngb-highlight [result]="result.name" [term]="term"></ngb-highlight>
        </p>
        <p class="text-gray-600 mb-0">{{ formatTags(result.tags) }}</p>
      </div>
    </div>
  </div>

  <div *ngIf="result.type === 'TagInboundSearchTypeahead'" class="inline-flex">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" class="bi bi-shop ms-0 me-3" fill="currentColor"
         viewBox="0 0 16 16">
      <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
      <path
        d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
    </svg>
    <ngb-highlight [result]="result.tag" [term]="term"></ngb-highlight>
  </div>
</div>
