<div class="mt-2 mb-4 flex justify-between gap-2 items-start">
  <div class="text-gray-900">
    @if (titleTemplate) {
      <div class="text-xl max-w-fit font-medium">
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
      </div>
    } @else {
      <h3 class="flex text-xl max-w-fit font-medium min-h-7 mb-0">{{ title }}</h3>
    }
    <div class="text-sm max-w-fit font-medium">
      <ng-container *ngTemplateOutlet="subTemplate;"></ng-container>
    </div>
  </div>
  @if (seeMoreFn && cardData) {
    <div class="flex gap-1 min-w-max align-middle">
      <span class="text-sm text-tm-red hover:cursor-pointer"
            (click)="seeMoreFn()">{{ 'search.seeMore' | transloco }}</span>
    </div>
  }
</div>

@if (disableSwiper || smallScreen) {
  <div class="flex overflow-x-auto gap-3 no-scrollbar">
    @if (cardData) {
      @for (data of cardData; track cardDataIndexExtractor($index, data)) {
        <div class="rounded-lg min-h-full">
          <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: data, index: $index }"></ng-container>
        </div>
      }
    } @else if (placeholderTemplate) {
      @for (data of placeholderItems; track $index) {
        <div class="rounded-lg min-h-full">
          <ng-container *ngTemplateOutlet="placeholderTemplate; context: { index: $index }"></ng-container>
        </div>
      }
    }
  </div>
} @else {
  <app-swiper-container [slideTemplate]="contentTemplate" [slideData]="cardData"
                        [dataTrackBy]="cardDataIndexExtractor" [slidesPerView]="slidesPerView"
                        [placeholderTemplate]="placeholderTemplate" [placeholderItems]="placeholderItems"
                        [disableHover]="disableHover" [disableNavigation]="disableNavigation"/>
}
