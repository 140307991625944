import { formatCurrency } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ApiService } from '../service/api/api.service';
import { Observable, from, map, BehaviorSubject, firstValueFrom } from 'rxjs';
import { of } from 'rxjs'
import { CurrencyCache } from './currency-cache';
import { ExchangeRate } from './exchange-rate';
import { CurrencyInfo } from './currency-info';
import { FILTER_PRICE_CURRENCY_LIMIT, SupportedCurrency } from "./currency-const";


// This pipe is used to convert currencies and format them according to the target currency
// Note that currency rates are cached (so we request the rate for a currency only one time)
@Pipe({
  name: 'currencyConverter',
  pure: true,
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
export class CurrencyConverterPipe implements PipeTransform {

  constructor(
    private currencyCache: CurrencyCache) {
  }

  supportedCurrency = Object.values(SupportedCurrency)

  getSupportedCurrencies() : string[] {
    return this.supportedCurrency
  }

  setActiveCurrency(currency: string) {
    this.currencyCache.setActiveCurrency(currency)
  }

  getActiveCurrency() {
    return this.currencyCache.activeCurrency;
  }

  getCurrencyLimit(currency: SupportedCurrency): number {
    return FILTER_PRICE_CURRENCY_LIMIT?.[currency] ?? FILTER_PRICE_CURRENCY_LIMIT.JPY;
  }

  getActiveCurrencyLimit(): number {
    return FILTER_PRICE_CURRENCY_LIMIT[this.getActiveCurrency() as SupportedCurrency] ?? FILTER_PRICE_CURRENCY_LIMIT.JPY
  }

  get activeCurrency$(): BehaviorSubject<string> {
    return this.currencyCache.activeCurrency$;
  }

  transform(priceInJPY: any, targetCurrency?: string, excludeDecimal?: boolean): Observable<string> {
    let currencyInfo: CurrencyInfo = this.getTargetCurrency(targetCurrency!);

    const displayPrice = priceInJPY ? priceInJPY: 0;

    // No need conversion
    if (currencyInfo.code === "JPY") {
      return of(
        formatCurrency(displayPrice, currencyInfo.locale, currencyInfo.symbol, currencyInfo.code)
      )
    }

    // Convert value
    return from(this.getExchangeRate(currencyInfo.code)).pipe(
      map(exchangeRate => {
        let value = exchangeRate.rate * priceInJPY
        if(excludeDecimal) {
          value = Math.round(value)
        }
        return formatCurrency(
          value,
          currencyInfo.locale, currencyInfo.symbol, currencyInfo.code,
          excludeDecimal ? "1.0-0" : "")
      }))
  }

  formatAmount(amount: number) : string {
    let currencyInfo: CurrencyInfo = this.getTargetCurrency(null);
    return formatCurrency(amount, currencyInfo.locale, currencyInfo.symbol, currencyInfo.code)
  }

  getCurrentCurrencyCode() : string{
    let currencyInfo: CurrencyInfo = this.getTargetCurrency(null);
    return currencyInfo.code;
  }

  getYenValueForCurrentCurrency() : Observable<string> {
    let currencyInfo: CurrencyInfo = this.getTargetCurrency(null);
    let JPYCurrencyInfo: CurrencyInfo = this.getTargetCurrency("JPY");
    return from(this.getExchangeRate(currencyInfo.code)).pipe(
      map(exchangeRate => formatCurrency(exchangeRate.inverseRate, JPYCurrencyInfo.locale, JPYCurrencyInfo.symbol, JPYCurrencyInfo.code, "1.2-3"))
    )
  }

  async getYenEquivalent(currentCode: string, numberInForeignCurrency: number): Promise<number> {
    if (currentCode === "JPY") {
      return numberInForeignCurrency;
    }

    let currencyInfo: CurrencyInfo = this.getTargetCurrency(currentCode);
    let exchangeRate = await this.getExchangeRate(currencyInfo.code)

    return numberInForeignCurrency * exchangeRate.inverseRate
  }

  async getYenEquivalentArray(currentCode: string, foreignValues: number[]): Promise<number[]> {
    if (currentCode === "JPY") {
      return foreignValues;
    }

    if (!foreignValues) {
      return foreignValues;
    }

    let currencyInfo: CurrencyInfo = this.getTargetCurrency(currentCode);
    let exchangeRate = await this.getExchangeRate(currencyInfo.code)

    return foreignValues.map(value => value * exchangeRate.inverseRate)
  }

  getTargetCurrency(targetCurrency: string|null): CurrencyInfo {
    let currencyToEvaluate = targetCurrency != null ? targetCurrency : this.currencyCache.activeCurrency
    switch (currencyToEvaluate) {
      case "USD":
        return { code: "USD", symbol: "$", locale: "en" }
      case "CNY":
        return { code: "CNY", symbol: "CN¥", locale: "zh" }
      case "EUR":
        return { code: "EUR", symbol: "€", locale: "en" }
      case "KRW":
        return { code: "KRW", symbol: "₩", locale: "ko" }
      default:
        return { code: "JPY", symbol: "¥", locale: "ja" }
    }
  }

  // Get exchange rate info for a currency (based on JPY)
  getExchangeRate(currrency: string): Promise<ExchangeRate> {
    return this.currencyCache.getExchangeRate(currrency);
  }
}
