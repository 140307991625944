import packageInfo from '../../package.json';
export const environment = {
  name: 'production',
  release: packageInfo.version,
  baseUrl: 'https://www.japanfoodie.jp',
  cdnUrl: 'https://cdn.takeme.com',
  qrPayUrl: 'https://p.takeme.com',
  cookieDomain: 'app.takeme.com',
  recaptchaSiteKey: '6Ld_PakoAAAAAHEySNFIEKc8b8G9j2W_zwRXXUYl',
  acitvateRecaptcha: false,
  minTagCount: 7,
  defaultCancellationPolicyDays: 3,
};
