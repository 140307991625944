import {
  ApplicationRef,
  Component,
  ContentChild,
  EnvironmentInjector,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  createComponent,
} from '@angular/core';
import {forkJoin,lastValueFrom,noop} from 'rxjs';
import {Shop} from '../../../concierge-app/shop-list-view/models/shop-list';
import {CurrencyConverterPipe} from '../../currency/currency-converter.pipe';
import {GoogleMapsLoader} from '../GoogleMapsLoader';

import {NgIf} from '@angular/common';
import {GoogleMapsModule} from '@angular/google-maps';
import {TranslocoService} from '@ngneat/transloco';
import {ShopCardComponent} from 'src/app/concierge-app/shop-card/shop-card.component';
import {AuthApiService} from '../../service/auth/auth-api.service';
import {ConciergeReferral} from '../../service/auth/models/concierge-referral';
import {DeviceService} from '../../service/device/device.service';
import {ReferralService} from '../../service/referrer/referral.service';
import {GoogleMapApiService} from '../GoogleMapApiService';
import {PlaceDetails} from '../model/PlaceDetails';
import {HotelMarker} from './hotel-marker';
import {ShopMarkerMetaData} from './shop-marker';

@Component({
  selector: 'app-google-map-shops',
  templateUrl: './google-map-shops.component.html',
  styleUrls: ['./google-map-shops.component.scss'],
  standalone: true,
  imports: [GoogleMapsModule, NgIf, ShopCardComponent],
})
export class GoogleMapShopsComponent implements OnInit {
  constructor(
    private googleMapsLoader: GoogleMapsLoader,
    private currencyConverter: CurrencyConverterPipe,
    private googleMapApiService: GoogleMapApiService,
    private deviceService: DeviceService,
    private translocoService: TranslocoService,
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
    private authService: AuthApiService,
    private referrerService: ReferralService,
  ) {}
  @Input() homePage = false;

  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<any>;
  googleMapLoaded = false;
  shopList: Shop[] | null = null;

  map!: google.maps.Map;
  shopRatings: Record<string, number> = {};
  options: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: this.deviceService.isWebView,
    fullscreenControl: this.deviceService.isWebView,
    gestureHandling: 'greedy',
    mapId: '1',
  };

  ngOnInit() {
    this.options = {
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: this.deviceService.isWebView,
      fullscreenControl: this.deviceService.isWebView || this.homePage, // Now homePage has a correct value
      gestureHandling: 'greedy',
      mapId: '1',
    };
  }

  advancedMarkers: any[] = [];
  selectedMarker!: any;
  markerShopMetadataMap = new Map<any, ShopMarkerMetaData>();
  selectedShop!: Shop | null;
  shouldDisplaySelectedMarkerModal: boolean = false;
  hotelMarker!: HotelMarker;
  hotelMarkerElement!: any;

  @Input() set dataList(value: Shop[] | undefined | null) {
    if (!value) {
      return;
    }

    this.shopList = value;
    this.updateShopMarker().then((r) => noop);
  }

  @Output() dataLoadedEvent: EventEmitter<ShopMarkerMetaData[]> =
    new EventEmitter();
  @Output() shopRatingEvent: EventEmitter<Record<string, number>> =
    new EventEmitter();

  handleMapInitialized(map: google.maps.Map) {
    this.map = map;
    this.map.addListener('click', () => this.closeMarkerOnInteractOutside());
    this.map.addListener('dragstart', () =>
      this.closeMarkerOnInteractOutside(),
    );
    this.updateShopMarker().then((r) => noop);
    this.updateHotelMarker();
  }

  updateHotelMarker() {
    let referrerSlug = this.referrerService.getReferrerSlug();
    if (referrerSlug) {
      lastValueFrom(this.authService.getConciergeReferral(referrerSlug)).then(
        (referralInfo) => {
          if (referralInfo?.googlePlaceId) {
            lastValueFrom(
              this.googleMapApiService.getPlaceDetails(
                referralInfo.googlePlaceId,
              ),
            ).then((placeInfo) => {
              if (placeInfo) {
                this.hotelMarker = this.getHotelMarker(placeInfo, referralInfo);
                this.addHotelMarker(this.hotelMarker);
              }
            });
          }
        },
      );
    }
  }

  closeMarkerOnInteractOutside() {
    if (this.selectedMarker) {
      const previousMarkerMetadata = this.markerShopMetadataMap.get(
        this.selectedMarker,
      );
      this.selectedMarker.content = previousMarkerMetadata?.markerElement;
      this.selectedMarker.zIndex = 2;
      previousMarkerMetadata?.infoWindow?.close();
    }
  }

  async updateShopMarker(): Promise<void> {
    if (!this.shopList) return;
    if (!this.googleMapLoaded) {
      this.googleMapLoaded = await this.googleMapsLoader.loadedOrAlreadyLoaded;
    }
    if (!this.map) return;

    this.clearMarker();

    this.shopList.forEach((shop) => {
      if (shop.googlePlaceId) {
        lastValueFrom(
          this.googleMapApiService.getPlaceDetails(shop.googlePlaceId),
        ).then((placeDetails) => {
          const shopMarker = this.getShopMarker(placeDetails, shop);

          let minPrice = this.currencyConverter.transform(
            shopMarker.shop.minCoursePrice!,
            undefined,
            true,
          );
          let maxPrice = this.currencyConverter.transform(
            shopMarker.shop.maxCoursePrice!,
            undefined,
            true,
          );
          let minMax = forkJoin([minPrice, maxPrice]);

          lastValueFrom(minMax).then(async (minMax) => {
            shopMarker.minPrice = minMax[0];
            await this.addShopMarker(shopMarker);
            this.fitBoundsWithMarkers();

            if (placeDetails?.rating) {
              const shopId = shop.id as string;
              this.shopRatings[shopId] = placeDetails.rating;
            }
          });

          this.emitDataEvents();
        });
      }
    });
  }

  clearMarker(): void {
    this.advancedMarkers.forEach((marker) => (marker.map = null));
    this.advancedMarkers = [];
  }

  async addShopMarker(shopMarkerMetaData: ShopMarkerMetaData): Promise<void> {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker',
    )) as google.maps.MarkerLibrary;

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: shopMarkerMetaData.position,
      zIndex: 1,
    });
    shopMarkerMetaData.advancedMarkerElement = marker;

    // Create Inner HTML
    const priceTag = this.buildPriceTagElement(shopMarkerMetaData);
    shopMarkerMetaData.markerElement = priceTag;

    // Create map info window
    const infoWindow = this.createInfoWindow(shopMarkerMetaData);

    const onClick = () => {
      if (this.hotelMarkerElement && marker !== this.hotelMarkerElement) {
        this.hotelMarkerElement.zIndex = 1;
      }

      if (this.selectedMarker) {
        if (this.selectedMarker !== marker) {
          // Close the previous marker when a previous is selected
          const previousMarkerMetadata = this.markerShopMetadataMap.get(
            this.selectedMarker,
          );
          this.selectedMarker.zIndex = 2;
          previousMarkerMetadata?.infoWindow?.close();
        }
      }
      this.selectedMarker = marker;
      this.selectedShop = shopMarkerMetaData.shop;
      marker.zIndex = 3;

      if (this.deviceService.isMobile && !this.homePage) {
        // In mobile we display a modal of "selectedMarker"
        this.shouldDisplaySelectedMarkerModal = true;
      } else {
        // In desktop we display a window above the marker
        if (this.homePage) {
          this.openMapInFullScreen();
        }
        infoWindow.open(this.map, marker);
      }
    };

    // Add listeners to PriceTag element
    priceTag.addEventListener('click', onClick);
    priceTag.addEventListener('touchend', onClick); // Handle "click" in mobile

    priceTag.addEventListener('mouseenter', () => {
      marker.zIndex = 2;
    });
    priceTag.addEventListener('mouseleave', () => {
      if (this.selectedMarker === marker) return;
      marker.zIndex = 1;
    });

    // Assign html to the google marker
    marker.content = priceTag;

    // Marker must have a click listener otherwise html element wont be interactable (!?)
    marker.addListener('click', () => {});

    this.advancedMarkers.push(marker);
    this.markerShopMetadataMap.set(marker, shopMarkerMetaData);
  }

  async addHotelMarker(hotelMarker: HotelMarker) {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker',
    )) as google.maps.MarkerLibrary;

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: hotelMarker.position,
      zIndex: 100,
    });

    this.hotelMarkerElement = marker;

    marker.content = this.buildHotelMarkerElement(hotelMarker);
  }

  private createInfoWindow(
    shopMarkerMetaData: ShopMarkerMetaData,
  ): google.maps.InfoWindow {
    const shopCardDivContainer = document.createElement('div');
    shopCardDivContainer.className = 'w-auto h-auto pt-2 pl-2 pb-2';

    const shopCardComponentElement = document.createElement('div');
    shopCardComponentElement.className = 'h-full';
    shopCardDivContainer.appendChild(shopCardComponentElement);
    // Create an info window for the marker
    let infoWindow = new google.maps.InfoWindow({
      content: shopCardDivContainer,
      minWidth: 335,
    });

    shopMarkerMetaData.infoWindow = infoWindow;

    const host = shopCardComponentElement;
    const cardShopComponent = createComponent(ShopCardComponent, {
      hostElement: host,
      environmentInjector: this.injector,
    });
    cardShopComponent.setInput('shop', shopMarkerMetaData.shop);
    cardShopComponent.setInput('mapView', true);
    cardShopComponent.setInput('rating', shopMarkerMetaData.rating);
    this.appRef.attachView(cardShopComponent.hostView);

    return infoWindow;
  }

  private emitDataEvents(): void {
    this.shopRatingEvent.emit(this.shopRatings);
  }

  private getShopMarker(
    result: PlaceDetails | null,
    shop: Shop,
  ): ShopMarkerMetaData {
    return {
      shop,
      position: {
        lat: result?.geometry?.location?.lat || 0,
        lng: result?.geometry?.location?.lng || 0,
      },
      minPrice: `¥${shop.minCoursePrice}`,
      rating: result?.rating,
    };
  }

  private getHotelMarker(
    result: PlaceDetails | null,
    referralInfo: ConciergeReferral,
  ): HotelMarker {
    return {
      conciergeReferral: referralInfo,
      position: {
        lat: result?.geometry?.location?.lat || 0,
        lng: result?.geometry?.location?.lng || 0,
      },
    };
  }

  // The map center will include every marker within the visible space
  fitBoundsWithMarkers(): void {
    if (this.googleMapLoaded) {
      const bonds = new google.maps.LatLngBounds();
      this.advancedMarkers?.forEach((marker: ShopMarkerMetaData) => {
        bonds.extend(marker.position);
      });
      this.map.fitBounds(bonds);
    }
  }

  closeMobileModal() {
    this.shouldDisplaySelectedMarkerModal = false;
  }

  openMapInFullScreen() {
    const fullscreenBtn = document.querySelector(
      '.gm-fullscreen-control',
    ) as HTMLElement;

    if (fullscreenBtn && !document.fullscreenElement) {
      fullscreenBtn.click(); // This will toggle full-screen mode
    } else if (!fullscreenBtn) {
      console.warn('Full-screen button not found.');
    }
  }

  // -- Marker's HTML definition
  // No libraries for Angular beta google map, using native library is the most futureproof
  // So we have to inject html directly in map canvas through the official API

  buildPriceTagElement(shopMarker: ShopMarkerMetaData): HTMLDivElement {
    const priceTag = document.createElement('div');
    let shop = shopMarker.shop;
    priceTag.innerHTML = `
    <div class="w-full min-w-[50px] flex justify-center">
      <div class="group relative w-full">
        <div class="rounded-lg overflow-hidden shadow-lg">
          <div class="flex flex-col text-center">
            <div class="${
              shop.instantReservationEnabled ? 'bg-red-600' : 'bg-black'
            } px-1 flex gap-1">
              <div class="${
                shop.instantReservationEnabled ? 'flex' : 'hidden'
              } justify-center items-center w-full min-h-full">
                <svg fill="none" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.33366 14.6666L6.00033 9.99992H2.66699L8.66699 1.33325H10.0003L9.33366 6.66658H13.3337L6.66699 14.6666H5.33366Z"
                    fill="#FFFFFF" />
                </svg>
              </div>
              <span class="text-white text-base">
                ${shopMarker.minPrice}
              </span>
            </div>
            <div class="border-t border-color-white bg-white px-1 ${
              shop.instantReservationEnabled
                ? 'group-hover:bg-red-600'
                : 'group-hover:bg-black'
            } ">
              <span class="text-black flex justify-center text-base group-hover:text-white">

                <span
                *ngIf="rating"
                class="flex items-center gap-0.5 text-base leading-relaxed text-blue-gray-900 antialiased"
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  class="-mt-0.5 h-4 w-4 md:h-5 md:w-5 ${
                    shop.instantReservationEnabled
                      ? 'text-tm-red'
                      : 'text-black'
                  }  group-hover:text-white"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                ${shopMarker.rating ? shopMarker.rating : '?'}
              </span>
            </div>
          </div>
        </div>

        <div class="absolute -bottom-0 left-1/2 transform -translate-x-1/2 -mb-4 drop-shadow-lg">
        <span class="text-xl text-white ${
          shop.instantReservationEnabled
            ? 'group-hover:text-red-600'
            : 'group-hover:text-black'
        }">▼</span>

      </div>
    </div>
    `;

    return priceTag;
  }

  buildHotelMarkerElement(hotelMarker: HotelMarker): HTMLDivElement {
    const priceTag = document.createElement('div');

    let yourHotel = this.translocoService.translate('filter.yourHotel');
    let hotelName = hotelMarker.conciergeReferral.referrerName;

    priceTag.innerHTML = `
    <div class="w-full min-w-[50px] flex justify-center">
    <div class="group relative w-full">
      <div class="rounded-lg overflow-hidden shadow-lg">
        <div class="flex flex-col text-center">
          <div class="bg-tm-green px-1 group-hover:bg-slate-500">
            <div class="flex flex-col items-center justify-center">
              <div class="text-white flex justify-center text-[12px] font-medium">
                <span
                *ngIf="rating"
                class="flex items-center gap-0.5 text-base leading-tight text-blue-gray-900 antialiased"
                >
                <svg class="w-4 h-4 text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                </svg>
                ${yourHotel}
              </div>
              <p class="text-white justify-center text-[10px] mb-1">${hotelName}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute -bottom-0 left-1/2 transform -translate-x-1/2 -mb-4 drop-shadow-lg">
      <span class="text-xl text-tm-green group-hover:text-slate-500">▼</span>

    </div>
  </div>
    `;

    return priceTag;
  }
}
