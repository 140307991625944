import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Course,
  CourseI18n,
} from 'src/app/concierge-app/shop-view-booking/course-reservation/models/course';

export const COLUMN_COURSE_NAME = 'course_name';
export const COLUMN_COURSE_DESCRIPTION = 'course_desc';

@Injectable({
  providedIn: 'root',
})

// get translation or fallback
export class CourseLangService {
  constructor(private translocoService: TranslocoService) {}

  getCourseName(course: Course) {
    if (!course.courseI18n) {
      return course.courseName;
    }
    let translatedName = course.courseI18n.find(
      (i18n) =>
        i18n.columnName === COLUMN_COURSE_NAME &&
        this.translocoService.getActiveLang() === i18n.lang,
    )?.dataContent;
    return translatedName || course.courseName;
  }

  getCourseNameByI18n(courseI18n: CourseI18n[]) {
    let translatedName = courseI18n.find(
      (i18n) =>
        i18n.columnName === COLUMN_COURSE_NAME &&
        this.translocoService.getActiveLang() === i18n.lang,
    )?.dataContent;
    return translatedName;
  }

  getCourseDescription(course: Course | undefined) {
    if (!course) return undefined;
    let translatedDesc = course.courseI18n.find(
      (i18n) =>
        i18n.columnName === COLUMN_COURSE_DESCRIPTION &&
        this.translocoService.getActiveLang() === i18n.lang,
    )?.dataContent;
    return translatedDesc || course.courseDesc;
  }
}
