/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { CustomModal } from 'src/app/shared/utils/custom-ngb-modal';
import { LoginModalOptions } from './login-modal-options';
import { LoginComponent } from './login.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { MeService } from 'src/app/shared/service/auth/me.service';

@Injectable({
  providedIn: 'root',
})

/**
 * Copied from app-admin-web with minor type modification
 */
export class LoginModalService implements OnDestroy {
  private postLoginAction: Function | null = null; //function to execute after successful login
  private loginSubscription: Subscription;

  constructor(
    private modalService: CustomModal,
    private meService: MeService,
    private translocoService: TranslocoService,
  ) {
    this.loginSubscription = this.meService.isLoggedIn$.subscribe(
      (isLoggedIn) => {
        if (isLoggedIn && this.postLoginAction) {
          this.postLoginAction();
          this.postLoginAction = null; // Clear the action after execution
        }
      },
    );
  }

  setPostLoginAction(executeFn: Function) {
    this.postLoginAction = executeFn;
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }

  //todo: check if we can remove load language process
  openLoginModal() {
    this.translocoService
      .load(this.translocoService.getActiveLang())
      .subscribe(() => {
        this.loadLoginModal();
      });
  }

  loadLoginModal(refreshOnLogin = true): NgbModalRef {
    const options: LoginModalOptions = {
      body: this.translocoService.translate('header.loginModal.body'),
      title: this.translocoService.translate('header.loginModal.title'),
      hideConfirmButton: true,
      refreshOnLogin: refreshOnLogin,
    };

    return this.modalService.open(
      LoginComponent,
      {
        injector: Injector.create({
          providers: [{ provide: 'options', useValue: options }],
        }),
      },
      {
        closeOnNavigate: true,
      },
    );
  }
}
