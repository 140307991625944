import { EventEmitter, Injectable, Output } from '@angular/core';
import { debounceTime, fromEvent, noop, Subscription } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { mobileViewThreshold, webViewThreshold } from "../../shared-const";

//#TODO import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  public isMobile!: boolean;
  public isWebView!: boolean; //May need to be deprecated as it does not match tailwind breakpoints

  @Output() viewChangeEvent: EventEmitter<void> = new EventEmitter<void>();

  subscription: Subscription;

  constructor() {
    this.updateView();

    this.subscription = fromEvent(window, 'resize').pipe(takeUntilDestroyed(), debounceTime(300)).subscribe({
      next: () => {
        this.updateView();
      },
      error: () => noop()
    })
  }

  public get isLargeView(): boolean {
    return window.innerWidth > 768;
  }

  private updateView(): void {
    const isMobileBeforeUpdate = this.isMobile;
    const isWebViewBeforeUpdate = this.isWebView;

    this.isMobile = !this.isGreaterThanThreshold(mobileViewThreshold)
    this.isWebView = this.isGreaterThanThreshold(webViewThreshold)

    if (this.isMobile != isMobileBeforeUpdate || this.isWebView != isWebViewBeforeUpdate) {
      this.viewChangeEvent.emit();
    }
  }

  private isGreaterThanThreshold(threshold: number): boolean {
    return window.innerWidth > threshold;
  }
}
