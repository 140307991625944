import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingBarService {

  startedBehavior: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  start(): void {
    this.startedBehavior.next(true);
  }

  stop(): void {
    this.startedBehavior.next(false);
  }
}
