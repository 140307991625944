import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, catchError, lastValueFrom, map, of } from 'rxjs';

// Security note
// The key is always accessible to the client (client side rendering)
// It is not a problem as long as we restrict the key usage to specific domains of TakeMe
// So nobody can compromise the key and over-use it
const API_KEY = 'AIzaSyCimLxyh_FN95K1WNr-mZZfx08ZfKsf-ec';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoader {
  apiLoaded: Observable<boolean>;
  loadedOrAlreadyLoaded: Promise<boolean>;

  constructor(
    private http: HttpClient,
    private transloco: TranslocoService,
  ) {
    let lang = transloco.getActiveLang();
    const googleMapApiUrl = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.57&libraries=places&callback=Function.prototype&language=${lang}`;
    this.apiLoaded = this.http.jsonp(googleMapApiUrl, 'callback').pipe(
      map(() => {
        return true;
      }),
      catchError((error) => of(error)),
    );

    // Reference a promise, will be already complete if called twice
    // so we don't load the google map dependencies twice
    this.loadedOrAlreadyLoaded = lastValueFrom(this.apiLoaded);
  }
}
