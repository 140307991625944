import {Component, ContentChild, Directive, Input, OnDestroy, TemplateRef,} from '@angular/core';
import {SearchService} from '../../service/search/search.service';
import {NgTemplateOutlet} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";
import {BreakpointObserver} from "@angular/cdk/layout";
import {Subscription} from "rxjs";
import {SwiperContainerComponent} from "./swiper-container.component";

@Directive({selector: 'ng-template[appSwiperViewerItem]', standalone: true})
export class SwiperViewerItemDirective {
}


@Directive({selector: 'ng-template[appSwiperViewerPlaceholder]', standalone: true})
export class SwiperViewerPlaceholderDirective {
}

@Component({
  selector: 'app-swiper-viewer',
  templateUrl: './swiper-viewer.component.html',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    TranslocoModule,
    SwiperContainerComponent,
  ],
})
export class SwiperViewerComponent<T> implements OnDestroy {
  @Input() cardData!: T[];
  @Input() cardDataIndexExtractor: (index: number, cardData: T) => unknown = (index) => index;
  private slidesPerView_ = 4;
  @Input() title!: string;
  @Input() type!: string;
  @Input() titleTemplate!: TemplateRef<any>;
  @Input() subTemplate!: TemplateRef<any>;
  @Input() seeMoreFn!: Function;
  @Input() disableSwiper = false;
  @Input() disableNavigation = false;

  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<any>;
  @ContentChild(SwiperViewerPlaceholderDirective, {read: TemplateRef}) placeholderTemplate!: TemplateRef<any>;
  // swiper parameters

  disableHover = false;
  smallScreen = false;
  placeholderItems: null[] = [null, null, null, null];

  @Input() set slidesPerView(value: number) {
    this.slidesPerView_ = value;
    this.disableHover = value === 1;
    this.placeholderItems = Array.from({length: value}, () => null);
  }

  get slidesPerView(): number {
    return this.slidesPerView_;
  }

  private breakpointSubscription: Subscription;

  constructor(public searchService: SearchService, breakpointObserver: BreakpointObserver) {
    this.breakpointSubscription = breakpointObserver.observe('(min-width: 640px)')
      .subscribe((v) => this.smallScreen = !v.matches);
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

}
