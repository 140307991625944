export interface FeatureLocation {
    cityName: string;
    mobileImageUrl: string;
    desktopImageUrl: string;
    mobileImageScaleClass: string;
    addressState: string;
}

export const featuredLocations: FeatureLocation[] = [
  {
    cityName: 'Tokyo',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Tokyo.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Tokyo.jpg',
    mobileImageScaleClass: '',
    addressState: 'tokyo',
  },
  {
    cityName: 'Kyoto',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Kyoto.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Kyoto.jpg',
    mobileImageScaleClass: '',
    addressState: 'kyoto',
  },
  {
    cityName: 'Osaka',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Osaka.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Osaka.jpg',
    mobileImageScaleClass: '',
    addressState: 'osaka',
  },
  {
    cityName: 'Kanagawa',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Kanagawa.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Kanagawa.jpg',
    mobileImageScaleClass: '',
    addressState: 'kanagawa',
  },
  {
    cityName: 'Okinawa',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Okinawa.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Okinawa.jpg',
    mobileImageScaleClass: '',
    addressState: 'okinawa',
  },
  {
    cityName: 'Hokkaido',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Hokkaido.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Hokkaido.jpg',
    mobileImageScaleClass: '',
    addressState: 'hokkaido',
  },
  {
    cityName: 'Aichi',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Nagoya.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Nagoya.jpg',
    mobileImageScaleClass: '',
    addressState: 'aichi',
  },
  {
    cityName: 'Hyogo',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Hyogo.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Hyogo.jpg',
    mobileImageScaleClass: '',
    addressState: 'hyogo',
  },
  {
    cityName: 'Oita',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Oita.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Oita.jpg',
    mobileImageScaleClass: '',
    addressState: 'oita',
  },
  {
    cityName: 'Fukuoka',
    mobileImageUrl: 'https://app.takeme.com/imgs/Area_Fukuoka.jpg',
    desktopImageUrl: 'https://app.takeme.com/imgs/Area_Fukuoka.jpg',
    mobileImageScaleClass: '',
    addressState: 'fukuoka',
  },
];