import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ShopListApiService } from '../shop-list-view/services/shop-list-api.service';
import { Tag } from './tag-card/models/tag';

import { NgComponentOutlet, NgIf } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { SortByOption } from 'src/app/filter/constants/search-filter-const';
import { GoogleMapShopsComponent } from 'src/app/shared/googlemap/google-map-shops/google-map-shops.component';
import { DEFAULT_PAGE_SIZE } from 'src/app/shared/models/pagination-manager';
import { RowResponse } from 'src/app/shared/models/row-response';
import { SeoService } from 'src/app/shared/service/seo/seo.service';
import { CustomModal } from 'src/app/shared/utils/custom-ngb-modal';
import { VideoComponent } from 'src/app/shared/widgets/video/video.component';
import { MeService } from '../../shared/service/auth/me.service';
import { BookNowService } from '../../shared/service/book-now/book-now.service';
import { HOME_PAGE_COMPONENT } from '../../shared/service/membership-service/common/member-constants';
import { MembershipService } from '../../shared/service/membership-service/membership.service';
import { ReferralService } from '../../shared/service/referrer/referral.service';
import { SearchService } from '../../shared/service/search/search.service';
import { BookNowComponent } from '../../shared/widgets/book-now/book-now.component';
import { PersonalizedBookingBannerComponent } from '../../shared/widgets/personalized-booking/banner/banner.component';
import { PersonalizedBookingComponent } from '../../shared/widgets/personalized-booking/personalized-booking.component';
import { SearchBarInterceptorComponent } from '../../shared/widgets/search-bar-interceptor/search-bar-interceptor.component';
import {
  SwiperViewerComponent,
  SwiperViewerItemDirective,
  SwiperViewerPlaceholderDirective,
} from '../../shared/widgets/swiper/swiper-viewer.component';
import { FeaturedExperience } from '../featured-experience/featured-experience.component';
import { FeaturedLocationComponent } from '../featured-location/featured-location.component';
import {
  FeaturedShopComponent,
  FeaturedShopPlaceholderComponent,
} from '../featured-shop/featured-shop.component';
import { Shop } from '../shop-list-view/models/shop-list';
import {
  ADDRESS_STATE,
  AddressState,
} from '../shop-list-view/models/shop-list-const';
import { ShopListParams } from '../shop-list-view/models/shop-list-params';
import { ShopListTagRequest } from '../shop-list-view/models/shop-list-tag-request';
import { HomePageService } from './home-page-api.service';
import { featuredLocations } from './models/featured-location';
import { FeaturedShop } from './models/featured-shop';
import { TagCardComponent } from './tag-card/tag-card.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styles: [
    /* For Webkit-based browsers (Chrome, Safari, and Opera) */
    '.scrollbar-hide::-webkit-scrollbar { display: none; }',

    /* For IE, Edge, and Firefox */
    '.scrollbar-hide { -ms-overflow-style: none; scrollbar-width: none; }',
  ],
  standalone: true,
  imports: [
    TranslocoModule,
    SearchBarInterceptorComponent,
    PersonalizedBookingComponent,
    NgIf,
    NgComponentOutlet,
    SwiperViewerComponent,
    FeaturedShopComponent,
    PersonalizedBookingBannerComponent,
    TagCardComponent,
    BookNowComponent,
    FeaturedExperience,
    FeaturedLocationComponent,
    SwiperViewerItemDirective,
    SwiperViewerPlaceholderDirective,
    FeaturedShopPlaceholderComponent,
    GoogleMapShopsComponent,
  ],
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly HOME_PAGE_COMPONENT = HOME_PAGE_COMPONENT;

  slidesPerView = 3;

  featuredLocations = featuredLocations;

  tags!: Tag[];
  shop: Shop = {
    googlePlaceId: 'ChIJ989F05WNGGARjEHx_3a2mu8',
  };

  showMap = false;
  takeMeExpressShops!: FeaturedShop[];
  budgetShops!: FeaturedShop[];
  michelinShops!: FeaturedShop[];
  recommendedShops!: FeaturedShop[];

  shops!: FeaturedShop[];
  destroyEvent$ = new Subject<void>();
  shopList!: RowResponse<Shop>;
  hasSeenVideo = localStorage.getItem('hasSeenIntroVideo');

  constructor(
    private shopService: ShopListApiService,
    private homePageService: HomePageService,
    public meService: MeService,
    private translocoService: TranslocoService,
    public searchService: SearchService,
    private seoService: SeoService,
    public bookNow: BookNowService,
    public referrerService: ReferralService,
    public membership: MembershipService,
    private shopListService: ShopListApiService,
    private customModal: CustomModal,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.translocoService
      .selectTranslate('seo.homePage.title')
      .subscribe((translatedTitle) => {
        this.seoService.updateTitle(translatedTitle);
      });

    this.translocoService
      .selectTranslate('seo.homePage.description')
      .subscribe((translatedDescription) => {
        this.seoService.updateDescription(translatedDescription);
      });
    this.homePageService
      .getRecommended()
      .subscribe((s) => (this.recommendedShops = s));
    this.homePageService
      .getBudgetShop()
      .subscribe((s) => (this.budgetShops = s));
    this.homePageService
      .getMichelin()
      .subscribe((s) => (this.michelinShops = s));
    this.homePageService
      .getTakeMeExpressShops()
      .subscribe((s) => (this.takeMeExpressShops = s));
    this.fetchTags().subscribe((s) => (this.tags = s));

    const params: ShopListParams = {
      limit: DEFAULT_PAGE_SIZE,
      offset: 0,
      addressState: [
        this.referrerService.getConciergeReferrer()?.addressState.toString(),
      ],
    };

    firstValueFrom(this.shopListService.getShopList(params)).then(
      (rowResponse) => {
        this.shopList = rowResponse;
        if (this.shopList.count !== 0) this.showMap = true;
      },
    );
  }

  ngAfterViewInit(): void {
    if (!this.hasSeenVideo) {
      localStorage.setItem('hasSeenIntroVideo', 'true');
      setTimeout(() => {
        this.openVideo(true);
      }, 3500);
    }
  }

  ngOnDestroy(): void {
    this.destroyEvent$.next();
    this.destroyEvent$.complete();
  }

  fetchTags(): Observable<Tag[]> {
    const FETCH_TAG_ENUMS = ['YAKINIKU', 'SUSHI', 'SEAFOOD'];

    const referrer = this.referrerService.getConciergeReferrer();
    if (referrer?.addressState === ADDRESS_STATE[AddressState.osaka].jap) {
      FETCH_TAG_ENUMS.push('TEPPANYAKI / STEAK');
      this.slidesPerView = 4;
    }

    const params: ShopListTagRequest = { tagEnums: FETCH_TAG_ENUMS };

    return this.shopService.getShopTags(params);
  }

  seeMoreTakeMeExpress(): void {
    this.searchService.resetFilters();
    return this.searchService.filterNav(
      this.searchService.filterForm.controls.requestSameDay,
      true,
    );
  }

  seeMoreTags() {
    this.searchService.resetFilters();
    return this.searchService.submitAndNavToList();
  }

  seeMoreMichelin() {
    this.searchService.resetFilters();
    return this.searchService.filterNav(
      this.searchService.filterForm.controls.requestMichelin,
      true,
    );
  }

  seeMoreBudgetRes() {
    this.searchService.resetFilters();
    return this.searchService.filterNav(
      this.searchService.filterForm.controls.sortBy,
      SortByOption.PRICE_ASC,
    );
  }

  seeMoreRecommendation() {
    this.searchService.resetFilters();
    return this.searchService.filterNav(
      this.searchService.filterForm.controls.sortBy,
      SortByOption.RECOMMENDED,
    );
  }

  openVideo(showPlaybutton: boolean = false) {
    const modalRef = this.customModal.open(
      VideoComponent,
      {
        centered: true,
      },
      {
        closeOnNavigate: true,
      },
    );
    modalRef.componentInstance.showPlaybutton = showPlaybutton;
  }
}
