import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleMapApiService} from 'src/app/shared/googlemap/GoogleMapApiService';
import {HotelShopDistance} from 'src/app/shared/googlemap/model/HotelShopDistance';
import {BookNowService} from 'src/app/shared/service/book-now/book-now.service';
import {NavigationService} from 'src/app/shared/service/nav/navigation.service';
import {BannerType, ImageBannerComponent} from 'src/app/shared/widgets/image-banner/image-banner.component';
import {ImageSize} from 'src/app/shared/widgets/image-viewer/image-viewer-options';
import {FeaturedShop} from '../home-page/models/featured-shop';
import {CdnPipe} from "../../shared/pipe/cdn.pipe";
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {HeartComponent} from "../../shared/widgets/heart/heart.component";
import {TranslocoModule} from "@ngneat/transloco";
import {RatingDecimalPipe} from "../../shared/utils/rating-decimal-pipe";
import {DistanceLabelComponent} from "../../shared/distance-label/distance-label.component";
import {CurrencyConverterPipe} from "../../shared/currency/currency-converter.pipe";
import {noop} from "rxjs";

@Component({
  selector: 'app-featured-shop',
  templateUrl: './featured-shop.component.html',
  standalone: true,
  imports: [
    CdnPipe,
    NgIf,
    ImageBannerComponent,
    HeartComponent,
    TranslocoModule,
    NgClass,
    RatingDecimalPipe,
    DistanceLabelComponent,
    CurrencyConverterPipe,
    AsyncPipe,
    NgForOf
  ]
})
export class FeaturedShopComponent implements OnInit {
  @Input() shop!: FeaturedShop;

  // Display a banner at the top left to the shop card
  bannerType: BannerType = BannerType.None;

  // Todo replace by saving rating on shop DB
  rating!: number;
  ImageSize = ImageSize;

  hotelShopDistance!: HotelShopDistance;

  constructor(
    private googleMapApiService: GoogleMapApiService,
    private navService: NavigationService,
    private router: Router,
    public bookNowService: BookNowService,
  ) {
  }

  ngOnInit(): void {
    this.refreshGooglePlaceRating();
    this.refreshBannerType();
    this.refreshHotelShopDistance();
  }

  refreshBannerType() {
    if (this.shop.tags?.some((tag) => tag.toLowerCase() === 'michelin')) {
      this.bannerType = BannerType.Michelin;
    } else if (this.rating && this.rating >= 4.2) {
      this.bannerType = BannerType.TopRated;
    }
  }

  refreshGooglePlaceRating() {
    let placeId = this.shop.googlePlaceId;
    if (!placeId) return;

    this.googleMapApiService.getPlaceDetails(placeId).subscribe((result) => {
      if (result) {
        this.rating = result.rating;
        this.refreshBannerType();
      }
    });
  }

  openShopView() {
    this.router.navigateByUrl(`/app/shop/${this.shop.number}`).then(noop);
  }

  refreshHotelShopDistance() {
    this.googleMapApiService
      .getHotelShopDistance(this.shop.id!)
      .subscribe((result) => {
        if (result) {
          this.hotelShopDistance = result;
        }
      });
  }
}


@Component({
  selector: 'app-featured-shop-placeholder',
  template: `
    <div class="placeholder-glow relative flex w-full h-full flex-col" aria-hidden="true">
      <!-- Card top -->
      <div class="placeholder overflow-hidden rounded-xl h-[100px] md:h-[130px] lg:h-[170px] xl:h-[200px]">

      </div>

      <!-- Card bottom -->
      <div class="flex flex-1 flex-col justify-between py-1.5 md:py-3 px-1.5">

        <div>
          <!-- Shop Name -->
          <p class="text-sm md:text-base font-medium leading-snug tracking-normal mb-0.5">
            <span class="placeholder w-75"></span>
          </p>

          <!-- Shop tags -->
          <p class="text-xs md:text-sm leading-relaxed mb-1">
            <span class="placeholder w-25"></span>
            <span class="placeholder w-25 ms-2"></span>
          </p>
        </div>

        <div class="align-end mt-12">
          <!-- Price and rating -->
          <div class="gap-2">
            <span class="placeholder bg-red-600 w-10 text-sm me-2"></span>
            <br>
            <span class="placeholder w-25 text-sm"></span>
          </div>
        </div>


      </div>
    </div>
  `,
  standalone: true
})
export class FeaturedShopPlaceholderComponent {
}
