<app-drop-button
    class="flex-shrink-0"
    [label]="'filter.cuisine' | transloco"
    [labelState]="labelState"
    [isDropDown]="true"
    [dropdownName]="'filterCuisine'"
    [control]="searchService.filterForm.controls.tagEnums"
    [activeState]="stateChanged"
    [filterCount]="filterService.filteredCount"
    (submitEvent)="onSubmit()">
      <span class="left-image">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path [attr.fill]="stateChanged ? '#DF0629': 'black'"
                d="M4.08325 12.8333V7.49579C3.58742 7.35968 3.17179 7.08746 2.83638 6.67913C2.50096 6.27079 2.33325 5.7944 2.33325 5.24996V1.16663H3.49992V5.24996H4.08325V1.16663H5.24992V5.24996H5.83325V1.16663H6.99992V5.24996C6.99992 5.7944 6.83221 6.27079 6.49679 6.67913C6.16138 7.08746 5.74575 7.35968 5.24992 7.49579V12.8333H4.08325ZM9.91659 12.8333V8.16663H8.16659V4.08329C8.16659 3.27635 8.45096 2.5885 9.01971 2.01975C9.58846 1.451 10.2763 1.16663 11.0833 1.16663V12.8333H9.91659Z"
          />
        </svg>
      </span>

  <span class="dropdown-content">
        <app-tag-selector [formControl]="searchService.filterForm.controls.tagEnums"></app-tag-selector>
      </span>
</app-drop-button>
