import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BackdropService {

  private isVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);


  get visible(): boolean {
    return this.isVisible.value;
  }

  set visible(value: boolean) {
    this.isVisible.next(value);
  }


  constructor() { }
}
