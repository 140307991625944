<div class="bg-cross-fade aspect-video pb-10">
  <!-- Hero -->
  <ng-container *ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.HEADER); else defaultHome">
    <ng-container *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.HEADER)"></ng-container>
  </ng-container>

  <ng-template #defaultHome>
    <section
      class="relative h-[150px] md:h-[275px] bg-cover bg-bottom">
      <img class="absolute inset-0 w-full h-full object-cover object-bottom"
           srcset="https://app.takeme.com/imgs/LP_Desktop.webp 1440w,
           https://app.takeme.com/imgs/LP_Desktop_m.webp 750w,
           https://app.takeme.com/imgs/LP_Desktop_l.webp 980w,
           https://app.takeme.com/imgs/LP_Desktop.jpg 1440w,
           https://app.takeme.com/imgs/LP_Desktop_m.jpg 750w,
           https://app.takeme.com/imgs/LP_Desktop_l.jpg 980w"
           sizes="100vw" src="https://app.takeme.com/imgs/LP_Desktop_l.jpg"
           alt="TakeMe: Book Japan’s Top-Rated Restaurants" aria-hidden="true"/>
      <div class="absolute inset-0 bg-black/40"></div>
      <!-- Adds a semi-transparent overlay -->
      <div class="absolute margin-auto inset-0 flex items-center justify-center">
        <div class="w-4/5 md:w-3/5 xl:w-2/5">
          <!-- Hero content, e.g., title, subtitle, and search bar -->
          <h1
            class="min-h-7 md:min-h-9 text-center text-wrap antialiased text-xl font-bold leading-none text-white mt-2 mb-2 md:text-3xl mx-2 flex justify-center sm:text-center whitespace-nowrap">
            {{ "homePage.authenticJapaneseCuisine" | transloco }}
          </h1>
          <app-search-bar-interceptor [isHomePage]="true"></app-search-bar-interceptor>
          <div class="flex  text-sm justify-center">
            <button #playIntroBtn title="tmc video" type="button" class="btn btn-sm text-sm sm:text-lg text-white btn-outline-none underline" (click)="openVideo()">
              <i class="bi bi-play-circle-fill"></i> {{ "homePage.watchVideo" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </section>
    @if (showMap) {
      <section
        class="relative h-[250px] md:h-[275px] bg-cover bg-bottom" *ngIf="shopList">
        <div class="absolute inset-0 bg-black/40"></div>
        <!-- Adds a semi-transparent overlay -->
        <div class="absolute margin-auto inset-0 flex items-center justify-center">
          <div class="w-full h-full">
            <app-google-map-shops [dataList]="shopList.rows" [homePage]="true" class="h-full">
            </app-google-map-shops>
          </div>
        </div>
      </section>
    }
  </ng-template>
  <!-- End of Hero -->

  <section class="relative bg-cover bg-bottom bg-tm-grey" id="personalized-booking">
    <app-personalized-booking></app-personalized-booking>
  </section>

  <div class="mx-auto p-4 w-full md:w-[80vw]">
    <!-- Recommended restaurants -->
    <ng-container *ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.RECOMMENED); else defaultRecommend">
      <ng-container
        *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.RECOMMENED)"></ng-container>
    </ng-container>

    <ng-template #defaultRecommend>
      <section class="mb-0">
        <app-swiper-viewer
          [cardData]="recommendedShops"
          [title]="'homePage.recommendedRestaurants' | transloco"
          [slidesPerView]="4"
          [seeMoreFn]="seeMoreRecommendation">
          <ng-template let-data appSwiperViewerItem>
            <div class="flex-shrink-0 w-[140px] sm:w-full h-full min-h-[150px]">
              <app-featured-shop [shop]="data"/>
            </div>
          </ng-template>
          <ng-template appSwiperViewerPlaceholder>
            <div class="flex-shrink-0 w-[140px] sm:w-full h-full min-h-[150px]">
              <app-featured-shop-placeholder/>
            </div>
          </ng-template>
        </app-swiper-viewer>
      </section>
    </ng-template>


    @if (takeMeExpressShops && takeMeExpressShops.length > 0) {
      <hr class="border-t border-gray-300 mt-6 mb-4 w-full"/>

      <!-- Instant booking ex: takeme express -->

      <ng-container *ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.INSTANT); else defaultInstant">
        <ng-container
          *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.INSTANT)"></ng-container>
      </ng-container>

      <ng-template #defaultInstant>
        <section id="instant-booking">
          <!-- Takeme Express restaurants -->
          <app-swiper-viewer
            [cardData]="takeMeExpressShops"
            type="takemeToday"
            [titleTemplate]="titleTemplate"
            [slidesPerView]="4">
            <ng-template let-data>
              <div class="flex-shrink-0 w-[140px] sm:w-full h-full min-h-[150px]">
                <app-featured-shop [shop]="data"/>
              </div>
            </ng-template>
          </app-swiper-viewer>
          <ng-template #titleTemplate>
            <app-book-now [shop]="takeMeExpressShops.at(0) || shop" [withTitle]="true" [home]="true"></app-book-now>
          </ng-template>
        </section>
      </ng-template>
    }

    <hr class="border-t border-gray-300 my-4 w-full"/>


    <!-- By location-->
    <ng-container *ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.LOCATION); else defaultLocation">
      <ng-container
        *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.LOCATION)"></ng-container>
    </ng-container>

    <hr class="border-t border-gray-300 my-4 w-full"/>


    <ng-template #defaultLocation>
      <section class="mb-6" id="location">
        <app-swiper-viewer
          [cardData]="featuredLocations"
          [title]="'homePage.searchByDestination' | transloco"
          [disableNavigation]="true"
          [disableSwiper]="true">
          <ng-template let-data let-i="index">
            <div [class.md:ml-0]="i === 0" [class.md:mr-0]="i === featuredLocations.length - 1"
              class="flex-shrink-0 w-[80px] md:w-[180px] h-[100px] md:h-[256px] mx-0 md:mx-2 hover:cursor-pointer hover:opacity-75">
              <app-featured-location [data]="data"></app-featured-location>
            </div>
          </ng-template>
        </app-swiper-viewer>
      </section>
    </ng-template>
    <!-- End of by location -->

    <hr class="border-t border-gray-300 my-4 w-full"/>


    @if (tags) {
      <hr class="border-t border-gray-300 my-4 w-full"/>

      <!-- Categories -->
      <ng-container
        *ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.TAG_CATEGORIES); else defaultTagCategories">
        <ng-container
          *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.TAG_CATEGORIES)"></ng-container>
      </ng-container>

      <ng-template #defaultTagCategories>
        <section class="mb-0" *ngIf="tags" id="categories">
          <app-swiper-viewer
            [cardData]="tags.slice(0, slidesPerView)"
            [title]="'homePage.findYourCraving' | transloco"
            [disableNavigation]="true"
            [slidesPerView]="slidesPerView"
            [seeMoreFn]="seeMoreTags">
            <ng-template let-data>
              <app-tag-card [tag]="data" (click)="searchService.filterTagNav(data.tagEnum)"></app-tag-card>
            </ng-template>
          </app-swiper-viewer>
        </section>
      </ng-template>
      <!-- End of Categories -->
    }

    <hr class="border-t border-gray-300 mt-6 mb-4 w-full"/>

    <!-- Michelin restaurants -->
    <ng-container *ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.MICHELIN); else defaultMichelin">
      <ng-container
        *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.MICHELIN)"></ng-container>
    </ng-container>

    <ng-template #defaultMichelin>
      <section class="mb-0">
        <app-swiper-viewer
          [cardData]="michelinShops"
          [title]="'homePage.michelinRestaurants' | transloco"
          [slidesPerView]="4"
          [seeMoreFn]="seeMoreMichelin">
          <ng-template let-data>
            <div class="flex-shrink-0 w-[140px] sm:w-full h-full min-h-[150px]">
              <app-featured-shop [shop]="data"/>
            </div>
          </ng-template>
          <ng-template appSwiperViewerPlaceholder>
            <div class="flex-shrink-0 w-[140px] sm:w-full h-full min-h-[150px]">
              <app-featured-shop-placeholder/>
            </div>
          </ng-template>
        </app-swiper-viewer>
      </section>
    </ng-template>
  </div>
</div>
<app-personalized-booking-banner></app-personalized-booking-banner>

<ng-container *ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.FOOTER)">
  <ng-container *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.FOOTER)"></ng-container>
</ng-container>



<!-- Experiences -->
<!-- <ng-container
*ngIf="membership.referrerHasCustomComponent(HOME_PAGE_COMPONENT.EXPERIENCES); else defaultExperience">
<ng-container
  *ngComponentOutlet="membership.getReferrerCustomComponent(HOME_PAGE_COMPONENT.EXPERIENCES)"></ng-container>
</ng-container>

<ng-template #defaultExperience>
<section class="mb-2" id="experience">
  <app-swiper-viewer
    [cardData]="[1]"
    [title]="'homePage.unforgettableExperiences' | transloco"
    [slidesPerView]="1"
    [disableNavigation]="true">
    <ng-template let-data>
      <div class="flex w-[100%] justify-between gap-4 overflow-x-auto no-scrollbar">
        <div class="w-[75vw] md:w-[49%] h-[130px] md:h-[220px] flex-shrink-0">
          <app-featured-experience
            [title]="'homePage.featureShopTitle1' | transloco"
            [description]="'homePage.featureShopDesc1' | transloco"
            [imageUrl]="'https://app.takeme.com/imgs/Experience_Yakatabune.jpg'"
            [shopNumber]="'SR10003'"/>
        </div>
        <div class="w-[75vw] md:w-[49%] h-[130px] md:h-[220px] flex-shrink-0">
          <app-featured-experience
            [title]="'homePage.featureShopTitle2' | transloco"
            [description]="'homePage.featureShopDesc2' | transloco"
            [imageUrl]="'https://app.takeme.com/imgs/Experience_KobeBeef.jpg'"
            [shopNumber]="'SR10096'"/>
        </div>
      </div>
    </ng-template>
  </app-swiper-viewer>
</section>
</ng-template> -->
