<div class="hidden items-center justify-between sm:justify-center gap-4 sm:gap-10 px-4 py-2 sm:text-center">
    <div class="flex sm:gap-2 flex-row flex-wrap justify-start items-baseline">
        <div class="text-sm sm:text-inherit sm:font-medium">{{'personalizedBooking.title' | transloco}}</div>
        <div class="text-sm sm:text-inherit sm:font-medium">{{'personalizedBooking.subTitle' | transloco}}</div>
    </div>
    <div class="min-w-max sm:w-48">
        <button class="btn btn-xl bg-tm-red btn-danger min-w-16 flex gap-2 sm:px-[80px] sm:py-[8px]" (click)="openBookingModal()" id="personalizedGetStartedButton">
            <i class="bi bi-envelope text-xs sm:text-sm"></i>
            <span class="text-xs sm:text-sm">{{'personalizedBooking.getStarted' | transloco }}</span>
        </button>
    </div>
</div>

