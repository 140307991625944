import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { noop, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from "../../shared/service/toast/toast.service";
import { ToastType } from "../../shared/widgets/toast/models/toast-type";

export type HandleableError = { handled?: boolean };

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private toastService: ToastService,
                private router: Router) {
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 503) {
                  setTimeout(() => this.showServerMaintenanceMessage(error), 10);
                }
                throw error;
            })
        );
    }

    private showServerMaintenanceMessage(error: HttpErrorResponse & HandleableError): void {
        if (error.handled) {
            return;
        }

        const errorHeader = `${error.status} Server Error`;
        const errorMessage = `The server is currently undergoing maintenance. Please try again later.`;

        this.toastService.show(errorHeader, errorMessage, ToastType.error);
    }
}
