import { Location } from '@angular/common';
import { inject } from '@angular/core';

export abstract class NavDismissNgb<
  NgbClass extends NgbModalOpenableClass<NgbModalRef>,
  NgbModalRef extends NgbModalRefClass,
  NgbClassOptions,
> {
  constructor(protected modalClass: NgbClass) {}

  protected location = inject(Location);
  protected modalRef: NgbModalRef | null = null;

  open(
    content: any,
    options?: NgbClassOptions,
    customOptions?: CustomNgbOptions,
  ): NgbModalRef {
    this.modalRef = this.modalClass.open(content, options);

    if (customOptions?.closeOnNavigate) {
      let popPseudoState = true;
      // Assuming you want to add an anchor as well
      const newUrl =
        window.location.pathname + window.location.search + '#modal';

      // Push a fake state to the browser's history to prevent back navigation
      const modalState = {
        modal: true,
        desc: 'Pseudo state for NgbModal',
      };

      history.pushState(modalState, '', newUrl);

      // Subscribe to the location change event to detect back/forward navigation
      const subscription = this.location.subscribe(() => {
        //as the back button already poped the fake state.
        popPseudoState = false;
        this.modalRef?.dismiss();
      });

      this.modalRef.result.finally(() => {
        // Remove the fake state from the browser's history when the modal is closed/dismissed
        if (popPseudoState) {
          history.back();
        }
        // Unsubscribe from the location change event
        subscription.unsubscribe();
      });
    }
    return this.modalRef;
  }
}

interface NgbModalOpenableClass<NgbModalRef> {
  open(content: unknown, options?: unknown): NgbModalRef;
}

interface NgbModalRefClass {
  dismiss(result?: boolean): void;

  result: Promise<any>;
}

interface CustomNgbOptions {
  /**
   * If `true`, closing the modal when pressing the back button from the browser/mobile.
   */
  closeOnNavigate?: boolean;
}
