import { EventEmitter, Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { environment } from "../../../../environments/environment";
import { DateTime } from "luxon";

@Injectable({
  providedIn: 'root',
})
export class InternalCookieService {
  constructor(private ngxCookieService: NgxCookieService) {
  }

  private LANG_COOKIE_NAME = '_tm_lang';
  private REFERRER_COOKIE_NAME = '_tm_referrer';
  MEMBERSHIP_BOOKING_COOKIE_PARAM = "_tm_mbook_id";

  private DEFAULT_COOKIE_EXPIRATION_DAYS = 30;

  referrerChangedEvent$ = new EventEmitter<string>();
  langChangedEvent$ = new EventEmitter<string>();

  public getNewExpirationDate(): Date {
    return DateTime.now().plus({days: this.DEFAULT_COOKIE_EXPIRATION_DAYS}).toJSDate();
  }


  public setActiveLang(lang: string = "en"): void {
    if (this.cookieValueExists(this.LANG_COOKIE_NAME, lang)) {
      return;
    }

    this.setCookie(this.LANG_COOKIE_NAME, lang)
    this.langChangedEvent$.emit(lang);
  }

  public getActiveLang(): string {
    return this.ngxCookieService.get(this.LANG_COOKIE_NAME);
  }

  public setReferrer(referrer: string): void {
    if (this.cookieValueExists(this.REFERRER_COOKIE_NAME, referrer)) {
      return;
    }

    this.setCookie(this.REFERRER_COOKIE_NAME, referrer)
    this.referrerChangedEvent$.emit(referrer);
  }

  /**
   * Prefer using getReferrerSlug from ReferralService instead and use this only when necessary to establish 'higher' source of truth.
   * This is to prevent inconsistencies between the cookie and the service.
   * Currently, the source of truth priority is URL > Cookie > Service
   * @private
   */
  private getReferrer(): string {
    return this.ngxCookieService.get(this.REFERRER_COOKIE_NAME);
  }

  public deleteReferrer(): void {
    this.ngxCookieService.delete(this.REFERRER_COOKIE_NAME);
    this.ngxCookieService.delete(this.MEMBERSHIP_BOOKING_COOKIE_PARAM);
  }

  public getReferrerCookieName(): string {
    return this.REFERRER_COOKIE_NAME;
  }

  public deleteCookieExceptReferrer(): void {
    let referrer = this.getReferrer();
    this.ngxCookieService.deleteAll();
    this.setReferrer(referrer);
  }

  public cookieValueExists(cookieName: string, cookieValue: string): boolean {
    const cookie = this.ngxCookieService.get(cookieName);
    return !!cookie && cookie === cookieValue;
  }

  public setCookie(cookieName: string, cookieValue: string): void {
    this.ngxCookieService.set(cookieName, cookieValue, this.getNewExpirationDate(), '/', environment.cookieDomain, true, 'None');
  }

}
