import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export class ConciergeUserService {

  constructor(private apiService: ApiService) {
  }

  postUpdateUserLanguagePreference(lang: string): Observable<string> {
    if (lang === "zh-CN") {
      lang = 'cn' //due to legacy code
    }

    if (lang === "zh-TW") {
      lang = 'tw' //due to legacy code
    }

    return this.apiService.postJson<string>('v3/concierge-users/set-lang', null, {
      langCode: lang,
    });
  }

}
