import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';

import {NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbOffcanvas, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {
  debounceTime,
  firstValueFrom,
  Observable,
  OperatorFunction,
  switchMap,
} from 'rxjs';
import { HomePageService } from '../../../concierge-app/home-page/home-page-api.service';
import { FeaturedShop } from '../../../concierge-app/home-page/models/featured-shop';
import {
  ADDRESS_STATE,
  getAddressStateFromString,
  LIST_FILTER,
} from '../../../concierge-app/shop-list-view/models/shop-list-const';
import { AnalyticsEventCategory, sendEvent } from '../../analytics/analytics';
import { ApiService } from '../../service/api/api.service';
import { DeviceService } from '../../service/device/device.service';
import { NavigationService } from '../../service/nav/navigation.service';
import { SearchService } from '../../service/search/search.service';
import { InboundTypeahead } from './model/InboundTypeahead';
import {ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";
import {SearchSuggestComponent} from "./search-suggest/search-suggest.component";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgbTypeahead,
    NgbDropdownToggle,
    NgClass,
    TranslocoModule,
    NgIf,
    NgbDropdown,
    SearchSuggestComponent,
    NgbDropdownMenu
  ]
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  @Input() canvasView: boolean = false;

  closeOffcanvas() {
    this.activeCanvas.dismiss();
  }

  showBackDrop: boolean = false;
  showDropdown: boolean = false;
  @ViewChild('searchDropDown', { static: true }) searchDropDown!: NgbDropdown;

  recommendedRestaurants!: FeaturedShop[];

  constructor(
    public searchService: SearchService,
    public navService: NavigationService,
    private apiService: ApiService,
    private router: Router,
    public device: DeviceService,
    private homePageService: HomePageService,
    private activeCanvas: NgbOffcanvas,
  ) {}

  ngOnInit() {
    firstValueFrom(this.homePageService.getRecommended()).then((result) => {
      this.recommendedRestaurants = result;
    });
  }

  ngAfterViewInit(): void {
    //auto open the dialog when in mobile
    if (this.device.isMobile || this.canvasView) {
      this.showSearchSuggest = true;
      this.searchDropDown?.open();
    }
  }

  @Input() isHomePage = false;
  @Output() submitEvent = new EventEmitter<void>();

  search: OperatorFunction<string, InboundTypeahead[]> = (
    text$: Observable<string>,
  ) => {
    return text$.pipe(
      debounceTime(150),
      switchMap((term) => {
        if (term === '' || (term.length < 2 && !this.containsKanji(term))) {
          this.showSearchSuggest = true;
          return [];
        } else {
          this.showSearchSuggest = false;
          return this.getTypeaheads(term);
        }
      }),
    );
  };
  showSearchSuggest = true;

  onSelect(ahead: InboundTypeahead): void {
    if (ahead.type === 'PrefectureInboundSearchTypeahead') {
      console.log(
        ahead.prefecture,
        getAddressStateFromString(ahead.prefecture),
        ADDRESS_STATE[getAddressStateFromString(ahead.prefecture)].default,
      );
      setTimeout(() => {
        this.searchService.filterNav(
          this.searchService.filterForm.controls.addressState,
          ADDRESS_STATE[getAddressStateFromString(ahead.prefecture)].default,
        );
      }, 500);
      return;
    }
    if (ahead.type === 'ShopInboundSearchTypeahead') {
      const clickEvent = {
        shopName: ahead.nameEn,
        shopNumber: ahead.number,
        query: this.searchService.filterForm.controls.globalSearchArgs.value,
      };
      sendEvent(AnalyticsEventCategory.TYPEAHEAD_CLICK, clickEvent);
      this.router.navigate(['/app/shop', ahead.number]).toString();
    }

    if (ahead.type === 'QueryInboundSearchTypeahead') {
      this.searchService.filterForm.controls.globalSearchArgs.setValue(
        ahead.query,
      );
      this.searchService.submitEvent.emit(LIST_FILTER.SEARCH);
    }

    if (ahead.type === 'TagInboundSearchTypeahead') {
      this.searchService.filterForm.controls.tagEnums.setValue([ahead.tag]);
      this.searchService.submitEvent.emit(LIST_FILTER.SEARCH);
    }

    if (this.canvasView && ahead.type !== 'ShopInboundSearchTypeahead') {
      this.activeCanvas.dismiss('search');
      this.searchService.tempNavSearch();
    }
  }

  containsKanji(query: string): boolean {
    return /[^\u0000-\u007f]/.test(query);
  }

  public getTypeaheads(query: string): Observable<InboundTypeahead[]> {
    const url = '/api/v3/reservations/shops/typeahead?query=' + query;
    return this.apiService.get<InboundTypeahead[]>(url, null);
  }

  searchFormatter = (ahead: InboundTypeahead): string => {
    if (ahead.type === 'QueryInboundSearchTypeahead') {
      return ahead.query;
    }
    if (ahead.type === 'TagInboundSearchTypeahead') {
      return ahead.tag;
    }
    if (ahead.type === 'PrefectureInboundSearchTypeahead') {
      return ahead.prefecture;
    }
    return '';
  };
}
