import { ApiService } from '../service/api/api.service';
import { API_CONCIERGE } from '../shared-const';
import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { ExchangeRate } from "./exchange-rate";


@Injectable({
    providedIn: 'root'
  })
export class CurrencyCache {

  ACTIVE_CURRENCY_KEY = 'activeCurrency'

  rateCache = new Map<String, Promise<ExchangeRate>>()
  activeCurrency = "JPY"
  activeCurrency$ = new BehaviorSubject(this.activeCurrency)

  constructor(private apiService: ApiService) {
    let storedCurrency = localStorage.getItem(this.ACTIVE_CURRENCY_KEY);
    if (storedCurrency != null) {
      this.activeCurrency = storedCurrency;
      this.activeCurrency$.next(this.activeCurrency)
    }
  }

  setActiveCurrency(newCurrency: string) {
    this.activeCurrency = newCurrency;
    this.activeCurrency$.next(this.activeCurrency)
    localStorage.setItem(this.ACTIVE_CURRENCY_KEY, newCurrency);
  }


  // Get exchange rate info for a currency (based on JPY)
  getExchangeRate(currrency: string): Promise<ExchangeRate> {
    if (this.rateCache.has(currrency)) {
      return this.rateCache.get(currrency)!;
    }

    const url = API_CONCIERGE + `exchange-rate/${currrency.toLowerCase()}`;

    let promise : Promise<any> = lastValueFrom(this.apiService.get(url));
    this.rateCache.set(currrency, promise);

    return promise;
  }
}
