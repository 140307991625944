import {HttpClient} from '@angular/common/http';
import {Translation, TranslocoConfig, translocoConfig, TranslocoLoader} from '@ngneat/transloco';
import {Injectable, isDevMode, Type} from '@angular/core';
import {SupportedLanguage} from "./header/const/header-const";


@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const translocoOptions: { config: TranslocoConfig, loader: Type<TranslocoLoader> } = {
  config: translocoConfig({
    availableLangs: Object.keys(SupportedLanguage),
    defaultLang: Object.keys(SupportedLanguage)[0],
    fallbackLang: Object.keys(SupportedLanguage)[0],
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: !isDevMode(),
  }),
  loader: TranslocoHttpLoader
};
