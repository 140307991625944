<ng-container *ngIf="!device.isWebView; else navItems">
  <div class="offcanvas-header border-none p-2 px-4">
    <div class="flex justify-between items-start w-full border-b border-b-gray-200" [ngClass]="{'border-none': !meService.user}">
      <app-profile-viewer></app-profile-viewer>
      <button
        type="button"
        class="mt-2"
        title="close"
        (click)="offCanvas.dismiss('Cross click')"
      >
        <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3072_58575)">
          <path d="M31.707 8.29296C31.5195 8.10549 31.2652 8.00017 31 8.00017C30.7348 8.00017 30.4805 8.10549 30.293 8.29296L24 14.586L17.707 8.29296C17.6148 8.19745 17.5044 8.12127 17.3824 8.06886C17.2604 8.01645 17.1292 7.98886 16.9964 7.98771C16.8636 7.98655 16.7319 8.01186 16.609 8.06214C16.4861 8.11242 16.3745 8.18667 16.2806 8.28056C16.1867 8.37446 16.1125 8.48611 16.0622 8.60901C16.0119 8.7319 15.9866 8.86358 15.9877 8.99636C15.9889 9.12914 16.0165 9.26036 16.0689 9.38236C16.1213 9.50437 16.1975 9.61471 16.293 9.70696L22.586 16L16.293 22.293C16.1108 22.4816 16.01 22.7342 16.0123 22.9964C16.0146 23.2586 16.1198 23.5094 16.3052 23.6948C16.4906 23.8802 16.7414 23.9854 17.0036 23.9876C17.2658 23.9899 17.5184 23.8891 17.707 23.707L24 17.414L30.293 23.707C30.4816 23.8891 30.7342 23.9899 30.9964 23.9876C31.2586 23.9854 31.5094 23.8802 31.6948 23.6948C31.8802 23.5094 31.9854 23.2586 31.9877 22.9964C31.99 22.7342 31.8892 22.4816 31.707 22.293L25.414 16L31.707 9.70696C31.8945 9.51943 31.9998 9.26512 31.9998 8.99996C31.9998 8.73479 31.8945 8.48049 31.707 8.29296Z" fill="#212529"/>
          </g>
          <defs>
          <clipPath id="clip0_3072_58575">
          <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 32 8)"/>
          </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
  <div class="offcanvas-body px-2 py-0 nav">
    <ng-container *ngTemplateOutlet="navItems"> </ng-container>
  </div>
</ng-container>

<ng-template #navItems> <app-nav-item></app-nav-item></ng-template>
