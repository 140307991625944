<div [ngClass]="showHeader ? 'top-[54px]':'top-0'" class="sticky z-20">

  <div class="font-tm-search flex justify-center py-2 bg-white overflow-x-auto no-scrollbar sm:overflow-x-visible">
    <div
      [formGroup]="searchService.filterForm"
      class="flex w-full pl-4 md:pl-0 md:w-[80%] gap-2 flex-shrink-0 overflow-x-auto no-scrollbar">
      <app-sameday-filter #btnFilter class="flex-shrink-0"></app-sameday-filter>
      <app-price-range #btnFilter class="flex-shrink-0"></app-price-range>
      <app-cuisine-filter #btnFilter class="flex-shrink-0"></app-cuisine-filter>
      <app-michelin-filter #btnFilter class="flex-shrink-0"></app-michelin-filter>
      <app-area-filter #btnFilter class="flex-shrink-0"></app-area-filter>
  
      <!--Clear All Button-->
      <div class="self-center flex-shrink-0" *ngIf="searchService.defaultStateInitialized">
        <span
          class="text-tm-red hover:cursor-pointer hover:bg-tm-pink focus:bg-tm-pink focus:border-tm-red focus:shadow-tm-pink rounded-full py-1.5 px-3 hover:border-tm-red text-[14px]"
          *ngIf="showClearAllButton()"
          (click)="searchService.resetFilters()">
          {{ "filter.clearAll" | transloco }}
        </span>
      </div>
      <!--Clear All Button End-->
  
      <!--Sort By Button-->
      <span class="hidden md:block md:ml-auto flex-shrink-0">
        <ng-container [ngTemplateOutlet]="sortByDrop"></ng-container>
      </span>
      <!--Sort By Button End-->
    </div>
  </div>
  
  <!-- Mobile View -->
  <div class="bg-white justify-center flex" *ngIf="!filterService.mapView">
    <div class="flex w-[90%] md:w-[80%]">
      <!-- Map View Button Start-->
      <span class="flex lg:hidden">
        <app-base-button
          class="flex-shrink-0"
          (click)="filterService.toggleMapView()"
          [label]="'filter.mapView' | transloco"
          [dropdownName]="'mapView'"
          [hideBorder]="true"
          [isDropDown]="false">
          <span class="left-image">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 14L6 12.6L2.9 13.8C2.67778 13.8889 2.47222 13.8639 2.28333 13.725C2.09444 13.5861 2 13.4 2 13.1667V3.83333C2 3.68889 2.04167 3.56111 2.125 3.45C2.20833 3.33889 2.32222 3.25556 2.46667 3.2L6 2L10 3.4L13.1 2.2C13.3222 2.11111 13.5278 2.13611 13.7167 2.275C13.9056 2.41389 14 2.6 14 2.83333V12.1667C14 12.3111 13.9583 12.4389 13.875 12.55C13.7917 12.6611 13.6778 12.7444 13.5333 12.8L10 14ZM9.33333 12.3667V4.56667L6.66667 3.63333V11.4333L9.33333 12.3667ZM10.6667 12.3667L12.6667 11.7V3.8L10.6667 4.56667V12.3667ZM3.33333 12.2L5.33333 11.4333V3.63333L3.33333 4.3V12.2Z"
                fill="black" />
            </svg>
          </span>
        </app-base-button>
      </span>
      <!-- Mobile View Button End -->
  
      <span class="md:hidden flex ml-auto sm:ml-0">
        <ng-container
          *ngTemplateOutlet="
            sortByDrop;
            context: { hideBorder: true, showDropArrow: false, filterSortMobile: 'filterSortMobile' }
          "></ng-container>
      </span>
    </div>
  </div>
  <!-- Mobile View End -->
</div>

<ng-template
  #sortByDrop
  let-hideBorder="hideBorder"
  let-showDropArrow="showDropArrow"
  let-filterSortMobile="filterSortMobile">
  <app-sortby-filter #btnFilter
    [hideBorder]="hideBorder"
    [showDropArrow]="showDropArrow"
    [filterSortMobile]="filterSortMobile"></app-sortby-filter>
</ng-template>
