import { DateTime } from "luxon";

export enum FormPage {
    BOOKING = 'booking',
    LOGIN = 'login',
    PAYMENT = 'payment',
    RESULT = 'result',
}

export enum FormStatus {
  YES = "1",
  NO = "0",
}

export enum FormAction {
  FOCUS = "FOCUS",
  INPUT = "INPUT",
  SUBMIT = "SUBMIT",
}

export interface FormDataEvent {
    [key: string]: unknown;
    page: FormPage;
    formName: string;
    action: FormAction;
    time?: string | null;
    value?: FormStatus | null;
}

export interface DataEvent {
  [key: string]: unknown;
  page: FormPage;
  resId: string;
  shopNumber: string;
  duration: number;
}

