<app-drop-button #sortByDropButton
                 [label]="('filter.sortBy' | transloco) + ': '"
                 [labelClass]="'font-normal'"
                 [labelSecondary]="labelState | transloco"
                 [labelSecondaryClass]="'font-medium'"
                 [isDropDown]="true"
                 [hideSubmit]="true"
                 [widthClass]="filterSortMobile ? 'sm:w-80 -mt-[9px]': 'sm:w-80'"
                 [dropdownName]="filterSortMobile ? filterSortMobile: 'filterSortBy'"
                 [activeState]="stateChanged"
                 [hideBorder]="hideBorder"
                 [shopDropArrow]="showDropArrow"
                 [control]="searchService.filterForm.controls.sortBy"
                 [filterCount]="filterService.filteredCount"
                 (submitEvent)="onSubmit()">
    <span class="left-image">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path [attr.fill]="stateChanged ? '#DF0629': 'black'"
              d="M4.66671 7.58329V3.39788L3.16462 4.89996L2.33337 4.08329L5.25004 1.16663L8.16671 4.08329L7.33546 4.89996L5.83337 3.39788V7.58329H4.66671ZM8.75004 12.8333L5.83337 9.91663L6.66462 9.09996L8.16671 10.602V6.41663H9.33337V10.602L10.8355 9.09996L11.6667 9.91663L8.75004 12.8333Z"
        />
      </svg>
    </span>

  <span class="dropdown-content">
      <ul class="p-0">
        <ng-container *ngFor="let sortOption of SORT_BY_OPTIONS | keyvalue">
          <li
              class="block px-1 py-2 hover:bg-tm-pink hover:text-tm-red hover:cursor-pointer"
              (click)="
              searchService.filterForm.controls.sortBy.patchValue(sortOption.key);
              searchService.filterForm.controls.sortBy.markAsDirty();
              sortByDropButton.onSubmitClick();
            "
              [ngClass]="{
              'bg-tm-pink text-tm-red': searchService.filterForm.controls.sortBy.value === sortOption.key
            }">
            {{ sortOption.value.transLabel | transloco }}
          </li>
        </ng-container>
      </ul>
    </span>
</app-drop-button>
