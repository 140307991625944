export enum ReservationStatus {
  PENDING = 0,
  CREATED = 1,
  CHECKING_NOW = 5,
  SUCCESS = 10,
  CONFIRMED_OK = 15,
  CONFIRMED_NG = 20,
  REQUEST_RESCHEDULE = 25,
  FAILED = 30,
  FAILED_FULL_PENALTY = 31,
  FAILED_HALF_PENALTY = 32,
  CANCELLED = 35,
  CHANGED = 40,
  TABLE_CHECK_PENDING = 100,
  EBICA_PENDING = 200, // reservation created on Ebica but not confirmed
  EBICA_NOT_SYNCED = 205, // reservation not created on Ebica
  EBICA_CONFIRMED = 210, // reservation created on Ebica successfully
  FAILED_EBICA_TO_CANCEL = 215, // reservation cancelled with refund, but Ebica cancellation failed
}

//Note can also use bootstrap classes such as "badge badge-info"
export const RESERVATION_STATUS: Record<number, { label: string; class: string }> = {
  [ReservationStatus.PENDING]: {
    label: 'reservationStatus.pending',
    class: 'badge pending',
  },
  [ReservationStatus.CREATED]: {
    label: 'reservationStatus.created',
    class: 'badge pending',
  },
  [ReservationStatus.CHECKING_NOW]: {
    label: 'reservationStatus.checkingNow',
    class: 'badge reserving',
  },
  [ReservationStatus.SUCCESS]: {
    label: 'reservationStatus.confirmedOk',
    class: 'badge confirmed',
  },
  [ReservationStatus.CONFIRMED_OK]: {
    label: 'reservationStatus.confirmedOk',
    class: 'badge confirmed',
  },
  [ReservationStatus.CONFIRMED_NG]: {
    label: 'reservationStatus.confirmedNg',
    class: 'badge cancelled',
  },
  [ReservationStatus.REQUEST_RESCHEDULE]: {
    label: 'reservationStatus.confirmedReschedule',
    class: 'badge cancelled',
  },
  [ReservationStatus.FAILED]: {
    label: 'reservationStatus.failed',
    class: 'badge cancelled',
  },
  [ReservationStatus.FAILED_FULL_PENALTY]: {
    label: 'reservationStatus.failedFullPenalty',
    class: 'badge cancelled',
  },
  [ReservationStatus.FAILED_HALF_PENALTY]: {
    label: 'reservationStatus.failedHalfPenalty',
    class: 'badge cancelled',
  },
  [ReservationStatus.CANCELLED]: {
    label: 'reservationStatus.cancelled',
    class: 'badge cancelled',
  },
  [ReservationStatus.CHANGED]: {
    label: 'reservationStatus.changed',
    class: 'badge reserving',
  },
  [ReservationStatus.TABLE_CHECK_PENDING]: {
    label: 'reservationStatus.tableCheckPending',
    class: 'badge reserving',
  },
  [ReservationStatus.EBICA_PENDING]: {
    label: 'reservationStatus.conciergePending',
    class: 'badge reserving',
  },
  // cannot do instant reservation, TakeMe staff need manual intervention
  [ReservationStatus.EBICA_NOT_SYNCED]: {
    label: 'reservationStatus.pending',
    class: 'badge reserving',
  },

  [ReservationStatus.EBICA_CONFIRMED]: {
    label: 'reservationStatus.confirmedOk',
    class: 'badge confirmed',
  },
  [ReservationStatus.FAILED_EBICA_TO_CANCEL]: {
    label: 'reservationStatus.failedEbicaToCancel',
    class: 'badge pending',
  },
};

export enum PaymentStatus {
  OUTSTANDING = 0,
  PAID = 10,
  REFUNDED = 30,
  CANCELLED = 90,
}

export const PAYMENT_STATUS = {
  [PaymentStatus.OUTSTANDING]: {
    label: 'paymentStatus.pending',
    class: 'badge pending',
  },
  [PaymentStatus.PAID]: {
    label: 'paymentStatus.paid',
    class: 'badge confirmed',
  },
  [PaymentStatus.REFUNDED]: {
    label: 'paymentStatus.refunded',
    class: 'badge refunded',
  },
  [PaymentStatus.CANCELLED]: {
    label: 'paymentStatus.cancelled',
    class: 'badge cancelled',
  },
};


export const DAY_OF_WEEK: Record<string, string> = {
  "mon": "common.monday",
  "tue": "common.tuesday",
  "wed": "common.wednesday",
  "thu": "common.thursday",
  "fri": "common.friday",
  "sat": "common.saturday",
  "sun": "common.sunday",
};

export interface ResultConfig {
  statuses: ReservationStatus[]; // Grouped statuses
  image: string;
  titleKey: string;
  showCancellationAlert: boolean;
  showBookingNumber: boolean;
  background?: string;
  editInfo?: boolean;
  bgBanner?: string;
  bannerIcon?: string;
  bannerTitle?: string;
  descriptionKey?: string;
  noteKey?: string;
}

export const RESULT_CONFIGS: ResultConfig[] = [
  {
    statuses: [ReservationStatus.CREATED, ReservationStatus.FAILED],
    image: 'cross.svg',
    titleKey: 'resultPage.failedTitle',
    descriptionKey: 'resultPage.failedDescription',
    noteKey: 'resultPage.failedNote',
    showCancellationAlert: false,
    showBookingNumber: false,
    bgBanner: 'bg-sky-50',
    bannerTitle: 'resultPage.failedBannerTitle',
    bannerIcon: 'note.svg',
    background:
      'bg-[linear-gradient(180deg,#793298_15%,_#f3f4f6_15%)] lg:bg-[linear-gradient(180deg,#793298_20%,_#f3f4f6_20%)]',
  },
  {
    statuses: [
      ReservationStatus.EBICA_CONFIRMED,
      ReservationStatus.CONFIRMED_OK,
      ReservationStatus.SUCCESS,
      ReservationStatus.FAILED_EBICA_TO_CANCEL,
    ],
    image: 'check.svg',
    titleKey: 'resultPage.successTitle',
    descriptionKey: 'resultPage.successNote',
    editInfo: true,
    showCancellationAlert: true,
    showBookingNumber: true,
    bgBanner: 'bg-sky-50',
    bannerTitle: 'resultPage.successBannerTitle',
    bannerIcon: 'note.svg',
    background:
      'bg-[linear-gradient(180deg,#198754_15%,_#f3f4f6_15%)] lg:bg-[linear-gradient(180deg,#198754_20%,_#f3f4f6_20%)]',
  },
  {
    statuses: [
      ReservationStatus.EBICA_NOT_SYNCED,
      ReservationStatus.EBICA_PENDING,
      ReservationStatus.PENDING,
    ],
    image: 'next.svg',
    titleKey: 'resultPage.reservingTitle',
    descriptionKey: 'resultPage.reservingNote',
    showCancellationAlert: true,
    showBookingNumber: true,
    editInfo: true,
    bgBanner: 'bg-sky-50',
    bannerIcon: 'note.svg',
    bannerTitle: 'resultPage.reservingBannerTitle',
    background:
      'bg-[linear-gradient(180deg,#A18E4A_15%,_#f3f4f6_15%)] lg:bg-[linear-gradient(180deg,#A18E4A_20%,_#f3f4f6_20%)]',
  },
  {
    statuses: [ReservationStatus.CANCELLED], // Refund only applies when paymentStatus is true
    image: 'check.svg',
    titleKey: 'resultPage.refundedTitle',
    descriptionKey: 'resultPage.refundedDescription',
    noteKey: 'resultPage.refundedNote',
    showCancellationAlert: false,
    showBookingNumber: true,
    bgBanner: 'bg-sky-50',
    bannerTitle: 'resultPage.refundedBannerTitle',
    bannerIcon: 'note.svg',
    background:
      'bg-[linear-gradient(180deg,#793298_15%,_#f3f4f6_15%)] lg:bg-[linear-gradient(180deg,#793298_20%,_#f3f4f6_20%)]',
  },
];