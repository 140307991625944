<div class="relative h-full" *ngIf="googleMapLoaded">
  <google-map
    height="100%"
    width="100%"
    [options]="options"
    (mapInitialized)="handleMapInitialized($event)">

  </google-map>
  <div 
    class="absolute justify-center bottom-3 left-0 w-full"
    *ngIf="selectedMarker && selectedShop && shouldDisplaySelectedMarkerModal"
  >
    <div class="flex justify-center">
        

      <!-- Modal container -->
      <div class="bg-white flex justify-center rounded-lg shadow-lg p-2 w-[90%] relative">

        <!-- Card component -->
        <div class="w-full h-auto">
          <app-shop-card *ngIf="selectedShop.id" [shop]="selectedShop" [rating]="shopRatings[selectedShop.id]" [mapView]="true"></app-shop-card>
        </div>

        <!-- Close button -->
        <div class="h-full flex items-start">
          <button (click)="closeMobileModal()" class="text-gray-500 hover:text-gray-700">
            <svg class="w-5 h-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              viewBox="0 0 24 24" stroke="currentColor">
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

      </div>

    </div>
  </div>
</div>
