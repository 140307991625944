import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-text-timer',
  templateUrl: './text-timer.component.html',
  styleUrls: ['./text-timer.component.scss'],
  standalone: true,
})
export class TextTimerComponent<T> implements OnInit, OnDestroy {
  // The object which a timer apply
  @Input() timedResource!: T;

  // A function that extract an expire date from the target resource
  @Input() expireTimeSupplier!: (item: T) => Date;

  // Emit an event when the time has expired
  @Output() expired = new EventEmitter<boolean>();

  remainingTime!: string;
  private timerSubscription!: Subscription;
  hr = 'hr';
  min = 'min';

  constructor(private transloco: TranslocoService) {}

  ngOnInit() {
    this.transloco
      .selectTranslate('time.hour')
      .subscribe((translation) => (this.hr = translation));
    this.transloco
      .selectTranslate('time.minute')
      .subscribe((translation) => (this.min = translation));

    const expiryDate = this.expireTimeSupplier(this.timedResource);
    if (expiryDate) {
      this.tickTimer(expiryDate); // Need to tick directly when init the component so the text don't flicker
      this.timerSubscription = interval(1000).subscribe(() =>
        this.tickTimer(expiryDate),
      );
    }
  }

  tickTimer(expiryDate: Date) {
    const currentTime = new Date();
    const timeLeft = Math.max(0, expiryDate.getTime() - currentTime.getTime());

    if (timeLeft > 0) {
      // Calculate hours, minutes, and seconds
      let hours = Math.floor(timeLeft / (1000 * 60 * 60)); // Corrected hour calculation
      let minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
      let seconds = Math.floor((timeLeft / 1000) % 60) + 1;

      // Adjust the minutes and seconds to account for next minute if seconds reach 60
      if (seconds === 60) {
        minutes += 1;
        seconds = 0;
      }
      if (minutes === 60) {
        hours += 1;
        minutes = 0;
      }

      // Format hours, minutes, and seconds for consistent two-digit display
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      // Conditionally format the output
      if (hours > 0) {
        this.remainingTime = `${formattedHours}${this.hr} ${formattedMinutes}${this.min}`;
      } else {
        this.remainingTime = `${formattedMinutes}:${formattedSeconds}`;
      }
    } else {
      this.remainingTime = 'Expired';
      this.timerSubscription?.unsubscribe();
      this.expired.emit(true);
    }
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.expired.emit(false);
      this.timerSubscription.unsubscribe();
    }
  }
}
