import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Dropdown, DropdownOptions } from 'flowbite';
import { Subject, firstValueFrom, noop, window } from 'rxjs';
import { FilterService } from '../../../filter/service/filter.service';
import { BackdropService } from '../../service/backdrop/backdrop.service';
import { DeviceService } from '../../service/device/device.service';
import { SearchService } from '../../service/search/search.service';
import { BaseButtonComponent } from '../base-button/base-button.component';
import {NgClass, NgIf} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-drop-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  standalone: true,
  imports: [
    BaseButtonComponent,
    NgIf,
    NgClass,
    TranslocoModule
  ]
})
export class DropdownButtonComponent extends BaseButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dropdown') dropdownRef!: ElementRef;
  @Input() public hideSubmit = false;
  @Input() filterCount: number = 0;
  @Input() widthClass: string = 'sm:w-80'
  @Input() topClass: string = 'mt-[0px]'
  @Input() padClass: string = 'pr-4 pl-4'
  @Input() contentPadClass: string = 'p-4'
  @Input() contentButtonPadClass: string = 'pt-0 px-4 pb-4'

  @Input() contentHeaderLabel = '';

  private mutationObserver!: MutationObserver;
  private dropdownObject!: Dropdown;
  private _initializedControlStateValue: unknown;
  private isSubmitClick = false;

  destroyEvent$: Subject<void> = new Subject<void>();

  constructor(private backdropService: BackdropService,
              public deviceService: DeviceService,
              private filterService: FilterService,
              public searchService: SearchService) {
    super()
  }

  async ngOnInit(): Promise<void> {
    if (!this.searchService.defaultStateInitialized) {
      await firstValueFrom(this.searchService.defaultStateInitializeEvent).then(noop);
    }

    // Make the dropdown unique
    this.dropdownName = this.dropdownName + Math.random().toString(10)

    this.setInitialControlStateValue(this.control.value)
  }

  ngAfterViewInit() {
    const dropdownElement = this.dropdownRef.nativeElement;

    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const currentClassList = dropdownElement.className;
          this.backdropService.visible = !currentClassList.includes('hidden')
        }
      });
    });

    this.mutationObserver.observe(dropdownElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    if (this.isDropDown) {
      this.dropdownObject = this.initDropDownObject();
    }
  }

  ngOnDestroy(): void {
    this.destroyEvent$.next();
    this.destroyEvent$.complete();
    this.mutationObserver.disconnect();
  }

  get dropdownClass(): string {
    return this.deviceService.isLargeView ? 'dropdown-container' : '';
  }

  setInitialControlStateValue(value: unknown): void {
    this._initializedControlStateValue = value;
  }

  getInitialControlStateValue(): unknown {
    return this._initializedControlStateValue;
  }

  onExitClick(): void {
    this.dropdownObject.hide();
    this.control.patchValue(this.getInitialControlStateValue());
  }

  onSubmitClick(): void {
    this.isSubmitClick = true;
    this.dropdownObject.hide();
    this.setInitialControlStateValue(this.control.value);
    this.submitEvent.emit()
  }

  revertState(): void {
    if (!this.isSubmitClick) {
      this.control.patchValue(this.getInitialControlStateValue());
    }
    this.isSubmitClick = false;
  }

  initDropDownObject(): Dropdown {
    const menu = document.getElementById(this.dropdownName)
    const button = document.getElementById(this.dropdownName + 'btn')

    const options: DropdownOptions = {
      onHide: () => {
        this.revertState();
      },
      onShow: () => {
        this.backdropService.visible = true;
        this.filterService.refreshCount();
      },
    }

    return new Dropdown(menu, button, options)
  }

  protected readonly window = window;
}
