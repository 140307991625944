import {
  AfterViewInit,
  Component,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { initFlowbite } from 'flowbite';
import { Subject, Subscription, noop, takeUntil } from 'rxjs';
import { Tag } from '../concierge-app/home-page/models/tag';
import { LIST_FILTER } from '../concierge-app/shop-list-view/models/shop-list-const';
import { HeaderControlService } from '../header/service/headerControl.service';
import { SearchService } from '../shared/service/search/search.service';
import { BaseFilterComponent } from './filters/base-filter/base-filter.component';
import { FilterService } from './service/filter.service';
import {NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {SamedayFilterComponent} from "./filters/sameday-filter/sameday-filter.component";
import {PriceRangeComponent} from "./filters/price-range/price-range.component";
import {CuisineFilterComponent} from "./filters/cuisine-filter/cuisine-filter.component";
import {MichelinFilterComponent} from "./filters/michelin-filter/michelin-filter.component";
import {AreaFilterComponent} from "./filters/area-filter/area-filter.component";
import {TranslocoModule} from "@ngneat/transloco";
import {BaseButtonComponent} from "../shared/buttons/base-button/base-button.component";
import {SortbyFilterComponent} from "./filters/sortby-filter/sortby-filter.component";

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    SamedayFilterComponent,
    PriceRangeComponent,
    CuisineFilterComponent,
    MichelinFilterComponent,
    AreaFilterComponent,
    NgIf,
    TranslocoModule,
    NgTemplateOutlet,
    BaseButtonComponent,
    SortbyFilterComponent
  ]
})
export class SearchFilterComponent implements OnDestroy, AfterViewInit {
  destroyEvent$: Subject<void> = new Subject<void>();
  tags: Tag[] = [];

  subs!: Subscription;
  showHeader = true;

  constructor(
    public searchService: SearchService,
    public filterService: FilterService,
    private headerControl: HeaderControlService,
  ) {
    this.subs = this.headerControl.showHeader$.subscribe((show) => {
      this.showHeader = show;
    });
  }

  @ViewChildren('btnFilter') buttonFilters:
    | QueryList<BaseFilterComponent>
    | undefined;

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
    this.destroyEvent$.next();
    this.destroyEvent$.complete();
  }

  ngAfterViewInit(): void {
    initFlowbite();

    this.buttonFilters?.forEach((component) => {
      component.submitEvent
        .pipe(takeUntil(this.destroyEvent$))
        .subscribe((component: BaseFilterComponent) => {
          this.onSubmit().then(noop);
          component.sendAnalytics().then(noop);
        });
    });
  }

  //todo: works despite crash if changed from home page. need to fix.
  showClearAllButton(): boolean {
    if (!this.buttonFilters) {
      return false;
    }
    return this.buttonFilters?.some(
      (filter) => filter?.clearAllEligible && filter?.stateChanged,
    );
  }

  async onSubmit(): Promise<void> {
    this.searchService.submitEvent.emit(LIST_FILTER.FILTER);
  }
}
