<section
    class="relative aspect-3  w-full h-[200px] sm:h-[360px] sm:w-full bg-[length:100%_100%] bg-no-repeat sm:bg-cover sm:bg-bottom md:flex md:justify-center md:items-center bg-[url('assets/images/bg-cathay-home-center-mobile-promo.jpg')] sm:bg-[url('assets/images/bg-cathay-low-desktop-promo.jpg')]">
  <div class="absolute inset-0 bg-black/40"></div> <!-- Adds a semi-transparent overlay -->

  <div class="absolute mx-auto px-4 md:p-4 w-full md:w-[80vw] ">
    <div class="w-4/5 md:w-3/5">
      <!-- Hero content, e.g., title, subtitle, and search bar -->

      <div class="text-start text-wrap antialiased text-sm p-4 justify-start font-bold leading-none text-white mt-2 mb-2 md:text-3xl mx-2 flex whitespace-nowrap">
        {{ 'homePage.cathyPacificTitlePromo' | transloco }}
      </div>

      <app-search-bar-interceptor [isHomePage]="true"></app-search-bar-interceptor>

      <div class="text-left antialiased text-lg md:text-xl font-bold text-white mt-2 mb-2 mx-2">
        <div class="flex gap-2  justify-start items-center text-xs sm:text-lg">
          <img class="bg-cover  h-[30px] sm:max-h-[50px]" src="../../../assets/images/cathay-airmile-badge-promo.png" alt="">
          {{ 'homePage.cathyAirMilesTitlePromo' | transloco }}
        </div>
      </div>
    </div>
  </div>
</section>
