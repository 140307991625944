import { AbstractControl, FormArray, FormControl } from "@angular/forms";
import { Shop } from "../../concierge-app/shop-list-view/models/shop-list";
import { join } from "lodash-es";

export type FormControlOf<FormInterface extends Record<string, unknown>> = {
  [key in keyof FormInterface]: FormInterface[key] extends (infer arrayType)[] ?
    FormArray<FormControl> : FormControl<FormInterface[key] | null>;
};

export function formatTags(shop: Shop | undefined): string {
  if (!shop || !shop.tags || !shop.tags.length) {
    return '';
  }

  return join(shop.tags.splice(0, 3), ', ');
}

export function originalSortOrder(): number {
  return 0;
}
